import React from "react";
import * as UI from "antd";
import { PageHeader } from '@ant-design/pro-layout';
import HttpPost,{UploadProps} from "../../../../utils/request";
import { Image } from 'antd';
import * as load from "../../../../utils/loading"
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import * as fa from '@fortawesome/fontawesome-free-solid'
import {Loading} from "../../../../utils/loading";
import {HasLoaded} from "../../../../utils/loading";
import FormUtils from "../../../../utils/FormUtils";


const {Search,TextArea}=UI.Input;
const { Meta } = UI.Card;
const {Option} = UI.Select


export default class Wxa extends React.Component{
    constructor(props){
        super(props);
        this.state={
            editCurr: {},
            editShow: false,
            tableData: [],
            tablePage: {total: 0,currPage:1},
            tableLoad: false,
            keywords: {},
            tpls: [],
            ext: {},
            imgkey: '',
            tplChooseShow: false,
            tplEditCurr: {},
            tester: {test_qrcode: {url: ''},wxa_code: {data: {url: ''}}},
            testerShow: false,
            exaCurr: {}
        }
    }
    componentDidMount() {
        this.GetTable()
    }

    /**
     * 审核状态
     * @param wxaId
     * @constructor
     */
    AuditState = (wxaId) => {
        var para = {}
        para.id = wxaId
        load.Loading()
        HttpPost("deposits/wxaauditstate", para).then(resp => {
            load.HasLoaded()
            if (resp.data.data.errcode === 0) {
                UI.message.success('小程序已经审核通过，请点击发布向公众开放新版本!')
            } else {
                UI.message.error(resp.data.data.reason)
            }
        })
    }
    /**
     * 体验者信息
     * @param wxaId
     * @constructor
     */
    InitialTesterInfor = (wxaId) =>{
        var that = this
        var para = {}
        para.id = wxaId
        load.Loading()
        HttpPost("deposits/wxatesterinfo", para).then(resp => {
            load.HasLoaded()
            that.setState({
                tester: resp.data.data,
                testerShow: true
            })
        })
    }
    /**
     * 获得当前小程序可用模板
     * @param devId
     * @constructor
     */
    GetWxaTpls = (devId) => {
        var that = this
        var para = {}
        para.dev_figure = devId
        para.pageNumber = 1
        para.pageSize = 10
        HttpPost("deposits/tpllist", para).then(resp => {
            that.setState({
                tpls: resp.data.data.data
            })
        })
    }
    /**
     * 发起搜索
     * @param params
     * @constructor
     */
    MakeSearch=(params)=>{
        console.log(params)
        this.setState({
            keywords: params
        })
        var that = this
        setTimeout(function () {
            that.GetTable()
        },200)
    }
    /**
     * 小程序列表
     * @param pageNumber
     Object.assign(para, that.state.keywords)
     * @param pageSize
     * @constructor
     */
    GetTable=(pageNumber=1,pageSize=20)=>{
        let that = this;
        let para = {};
        para.pageNumber = pageNumber;
        para.pageSize = pageSize;
        that.setState({
            tableLoad: true
        })
        HttpPost('deposits/wxalist',para).then(function (resp) {
            that.setState({
                tableLoad: false
            })
            var curr = {
                total: resp.data.data.total,
                currPage: pageNumber
            }
            that.setState({
                tableData: resp.data.data.data,
                tablePage: curr
            })
        })
    }
    /**
     * 图片上传
     * @param info
     * @constructor
     */
    ImgUpload = (info) => {
        var that = this;
        if (info.file.status === 'done') {
            load.HasLoaded();
            console.log(info.file)
            var ext = that.state.ext
            console.log(that.state.imgkey)
            console.log(info.file.response.data.data.url)
            ext[that.state.imgkey] = info.file.response.data.data.url
            that.setState({
                ext: ext
            })
        }
    }
    /**
     * 小程序名称检测
     * @param wxaId
     * @constructor
     */
    CheckWxaName=(rule, value, callback)=>{
        var that = this
        if (value) {
            var para = {}
            para.wxaId = that.state.editCurr.id
            para.name = value
            console.log(value)
            HttpPost("deposits/wxanameverify", para).then(resp=>{
                if (resp.data.code === 200) {
                    callback()
                } else {
                    callback(resp.data.data.errmsg)
                }
            })
        } else {
            if (typeof callback === 'function'){
                callback('请输入小程序名称')
            }
        }
    }
    /**
     * 提交小程序信息
     * @param params
     * @constructor
     */
    Handle = (params)=>{
        var that = this
        console.log(params)
        params.id = this.state.editCurr.id
        params.icon = that.state.ext.icon
        params.license = that.state.ext.license
        load.Loading()
        HttpPost("deposits/wxaupdate", params).then(resp=>{
            load.HasLoaded()
            if (resp.data.code === 200) {
                if (resp.data.data.code === 200) {
                    UI.message.success("提交小程序信息成功")
                } else {
                    UI.message.warning(resp.data.data.msg)
                }
            } else {
                UI.message.warning(resp.data.msg)
            }
        })
    }
    /**
     * 提交小程序审核
     * @param wxaId
     * @constructor
     */
    SubmitAduit=(wxaId)=>{
        var that = this
        var para = {}
        para.id = wxaId
        load.Loading()
        HttpPost("deposits/submitaudit", para).then(resp=>{
            load.HasLoaded()
            if (resp.data.errcode === 200) {
                if (resp.data.data.code === 0) {
                    UI.message.success("小程序审核提交成功，先静待审核结果")
                } else {
                    UI.message.warning(resp.data.data.errmsg)
                }
            } else {
                UI.message.warning(resp.data.msg)
            }
        })
    }
    /**
     * 提交新版本代码到小程序
     * @param params
     * @constructor
     */
    CodeCommit=(params)=>{
        params.id = this.state.tplEditCurr.id
        var that = this
        load.Loading()
        HttpPost("deposits/codecommit", params).then(resp=>{
            load.HasLoaded()
            if (resp.data.code === 200) {
                if (resp.data.data.errcode === 0) {
                    UI.message.success("小程序代码部署成功，请稍后进行发布提交")
                    that.setState({
                        tplChooseShow: false
                    })
                } else {
                    UI.message.warning(resp.data.data.errmsg)
                }
            } else {
                UI.message.warning(resp.data.msg)
            }
        })
    }
    /**
     * 新增体验者
     * @param params
     * @constructor
     */
    AddTester=(params)=>{
        params.id = this.state.wxaCurr.id
        var that = this
        load.Loading()
        HttpPost("deposits/wxabindtester", params).then(resp=>{
            load.HasLoaded()
            if (resp.data.code === 200) {
                if (resp.data.data.errcode === 0) {
                    UI.message.success("体验者新增成功")
                } else {
                    UI.message.warning(resp.data.data.errmsg)
                }
            } else {
                UI.message.warning(resp.data.msg)
            }
        })
    }
    render() {
        var that = this
        var Wxa = (params) => {
            var values = JSON.parse(JSON.stringify(params.values))
            if (values.ext && values.ext !== '') {
                values.ext = JSON.parse(values.ext)
                console.log(values.ext)
                return (
                    <div>
                        <img src={values.ext.icon} style={{objectFit:"cover",height:"120px",marginBottom:'16px',textAlign:'center',maxWidth:'100%'}}/>
                        <div><h3>{values.ext.name}</h3></div>
                        <div><small>{values.ext.name}</small></div>
                    </div>
                )
            } else {
                return (
                    <div>
                        <div style={{height:"120px"}}>
                        </div>
                        <div><h3>{values.code}</h3></div>
                    </div>
                )
            }
        }
        var TesterQrcode=()=>{
            if (that.testerShow){
                return (
                    <UI.Row gutter={16}>
                        <UI.Col style={{textAlign:'center'}}>
                            <img src={that.state.tester.test_qrcode.url} />
                            <h3 style={{marginTop:'14px'}}>体验者</h3>
                        </UI.Col>
                    </UI.Row>
                )
            } else {
                return null
            }
        }
        return (
            <div id="miniprams">
                <PageHeader title="托管小程序集中化管理" subTitle="" extra={[]}/>
                <UI.Row gutter={16}>
                    {that.state.tableData.map(wxa => {
                        return (
                            <UI.Col className="gutter-row" key={wxa.id} span={6} style={{backgroundColor:"#ffffff",textAlign:"center",paddingBottom:"14px"}}>
                                <Wxa values={wxa}/>
                                <div style={{padding:"22px"}}>
                                    <UI.Button.Group>
                                        <UI.Button type={"primary"} size={"small"} shape={"round"} onClick={()=>{
                                            var data = JSON.parse(JSON.stringify(wxa))
                                            if (data.ext && data.ext !== ''){
                                                data.ext=JSON.parse(wxa.ext)
                                                that.setState({
                                                    ext: data.ext
                                                })
                                            } else {
                                                that.setState({
                                                    ext: {}
                                                })
                                            }
                                            that.setState({
                                                editShow: true
                                            })
                                        }}>编辑</UI.Button>
                                        <UI.Button type={"primary"} size={"small"} shape={"round"} onClick={()=>{
                                            var data = JSON.parse(JSON.stringify(wxa))
                                            that.GetWxaTpls(data.figure)
                                            that.setState({
                                                tplEditCurr: data,
                                                tplChooseShow: true
                                            })
                                        }}>部署</UI.Button>
                                        <UI.Button type={"primary"} size={"small"} shape={"round"} onClick={()=>{
                                            that.setState({
                                                wxaCurr: wxa
                                            })
                                            that.InitialTesterInfor(wxa.id)
                                        }}>体验</UI.Button>
                                        <UI.Button type={"primary"} size={"small"} shape={"round"} onClick={()=>{
                                            that.AuditState(wxa.id)
                                        }}>状态</UI.Button>
                                        <UI.Button type={"primary"} size={"small"} shape={"round"} onClick={()=>{
                                            var para = {}
                                            para.id = wxa.id
                                            load.Loading()
                                            HttpPost("deposits/submitaudit", para).then(resp=>{
                                                load.HasLoaded()
                                                if (resp.data.code === 200) {
                                                    if (resp.data.data.errcode === 0) {
                                                        UI.message.success("小程序审核提交成功，先静待审核结果")
                                                    } else {
                                                        UI.message.warning(resp.data.data.errmsg)
                                                    }
                                                } else {
                                                    UI.message.warning(resp.data.msg)
                                                }
                                            })
                                        }}>审核</UI.Button>
                                        <UI.Button type={"danger"} size={"small"} shape={"round"} onClick={()=>{
                                            var para = {}
                                            para.id = wxa.id
                                            load.Loading()
                                            HttpPost("deposits/wxarelease", para).then(resp=>{
                                                load.HasLoaded()
                                                if (resp.data.code === 200) {
                                                    if (resp.data.data.errcode === 0) {
                                                        UI.message.success("小程序发布成功")
                                                    } else {
                                                        UI.message.warning(resp.data.data.errmsg)
                                                    }
                                                } else {
                                                    UI.message.warning(resp.data.msg)
                                                }
                                            })
                                        }}>发布</UI.Button>
                                    </UI.Button.Group>
                                </div>
                            </UI.Col>
                        )
                    })}
                </UI.Row>
                <div style={{display:"block",marginTop:"28px"}}>
                     <UI.Pagination defaultCurrent={this.state.tablePage.currPage} total={this.state.tablePage.total} defaultPageSize={20}/>
                </div>
                <UI.Modal title="小程序代码部署" footer={null} visible={that.state.tplChooseShow} onCancel={()=>{
                    that.setState({
                        tplChooseShow:false
                    })
                }} destroyOnClose>
                    <UI.Form onFinish={that.CodeCommit} labelCol={{span:24}} labelAlign={"left"}>
                        <UI.Form.Item label={"可用模板"} name="tplId" rules={[{required:true,message:"请选择你要部署的版本"}]}>
                            <UI.Radio.Group id="pannelradio">
                                {that.state.tpls.map(tpl=>{
                                    return (
                                        <UI.Radio value={tpl.id} key={tpl.id} style={{width:'100%',borderBottom:'1px solid rgb(240,240,240)',display:'flex',paddingBottom:'14px',marginTop:'14px'}}>
                                            <h3>{tpl.name}</h3>
                                            <small>{tpl.update_logs}</small>
                                            <small>{tpl.tags}</small>
                                        </UI.Radio>
                                    )
                                })}
                            </UI.Radio.Group>
                        </UI.Form.Item>
                        <UI.Form.Item style={{textAlign:'right'}}>
                            <UI.Button type="primary" shape="round" htmlType="submit">更新小程序模板</UI.Button>
                        </UI.Form.Item>
                    </UI.Form>
                </UI.Modal>
                <UI.Modal title="小程序编辑" footer={null} visible={that.state.editShow} onCancel={()=>{
                    that.setState({
                        editShow:false
                    })
                }} destroyOnClose>
                    <UI.Form onFinish={that.Handle} labelCol={{span:24}} labelAlign={"left"}>
                        <UI.Form.Item label={"小程序名称"}
                                      name={"name"}
                                      initialValue={that.state.ext.name}
                                      rules={[{required:true,message:"请输入小程序名称"},{validator: that.CheckWxaName}]} >
                            <UI.Input placeholder={"请输入小程序名称"} onMouseLeave={that.CheckWxaName}/>
                        </UI.Form.Item>
                        <UI.Form.Item label="小程序图标" required>
                            <UI.Upload
                                {...UploadProps}
                                beforeUpload={()=>{
                                    load.Loading()
                                    that.setState({
                                        imgkey: 'icon'
                                    })
                                }}
                                listType="picture-card"
                                className="avatar-uploader"
                                showUploadList={false}
                                onChange={that.ImgUpload} name="file">
                                {that.state.ext.icon ? <img src={that.state.ext.icon} alt="avatar" style={{ width: '100%' }} /> : "UPLOAD"}
                            </UI.Upload>
                        </UI.Form.Item>
                        <UI.Form.Item label={"组织类型"}
                                      initialValue={that.state.ext.licenseType}
                                      rules={[{required:true,message:"请选择组织类型"}]}
                                      name="licenseType">
                            <UI.Radio.Group>
                                <UI.Radio value={"id_card"}>个人用户</UI.Radio>
                                <UI.Radio value={"license"}>企业用户</UI.Radio>
                            </UI.Radio.Group>
                        </UI.Form.Item>
                        <UI.Form.Item label="法律文件" help={"企业上传营业执照或则组织机构代码证,个人上传身份证"} required>
                            <UI.Upload
                                {...UploadProps}
                                beforeUpload={()=>{
                                    load.Loading()
                                    that.setState({
                                        imgkey: 'license'
                                    })
                                }}
                                listType="picture-card"
                                className="avatar-uploader"
                                showUploadList={false}
                                onChange={that.ImgUpload} name="file">
                                {that.state.ext.license ? <img src={that.state.ext.license} alt="avatar" style={{ width: '100%' }} /> : "UPLOAD"}
                            </UI.Upload>
                        </UI.Form.Item>
                        <UI.Form.Item label={"小程序介绍"}
                                      name={"explain"}
                                      initialValue={that.state.ext.explain}
                                      rules={[{required:true,message:"请输入小程序介绍"}]} >
                            <TextArea placeholder={"请输入小程序介绍"} rows={8}/>
                        </UI.Form.Item>
                        <UI.Form.Item label={"小程序标签"}
                                      name={"tags"}
                                      initialValue={that.state.ext.name}
                                      rules={[{required:true,message:"小程序标签"}]} >
                            <UI.Input placeholder={"请输入小程序标签，多个标签间使用英文逗号相隔"}/>
                        </UI.Form.Item>
                        <UI.Form.Item style={{textAlign:'right'}}>
                            <UI.Button type="primary" shape="round" htmlType="submit">更新小程序</UI.Button>
                        </UI.Form.Item>
                    </UI.Form>
                </UI.Modal>
                <UI.Modal title="小程序体验" footer={null} visible={that.state.testerShow} onCancel={()=>{
                    that.setState({
                        testerShow:false
                    })
                }} destroyOnClose>
                    <UI.Row gutter={16}>
                        <UI.Col span={12} style={{textAlign:'center'}}>
                            <img src={that.state.tester.test_qrcode.url} style={{width:'90%',objectFit:'cover'}} />
                            <h3 style={{marginTop:'14px'}}>体验者</h3>
                        </UI.Col>
                        <UI.Col  span={12} style={{textAlign:'center'}}>
                            <img src={that.state.tester.wxa_code.data.url}  style={{width:'90%',objectFit:'cover'}} />
                            <h3 style={{marginTop:'14px'}}>小程序码</h3>
                        </UI.Col>
                    </UI.Row>
                    <UI.Form onFinish={that.AddTester} style={{display: 'flex'}}>
                        <UI.Form.Item name={"wechatId"} style={{flex:'1'}}>
                            <UI.Input placeholder={"请输入小程序ID，请注意是ID而不是用户名"}/>
                        </UI.Form.Item>
                        <UI.Form.Item>
                            <UI.Button type="primary" htmlType="submit">新增体验者</UI.Button>
                        </UI.Form.Item>
                    </UI.Form>
                </UI.Modal>
            </div>
        )
    }

}