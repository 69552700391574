import React from "react";
import * as UI from "antd";
import { PageHeader } from '@ant-design/pro-layout';
import HttpPost,{UploadProps} from "../../../../utils/request";
import * as load from "../../../../utils/loading"

const {Search, TextArea}=UI.Input;

export default class Applications extends React.Component{
    constructor() {
        super();
        this.state = {
            editCurr: {},
            editShow: false,
            imageUrl: null,
            loading: false,
            tableData: [],
            tablePage: {total: 0,currPage:1},
            tableLoad: false
        }
    }
    componentDidMount() {
        this.getTablePage(1)
    }

    /**
     * 搜索应用
     * @constructor
     */
    MakeSearch = function () {
        console.log("开始搜索")
    }
    /**
     * 图标上传状态变化
     * @param info
     * @constructor
     */
    ImgUpload = (info) => {
        var that = this;
        load.HasLoaded();
        if (info.file.status === 'done') {
            console.log(info.file)
            that.setState({
                imageUrl: info.file.response.data.data.url
            })
        }
    }
    /**
     * 图片上传前开启加载
     * @param file
     */
    beforeUpload = (file) => {
        load.Loading();
    }
    /**
     * 获得应用分页
     * @param page
     */
    getTablePage=(page)=>{
        let that = this;
        let para = {};
        para.pageNumber = page;
        para.pageSize = 20;
        Object.assign(para, that.state.searchKey)
        that.setState({
            tableLoad: true
        })
        HttpPost('grant/applist',para).then(function (resp) {
            that.setState({
                tableLoad: false
            })
            var curr = {
                total: resp.data.data.total,
                currPage: page
            }
            that.setState({
                tableData: resp.data.data.data,
                tablePage: curr
            })
        })
    }
    modify = (para) =>{
        let that = this;
        load.Loading();
        HttpPost("grant/appmodify",para).then(resp=>{
            load.HasLoaded();
            if (resp.data.code===200){
                if(resp.data.data.code===200){
                    UI.message.success("应用信息处理完成")
                    this.getTablePage(that.state.tablePage.currPage)
                } else {
                    UI.message.warning(resp.data.data.msg)
                }
            }else {
                UI.message.error(resp.data.msg)
            }
        })
    }
    /**
     * 提交应用信息
     * @param values
     */
    handle = (values) => {
        let that = this;
        if (Object.keys(that.state.editCurr).length > 0) {
            values.id = that.state.editCurr.id;
        }
        if (that.state.imageUrl) {
            load.Loading();
            values.icon = that.state.imageUrl;
            HttpPost("grant/appmodify",values).then(resp=>{
                load.HasLoaded();
                if (resp.data.code===200){
                    if(resp.data.data.code===200){
                        UI.message.success("应用信息处理完成")
                        this.setState({
                            editShow: false,
                            editCurr: {}
                        })
                        this.getTablePage(1)
                    } else {
                        UI.message.warning(resp.data.data.msg)
                    }
                }else {
                    UI.message.error(resp.data.msg)
                }
            })
        } else {
            UI.message.error("请上传应用图标")
        }
    }
    render() {
        const columns =[
            {
                title:'ID',
                dataIndex: 'id',
                key: 'id'
            },
            {
                title: '应用名称',
                dataIndex: 'name',
                key: 'name'
            },
            {
                title: '分组说明',
                dataIndex: 'intro',
                key: 'intro'
            },
            {
                title: '分组操作',
                key:'operation',
                align:'right',
                width: 220,
                render: (col,row,index)=>{
                    return (
                        <div className="opt">
                            <UI.Button type="link" onClick={()=>{
                                that.setState({
                                    editCurr:row,
                                    editShow: true,
                                    imageUrl: row.icon
                                })
                            }}>编辑</UI.Button>
                            <UI.Button type="link" onClick={()=>{
                                that.modify({id:row.id, state: !row.state})
                            }}>{row.state ? '禁用' : '启用'}</UI.Button>
                        </div>
                    )
                }
            }
        ]
        let that = this;
        return (
            <div id="appadmin">
                <PageHeader title="应用集中管理" subTitle="相同应用拥有相同的用户体系，可实现不同的终端间SSO登陆，如未有用户分拆需求，一个商户一个应用即可" extra={[
                    <UI.Button  key="1" type="primary" shape="round" onClick={()=>{
                        that.setState({
                            editCurr: {},
                            editShow: true
                        })
                    }}>新增应用</UI.Button>
                ]}/>
                <div id="tablecontains">
                    <div id="toolbar">
                        <Search placeholder="请输入应用名称关键词..." enterButton="应用搜索" size="large" onSearch={this.MakeSearch}/>
                    </div>
                </div>
                <div style={{marginTop:'22px'}}>
                    <UI.Table
                        rowKey="id"
                        dataSource={this.state.tableData}
                        columns={columns}
                        loading={this.state.tableLoad}
                        pagination={<UI.Pagination total={this.state.tablePage.total} defaultCurrent={this.state.tablePage.currPage}/>}/>
                </div>
                <UI.Modal title="新增应用" visible={this.state.editShow} footer={null} onCancel={()=>{
                    this.setState({
                        editShow: false
                    })
                }} destroyOnClose>
                    <UI.Form labelCol={{span:24}} labelAlign="left" onFinish={this.handle}>
                        <UI.Form.Item name="name" label="应用名称" rules={[{required:true,message:'请输入应用名称'}]} initialValue={this.state.editCurr.name}>
                            <UI.Input placeholder="请输入应用名称..."/>
                        </UI.Form.Item>
                        <UI.Form.Item label="应用图标" required>
                            <UI.Upload
                                {...UploadProps}
                                beforeUpload={that.beforeUpload}
                                listType="picture-card"
                                className="avatar-uploader"
                                showUploadList={false}
                                onChange={that.ImgUpload} name="file">
                                {that.state.imageUrl ? <img src={that.state.imageUrl} alt="avatar" style={{ width: '100%' }} /> : "UPLOAD"}
                            </UI.Upload>
                        </UI.Form.Item>
                        <UI.Form.Item name="intro" label="应用介绍"  rules={[{required:true,message:'请输入应用介绍'}]} initialValue={this.state.editCurr.intro}>
                            <TextArea placeholder="请输入应用介绍..." rows={6}/>
                        </UI.Form.Item>
                        <UI.Form.Item style={{textAlign:'right'}}>
                            <UI.Button type="primary" shape="round" htmlType="submit">提交应用信息</UI.Button>
                        </UI.Form.Item>
                    </UI.Form>
                </UI.Modal>
            </div>
        )
    }
}
