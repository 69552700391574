/**
 * author: Alex Lai
 * Support Website: https://laialex.com
 * Email: alex@laialex.com
 * Create Time: 2023/11/10
 */
import React,{Component} from "react";
import * as UI from "antd";
import { PageHeader } from '@ant-design/pro-layout';
import apis from "../../../../utils/apis";
import {BellOutlined} from "@ant-design/icons";



const {TextArea} = UI.Input

export default class ExecuteGroup extends Component{
    constructor() {
        super();
        this.state = {
            tableData: [],
            paging: {total: 0,pageNumber: 1, pageSize: 20},
            tableLoad: false,
            searchKey: {},
            editShow: false,
            editCurr: {},
            submitting: false,
            settings: []
        }
    }

    componentDidMount() {
        this.settingSync()
        this.tableDataSync()
    }

    /**
     * 计划任务协议
     */
    settingSync () {
        var that = this
        var filter = {}
        filter.service = 'Scheduler'
        filter.pageNumber = 1
        filter.pageSize = 2000
        apis.systemSettings(filter).then(res => {
            that.setState({
                settings: res.data.data.data
            })
        })
    }

    /**
     * 发起搜索
     * @param params
     * @constructor
     */
    makeSearch=(params)=>{
        this.setState({
            searchKey: params
        })
        var that = this
        setTimeout(function () {
            that.tableDataSync()
        },200)
    }
    /**
     * 签名列表
     * @param pageNumber
     Object.assign(para, that.state.keywords)
     * @param pageSize
     * @constructor
     */
    tableDataSync=(pageNumber=1)=>{
        let that = this;
        let para = {};
        para.pageNumber = pageNumber;
        para.pageSize = 20;
        that.setState({
            tableLoad: true
        })
        Object.assign(para,that.state.searchKey)
        apis.schedulerGroups(para).then(function (resp) {
            that.setState({
                tableLoad: false
            })
            var curr = {
                total: resp.data.data.total,
                pageNumber: pageNumber
            }
            that.setState({
                tableData: resp.data.data.data,
                paging: curr
            })
        })
    }

    render() {
        const  columns = [
            {
                title: "ID",
                dataIndex: "id"
            },
            {
                title: "应用名称",
                key: "id",
                render: function (value, row, index) {
                    return (
                        <div style={{fontSize:"22px",color:"darkcyan"}}>{row.app_name}</div>
                    )
                }
            },
            {
                title: "应用标题",
                key: "id",
                render: function (value, row, index) {
                    return (
                        <div>{row.title}</div>
                    )
                }
            },
            {
                title: "协议类型",
                key: "id",
                render: function (value, row, index) {
                    return (
                        <UI.Button shape={"round"} type={"dashed"}>{row.type.code}</UI.Button>
                    )
                }
            },
            {
                title: "客户端",
                key: "id",
                render: function (value, row, index) {
                    return (
                        <UI.Button shape={"round"} type={"dashed"}>{row.address_list ? row.address_list : "待配置"}</UI.Button>
                    )
                }
            },
            {
                title: "可用",
                key: "id",
                render: function (value, row, index) {
                    return (
                        <UI.Button shape={"round"} type={"circle"}>{row.state ?　'是' : '否'}</UI.Button>
                    )
                }
            },
            {
                title: "可用",
                key: "id",
                fixed: "right",
                render: function (value, row, index) {
                    return (
                        <span>
                             <UI.Button onClick={()=>{
                                 var data = JSON.parse(JSON.stringify(row))
                                 data.type_id = data.type.id
                                 that.setState({
                                     editCurr: data,
                                     editShow: true
                                 })
                             }} type={"link"}>编辑</UI.Button>
                        </span>
                    )
                }
            }
        ]
        var that = this
        return (
            <div>
                <PageHeader title="执行组管理" subTitle="执行组管理的配置与管理" extra={[
                    <UI.Button  key="1" type="primary" shape="round" onClick={()=>{
                        that.setState({
                            editCurr: {},
                            editShow: true,
                            submitting: false
                        })
                    }}>新增执行组</UI.Button>
                ]}/>
                <div style={{color:"red", fontSize:"10px",marginBottom:"16px"}}><BellOutlined /> 免费用户可创建一个执行组</div>
                <UI.Card>
                    <div style={{marginTop:'22px'}}>
                        <UI.Form onFinish={that.MakeSearch} layout={"inline"}>
                            <UI.Form.Item label={"执行组名称"} name={"name__contains"}>
                                <UI.Input placeholder={"执行组名称"}/>
                            </UI.Form.Item>
                            <UI.Form.Item>
                                <UI.Button onClick={that.makeSearch} type={"primary"} htmlType={"submit"}>快递搜索</UI.Button>
                            </UI.Form.Item>
                        </UI.Form>
                    </div>
                </UI.Card>

                <div style={{marginTop:'22px'}}>
                    <UI.Table
                        rowKey="id"
                        dataSource={this.state.tableData}
                        columns={columns}
                        loading={this.state.tableLoad}
                        pagination={{total:this.state.paging.total,defaultPageSize:20,defaultCurrent:this.state.paging.currPage,onChange:this.tableDataSync}}/>
                </div>

                <UI.Modal visible={that.state.editShow} title={'定时任务执行组管理'} onCancel={()=>{
                    that.setState({
                        editCurr: {},
                        editShow: false,
                        submitting: false
                    })
                }} footer={null} destroyOnClose>
                    <UI.Form onFinish={(params => {
                        that.setState({
                            submitting: true
                        })
                        if (that.state.editCurr.id) {
                            params.id = that.state.editCurr.id
                        }
                        apis.schedulerExecuteGroupAdmin(params).then(resp => {
                            that.setState({
                                submitting: false
                            })
                            if (resp.data.code === 200 && resp.data.data.code === 200) {
                                UI.message.success("提交信息成功")
                                that.tableDataSync(that.state.editCurr.id ? that.state.paging.pageNumber : 1)
                                that.setState({
                                    submitting: false,
                                    editShow: false,
                                    editCurr: {}
                                })
                            } else {
                                UI.message.error(resp.data.data.msg ? resp.data.data.msg : resp.data.msg)
                            }
                        })
                    })} labelCol={{span:24}} labelAlign={"left"} initialValues={that.state.editCurr}>
                        <UI.Form.Item label={"分组名称"} name={"app_name"} rules={[{required:true,message:'分组名称'}]} help={"限制以小写字母开头，由小写字母、数字和中划线组成"}>
                            <UI.Input disabled={that.state.editCurr.id} placeholder={"分组名称"}/>
                        </UI.Form.Item>
                        <UI.Form.Item label={"分组标题"} name={"title"} rules={[{required:true,message:'分组标题'}]}>
                            <UI.Input placeholder={"分组标题"}/>
                        </UI.Form.Item>
                        <UI.Form.Item label={"执行组状态"} name={"state"} rules={[{required:true,message:'执行组状态'}]}>
                            <UI.Radio.Group>
                                <UI.Radio value={true}>可用</UI.Radio>
                                <UI.Radio value={false}>禁用</UI.Radio>
                            </UI.Radio.Group>
                        </UI.Form.Item>
                        <UI.Form.Item label={"执行协议"} name={"type_id"} rules={[{required:true,message:'执行器类型'}]}>
                            <UI.Radio.Group>
                                {that.state.settings.map(item => {
                                    return (
                                        <UI.Radio value={item.id} key={item.id}>{item.code}</UI.Radio>
                                    )
                                })}
                            </UI.Radio.Group>
                        </UI.Form.Item>
                        <UI.Form.Item label={"注册类型"} name={"address_type"} rules={[{required:true,message:'执行组状态'}]}>
                            <UI.Radio.Group>
                                <UI.Radio value={0}>自动注册</UI.Radio>
                                <UI.Radio value={1}>手动配置</UI.Radio>
                            </UI.Radio.Group>
                        </UI.Form.Item>
                        <UI.Form.Item label={"执行器地址列表"} name={"address_list"} help="地址样式:http://ip:8999,多个执行器使用英文逗号相隔" rules={[{required:true,message:'执行器地址列表'}]}>
                            <TextArea rows={6} placeholder={"执行器地址列表"}/>
                        </UI.Form.Item>
                        <UI.Form.Item>
                            <UI.Button htmlType={"submit"} loading={that.state.hanldeLoading} type={"primary"} block>立即提交</UI.Button>
                        </UI.Form.Item>
                    </UI.Form>
                </UI.Modal>
            </div>
        )
    }
}