/**
 * author: Alex Lai
 * Support Website: https://laialex.com
 * Email: alex@laialex.com
 * Create Time: 2023/12/29
 */
import React, {Component} from "react";
import * as UI from "antd";
import { PageHeader } from '@ant-design/pro-layout';
import {BellOutlined} from "@ant-design/icons";
import apis from "../../../../../utils/apis";
import {LoadingEnded, LoadingStart} from "../../../../../utils/loading";


const {TextArea} = UI.Input
const {Option} = UI.Select

export default class SmsRecord extends Component {
    constructor() {
        super();
        this.state = {
            tableData: [],
            paging: {total: 0, pageNumber: 1, pageSize: 20},
            tableLoad: false,
            searchKey: {},
        }
    }
    componentDidMount() {
        this.tableDataSync()
    }

    /**
     * 发起搜索
     * @param params
     * @constructor
     */
    makeSearch = (params) => {
        this.setState({
            searchKey: params
        })
        var that = this
        setTimeout(function () {
            that.tableDataSync()
        }, 200)
    }
    /**
     * 签名列表
     * @param pageNumber
     Object.assign(para, that.state.keywords)
     * @param pageSize
     * @constructor
     */
    tableDataSync = (pageNumber = 1) => {
        let that = this;
        let para = {};
        para.pageNumber = pageNumber;
        para.pageSize = 20;
        that.setState({
            tableLoad: true
        })
        Object.assign(para, that.state.searchKey)
        apis.getSmsRecord(para).then(function (resp) {
            that.setState({
                tableLoad: false
            })
            var curr = {
                total: resp.data.data.total,
                pageNumber: pageNumber
            }
            that.setState({
                tableData: resp.data.data.data,
                paging: curr
            })
        })
    }


    render() {
        var that = this

        const columns = [
            {
                title: "ID",
                dataIndex: "id"
            },
            {
                title: "发送模板",
                key: "id",
                render: function (value, row, index) {
                    return row.template.name
                }
            },
            {
                title: "签名",
                key: "id",
                render: function (value, row, index) {
                    return row.sign.name
                }
            },
            {
                title: "收信手机",
                key: "id",
                render: function (value, row, index) {
                    return row.mobile
                }
            },
            {
                title: "发送时间",
                key: "id",
                render: function (value, row, index) {
                    var dd = new Date(row.create_at)
                    return `${dd.toLocaleDateString()} ${dd.toLocaleTimeString()}`
                }
            },
            {
                title: "验证已发送",
                key: "id",
                render: function (value, row, index) {
                    return (
                        <UI.Button shape={"round"} type={"circle"} style={{color:"white", backgroundColor: row.sent ? "darkseagreen" : "tomato", border: "none"}}>{row.sent ? '是' : '否'}</UI.Button>
                    )
                }
            },
            {
                title: "管理",
                key: "id",
                fixed: "right",
                render: function (value, row, index) {
                    return (
                        <span>
                             <UI.Button onClick={() => {
                                 LoadingStart()
                                 apis.getSmsRecordStatus(row.id).then(res => {
                                     LoadingEnded()
                                     if (res.data.code === 200 && res.data.data.code === 200) {
                                         UI.message.success("当前短信已经发送成功")
                                     } else {
                                         UI.message.error(res.data.data.msg ? res.data.data.msg : "当前短信发送失败")
                                     }
                                 })
                             }} type={"link"}>状态查询</UI.Button>
                        </span>
                    )
                }
            }
        ]

        return (
            <div>
                <PageHeader title="短信发送记录" subTitle="短信发送记录查看,发送失败原因查看"/>
                <UI.Card>
                    <div style={{marginTop: '22px'}}>
                        <UI.Form onFinish={that.makeSearch} layout={"inline"}>
                            <UI.Form.Item label={"收件手机号"} name={"mobile"}>
                                <UI.Input placeholder={"收件手机号"}/>
                            </UI.Form.Item>
                            <UI.Form.Item label={"国际区号"} name={"itac"}>
                                <UI.Input placeholder={"国际区号"}/>
                            </UI.Form.Item>
                            <UI.Form.Item>
                                <UI.Button type={"primary"} htmlType={"submit"}>快速搜索</UI.Button>
                            </UI.Form.Item>
                        </UI.Form>
                    </div>
                </UI.Card>

                <div style={{marginTop: '22px'}}>
                    <UI.Table
                        rowKey="id"
                        dataSource={this.state.tableData}
                        columns={columns}
                        loading={this.state.tableLoad}
                        pagination={{
                            total: this.state.paging.total,
                            defaultPageSize: 20,
                            defaultCurrent: this.state.paging.pageNumber,
                            onChange: this.tableDataSync
                        }}/>
                </div>
            </div>
        )
    }
}