import React,{Component,Fragment} from "react";
import * as UI from 'antd'
import { PageHeader } from '@ant-design/pro-layout'
import  {Switch,Route,Link} from "react-router-dom"
import HttpPost from "../../../../utils/request";


const {Meta} = UI.Card

export default class Saas extends Component{
    constructor() {
        super();
        this.state = {
            tableData: [],
            tablePage: {total: 0,currPage:1},
            tableLoad: false,
            searchKey: {}
        }
    }
    componentDidMount() {
        this.GetTable()
    }

    /**
     * 分页查询
     * @param pageNumber
     * @param pageSize
     * @constructor
     */
    GetTable=(pageNumber=1,pageSize=20)=>{
        let that = this;
        let para = {};
        para.pageNumber = pageNumber;
        para.pageSize = pageSize;
        Object.assign(para, that.state.searchKey)
        that.setState({
            tableLoad: true
        })
        para.is_saas_service = true
        HttpPost('system/systemproduct',para).then(function (resp) {
            that.setState({
                tableLoad: false
            })
            let curr = {
                total: resp.data.data.total,
                currPage: pageNumber
            }
            that.setState({
                tableData: resp.data.data.data,
                tablePage: curr
            })
        })
    }
    MakeSearch=(keywords)=>{
        var that = this;
        this.setState({
            searchKey: Object.assign(that.state.searchKey,{keywords: keywords})
        })
        that.GetTable(1)
    }
    render() {
        var that = this
        var pageProperty = {total:this.state.tablePage.total,defaultPageSize:20,defaultCurrent:this.state.tablePage.currPage,onChange:this.GetTable}
        return (
            <div>
                <PageHeader title="软件即应用" subTitle="软件即应用服务"/>
                <div style={{marginTop:'22px',marginButtom:"32px"}}>
                    <UI.Form onFinish={that.MakeSearch} layout={"inline"}>
                        <UI.Form.Item name="title__icontains" label="产品名称">
                            <UI.Input placeholder="请输入软件名称..."/>
                        </UI.Form.Item>
                        <UI.Form.Item>
                            <UI.Button type={"primary"} htmlType={"submit"}>产品搜索</UI.Button>
                        </UI.Form.Item>
                    </UI.Form>
                </div>
                <UI.Divider/>
                <UI.Row gutter={14}>
                    {that.state.tableData.map(item=>{
                        return (
                            <UI.Col key={item.mpn} span={6}>
                                <UI.Card hoverable cover={<img alt={item.title} style={{width:"100%"}} src={"//os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png"}/>}>
                                   <Meta title={item.title} description={item.content}/>
                                   <div style={{textAlign:"right",marginTop:"28px"}}>
                                       <UI.Button.Group>
                                           <UI.Button shape={"round"} type={"primary"}>
                                               <a href={`/purchase/item/${item.mpn}`}>采购</a>
                                           </UI.Button>
                                       </UI.Button.Group>
                                   </div>
                                </UI.Card>
                            </UI.Col>
                        )
                    })}
                </UI.Row>
                <div style={{marginTop:"28px"}}>
                    <UI.Pagination {...pageProperty} />
                </div>
            </div>
        )
    }
}