import React,{Component} from "react";
import HttpPost, {UploadProps} from "../../../../../utils/request";
import * as UI from 'antd'
import { PageHeader } from '@ant-design/pro-layout'

import * as load from "../../../../../utils/loading";

const {Option} = UI.Select
const {TextArea} =UI.Input


export default class SignGraph extends React.Component{
    constructor(){
        super()
        this.state = {
            tableData: [],
            tablePage: {total: 0,currPage:1},
            tableLoad: false,
            searchKey: {},
            editShow: false,
            editCurr: {},
            graphs: [],
            imgkey: '',
            upLoading: false
        }
    }
    componentDidMount() {
        this.getGraphs()
        this.GetTable(1)
    }

    formRef=React.createRef()
    handle=(values)=>{
        let that = this;
        if (Object.keys(that.state.editCurr).length > 0) {
            values.id = that.state.editCurr.id;
        }
        load.Loading();
        HttpPost("merchant/smssignadmin",values).then(resp=>{
            load.HasLoaded();
            if (resp.data.code===200){
                if(resp.data.data.code===200){
                    UI.message.success("短信模板信息提交成功")
                    this.setState({
                        editShow: false,
                        editCurr: {}
                    })
                    this.GetTable(1)
                } else {
                    UI.message.warning(resp.data.data.msg)
                }
            }else {
                UI.message.error(resp.data.msg)
            }
        })
    }
    /**
     * 获得可用渠道
     */
    getGraphs = () =>{
        var that = this
        var para = {}
        para.pageNumber = 1
        para.pageSize = 10000
        HttpPost("merchant/smsapplist",para).then(resp=>{
            that.setState({
                graphs: resp.data.data.data
            })
        })
    }
    /**
     * 图片上传
     * @param info
     * @constructor
     */
    ImgUpload = (info) => {
        var that = this;
        if (info.file.status === 'done') {
            load.HasLoaded();
            var ext = that.state.editCurr
            ext[that.state.imgkey] = info.file.response.data.data.url
            that.setState({
                editCurr: ext
            })
            var para = {}
            para[that.state.imgkey] = info.file.response.data.data.url
            that.formRef.current.setFieldsValue(para)
        }
    }
    /**
     * 发起搜索
     * @param params
     * @constructor
     */
    MakeSearch=(params)=>{
        console.log(params)
        this.setState({
            searchKey: params
        })
        var that = this
        setTimeout(function () {
            that.GetTable()
        },200)
    }
    /**
     * 签名列表
     * @param pageNumber
     Object.assign(para, that.state.keywords)
     * @param pageSize
     * @constructor
     */
    GetTable=(pageNumber=1,pageSize=20)=>{
        let that = this;
        let para = {};
        para.pageNumber = pageNumber;
        para.pageSize = pageSize;
        that.setState({
            tableLoad: true
        })
        Object.assign(para,that.state.searchKey)
        HttpPost('merchant/smssignlist',para).then(function (resp) {
            that.setState({
                tableLoad: false
            })
            var curr = {
                total: resp.data.data.total,
                currPage: pageNumber
            }
            that.setState({
                tableData: resp.data.data.data,
                tablePage: curr
            })
        })
    }
    render(){
        var that = this
        var columns = [
            {
                title: "id",
                dataIndex: "id"
            },
            {
                title: "签名名称",
                dataIndex: "name"
            },
            {
                title: "短信应用",
                key: 'id',
                render: function (value, row, index) {
                    return row.sms_apps.name
                }
            },
            {
                title: "签名",
                key: 'id',
                render: function (value, row, index) {
                    return row.sign_content
                }
            },
            {
                title: "平台",
                key: 'id',
                render: function (value, row, index) {
                    return row.sms_apps.graph.name
                }
            },
            {
                title: "已推送",
                key: 'id',
                render: function (value, row, index) {
                    return (
                        <UI.Button danger shape={"circle"}>
                            {row.synced ? '是' : '否'}
                        </UI.Button>
                    )
                }
            },
            {
                title: "审核通过",
                key: 'id',
                render: function (value, row, index) {
                    return (
                        <UI.Button type={"primary"} shape={"circle"}>
                            {row.state ? '是' : '否'}
                        </UI.Button>
                    )
                }
            },
            {
                title: "平台",
                key: 'id',
                align: "right",
                fixed: "right",
                render: function (value, row, index) {
                    return (
                        <UI.Button.Group>
                            <UI.Button onClick={()=>{
                                var data = JSON.parse(JSON.stringify(row))
                                delete data.metadata
                                data.sms_app = data.sms_apps.id
                                delete data.create_at
                                delete data.update_at
                                that.setState({
                                    editCurr: data,
                                    editShow: true
                                })
                            }} type={"link"}>签名编辑</UI.Button>
                            <UI.Button type={"link"} onClick={()=>{
                                load.Loading();
                                HttpPost("merchant/smssignstate",{id: row.id}).then(resp=>{
                                    load.HasLoaded();
                                    if (resp.data.code===200){
                                        if(resp.data.data.code===200){
                                            UI.message.success("签名已经通过审核")
                                            that.GetTable(1)
                                        } else {
                                            UI.message.error(resp.data.data.msg)
                                        }
                                    }else {
                                        UI.message.error(resp.data.msg)
                                    }
                                })
                            }}>状态验证</UI.Button>
                        </UI.Button.Group>
                    )
                }
            }
        ]
        return (
            <div>
                <PageHeader title="短信签名管理" subTitle="短信签名的管理" extra={[
                    <UI.Button  key="1" type="primary" shape="round" onClick={()=>{
                        that.setState({
                            editCurr: {},
                            editShow: true
                        })
                    }}>新增签名</UI.Button>
                ]}/>
                <div style={{marginTop:'22px'}} id="hot-top-toolbar">
                    <UI.Form onFinish={that.MakeSearch} layout={"inline"}>
                        <UI.Form.Item label={"签名名称"} name={"name__contains"}>
                            <UI.Input placeholder={"请输入签名名称"}/>
                        </UI.Form.Item>
                        <UI.Form.Item label={"短信应用"} name={"Sms_Apps__Id"}>
                            <UI.Select placeholder={"请选择短信应用"}>
                                {that.state.graphs.map(gp=>{
                                    return (
                                        <Option key={gp.id} value={gp.id}>{gp.name}</Option>
                                    )
                                })}
                            </UI.Select>
                        </UI.Form.Item>
                        <UI.Form.Item>
                            <UI.Button type={"primary"} htmlType={"submit"}>签名搜索</UI.Button>
                        </UI.Form.Item>
                    </UI.Form>
                </div>
                <div style={{marginTop:'22px'}}>
                    <UI.Table
                        rowKey="id"
                        dataSource={this.state.tableData}
                        columns={columns}
                        loading={this.state.tableLoad}
                        pagination={{total:this.state.tablePage.total,defaultPageSize:20,defaultCurrent:this.state.tablePage.currPage,onChange:that.GetTable}}/>
                </div>
                <UI.Modal footer={null} visible={that.state.editShow} title={'短信签名管理'} onCancel={()=>{
                    that.setState({
                        editCurr: {},
                        editShow: false
                    })
                }} destroyOnClose>
                    <UI.Form onFinish={that.handle} ref={that.formRef} labelAlign={"left"} labelCol={{span:24}}>
                        <UI.Form.Item label={"签名名称"} name={"name"} rules={[{required:true,message:'请输入签名名称'}]} initialValue={this.state.editCurr.name}>
                            <UI.Input placeholder={"请输入签名名称"}/>
                        </UI.Form.Item>
                        <UI.Form.Item label={"签名使用区域"} name={"domestic"} rules={[{required:true,message:'签名使用区域'}]} initialValue={this.state.editCurr.is_self_method}>
                            <UI.Radio.Group style={{width:'100%'}} value={that.state.editCurr.is_self_method}>
                                <UI.Radio value={true}>中国大陆</UI.Radio>
                                <UI.Radio value={false}>海外（中国大陆之外）</UI.Radio>
                            </UI.Radio.Group>
                        </UI.Form.Item>
                        <UI.Form.Item label={"短信应用"} name={"sms_app"} rules={[{required:true,message:'请选择短信应用'}]} initialValue={this.state.editCurr.sms_app}>
                            <UI.Select placeholder={"请选择短信应用"} defaultValue={that.state.editCurr.sms_app}>
                                {that.state.graphs.map(gp=>{
                                    return (
                                        <Option key={gp.id} value={gp.id}>{gp.name}</Option>
                                    )
                                })}
                            </UI.Select>
                        </UI.Form.Item>
                        <UI.Form.Item  label={"签名类型"} name={"sign_type"} rules={[{required:true,message:'请选择合适的签名类型'}]} initialValue={this.state.editCurr.sign_type}>
                            <UI.Select defaultValue={that.state.editCurr.sign_type} placeholder={"请选择合适的签名类型"} style={{width:"100%"}}>
                                <Option value={0}>公司</Option>
                                <Option value={1}>APP</Option>
                                <Option value={2}>网站</Option>
                                <Option value={3}>公众号或则小程序</Option>
                                <Option value={4}>商标</Option>
                                <Option value={5}>政府 / 机关  / 其他机构</Option>
                            </UI.Select>
                        </UI.Form.Item>
                        <UI.Form.Item  label={"文档类型"} name={"document_type"} rules={[{required:true,message:'请选择合适的文档类型'}]} initialValue={this.state.editCurr.document_type}>
                            <UI.Select defaultValue={that.state.editCurr.document_type} placeholder={"请选择合适的文档类型"} style={{width:"100%"}}>
                                <Option value={0}>三证合一</Option>
                                <Option value={1}>企业营业执照</Option>
                                <Option value={2}>组织机构代码证</Option>
                                <Option value={3}>社会信用代码证</Option>
                                <Option value={4}>应用后台管理截图</Option>
                                <Option value={5}>小程序设置页面截图</Option>
                                <Option value={6}>商标注册证</Option>
                                <Option value={7}>电商平台店铺名全称或简称（阿里云专用）</Option>
                            </UI.Select>
                        </UI.Form.Item>
                        <UI.Form.Item label="证件图片" name={"proof_image"} help={"请使用jpeg格式"} rules={[{required:true,message:'请按你所选择的证件类型上传证件'}]} initialValue={this.state.editCurr.proof_image}>
                            <UI.Upload
                                {...UploadProps}
                                beforeUpload={()=>{
                                    load.Loading()
                                    that.setState({
                                        imgkey: 'proof_image'
                                    })
                                }}
                                listType="picture-card"
                                className="avatar-uploader"
                                showUploadList={false}
                                onChange={that.ImgUpload} name="file">
                                {that.state.editCurr.proof_image ? <img src={that.state.editCurr.proof_image} alt="avatar" style={{ width: '100%' }} /> : "UPLOAD"}
                            </UI.Upload>
                            <UI.Input hidden/>
                        </UI.Form.Item>
                        <UI.Form.Item label={"签名"} name={"sign_content"} rules={[{required:true,message:'请输入签名'}]} initialValue={this.state.editCurr.sign_content}>
                            <UI.Input placeholder={"请输入签名"} disable={that.state.editCurr.id}/>
                        </UI.Form.Item>
                        <UI.Form.Item label={"签名用途"} name={"is_self_method"} rules={[{required:true,message:'请选择签名用途'}]} initialValue={this.state.editCurr.is_self_method}>
                            <UI.Radio.Group style={{width:'100%'}} value={that.state.editCurr.is_self_method}>
                                <UI.Radio value={true}>自用</UI.Radio>
                                <UI.Radio value={false}>他用</UI.Radio>
                            </UI.Radio.Group>
                        </UI.Form.Item>
                        <UI.Form.Item label="委托书" name={"commission_image"} help={"请使用jpeg格式,选填"} rules={[{required:false,message:'请上传委托书'}]} initialValue={this.state.editCurr.commission_image}>
                            <UI.Upload
                                {...UploadProps}
                                beforeUpload={()=>{
                                    load.Loading()
                                    that.setState({
                                        imgkey: 'commission_image'
                                    })
                                }}
                                listType="picture-card"
                                className="avatar-uploader"
                                showUploadList={false}
                                onChange={that.ImgUpload} name="file">
                                {that.state.editCurr.commission_image ? <img src={that.state.editCurr.commission_image} alt="avatar" style={{ width: '100%' }} /> : "UPLOAD"}
                            </UI.Upload>
                        </UI.Form.Item>
                        <UI.Form.Item label={"备注"} name={"remark"} rules={[{required:false,message:'请输入备注'}]} initialValue={this.state.editCurr.remark}>
                            <TextArea rows={5} showCount placeholder={"请输入备注（选填）"}/>
                        </UI.Form.Item>
                        <UI.Form.Item>
                            <UI.Button htmlType={"submit"} type={"primary"} block>提交签名信息</UI.Button>
                        </UI.Form.Item>
                    </UI.Form>
                </UI.Modal>
            </div>
        )
    }
}