import React,{Component,Fragment} from "react";
import * as UI from 'antd'
import { PageHeader } from '@ant-design/pro-layout'
import  {Route,Link,Routes} from "react-router-dom"
import EIamEndpoint from "./EIamEndpoint";
import EIamGroup from "./EIamGroup";
import EIamProduct from "./EIamProduct";
export default class EIamLayout extends React.Component{
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        console.log(this.props)
    }

    render() {
        return (
            <div>
                <div id={"sub-service"} style={{width: "var(--sub-service-width)", position: "fixed", left: React.sidewidth, top: "var(--primary-head-height)"}}>
                    <h2 style={{fontWeight:'bold',margin:"0 auto"}}>权限管理</h2>
                    <UI.Divider/>
                    <UI.Menu mode={"inline"} style={{borderRight:"none"}}>
                        <UI.Menu.Item key={"5"}>
                            <a href={"/user/eiam/product"}>授权产品</a>
                        </UI.Menu.Item>
                        <UI.Menu.Item key={"6"}>
                            <a href={"/user/eiam/endpoint"}>授权节点</a>
                        </UI.Menu.Item>
                        <UI.Menu.Item key={"7"}>
                            <a href={"/user/eiam/group"}>授权组</a>
                        </UI.Menu.Item>
                    </UI.Menu>
                </div>
                <div id={"sub-app-view"} style={{width: `calc(100vw - var(--sub-service-width) - ${React.sidewidth})`, position: "fixed", left: `calc(${React.sidewidth} + var(--sub-service-width))`, top: "var(--primary-head-height)"}}>
                    <Routes>
                        <Route exact path={`/user/eiam/endpoint`} component={EIamEndpoint}></Route>
                        <Route exact path={`/user/eiam/group`} component={EIamGroup}></Route>
                        <Route exact path={`/user/eiam/product`} component={EIamProduct}></Route>
                    </Routes>
                </div>
            </div>
        )
    }
}