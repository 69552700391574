import React,{Component} from "react";
import HttpPost from "../utils/request";
import './home.css';
import * as UI from 'antd'
import { PageHeader } from '@ant-design/pro-layout'
const columns = [
    {
        title: '国家代码',
        dataIndex: 'CCODE',
        key: 'CCODE'
    },
    {
        title: '国家名称',
        dataIndex: 'ShowName',
        key: 'ShowName'
    },
    {
        title: '国家二字码',
        dataIndex: 'countryCode',
        key: 'countryCode'
    },
    {
        title: '配送国家',
        dataIndex: 'country',
        key: 'country'
    }
]
export default class Home extends Component{
    constructor(props) {
        super(props);
        this.state = {
            staf: {},
            country: [],
            loading: false,
            menuList: [],
            news: [],
            newsCurr: {},
            newsShow: false,
            notifys: []
        }
    }
    componentDidMount() {
        this.getUserMenu()
        this.getNews()
        this.getNotify()
    }

    /**
     * 新闻动态
     */
    getNews(){
        var that = this
        var api = "https://notify.alaikis.com/api/post/list"
        HttpPost(api, {category: '79,80',num: 5,page:1}).then(resp=>{
            that.setState({
                news: resp.data.result.data
            })
        })
    }
    /**
     * 通知说明
     */
    getNotify(){
        var that = this
        var api = "https://notify.alaikis.com/api/post/list"
        HttpPost(api, {category: '78',num: 5,page:1}).then(resp=>{
            that.setState({
                notifys: resp.data.result.data
            })
        })
    }
    /**
     * 获得员工目录
     */
    getUserMenu = () =>{
        var that = this
        HttpPost("system/usermenu").then(function (res) {
            that.setState({
                menuList: res.data.data
            })
        })
    }
    render() {
        var that = this
        return (
            <div>
                <UI.Row gutter={16}>
                    <UI.Col span={16}>
                        <h2>服务导航</h2>
                        <UI.Card>
                            <UI.Row gutter={16}>
                                {this.state.menuList.map(item=>{
                                    if (item.level ===2) {
                                        return (
                                            <UI.Col key={item.id}  span={6}  style={{padding:"6px"}}>
                                               <a href={item.redirect_url ? item.redirect_url : item.router} style={{color:"#666666"}}>
                                                    <div style={{backgroundColor:"#d3dce6",padding:"8px",display:"flex",flexDirection:"row",justifyContent:'space-between'}}>
                                                        <span>{item.name}</span>
                                                        <span>-</span>
                                                    </div>
                                               </a>
                                            </UI.Col>
                                        )
                                    }
                                })}
                            </UI.Row>
                        </UI.Card>
                    </UI.Col>
                    <UI.Col span={8}>
                        <UI.Card title={"通知公告"}>
                            <UI.List footer={null} header={null}>
                                {that.state.notifys.map(notify=>{
                                    return (
                                        <UI.List.Item key={notify.Id} onClick={()=>{
                                            that.setState({
                                                newsCurr: notify,
                                                newsShow: true
                                            })
                                        }}>
                                            <h3 style={{fontSize:"12px"}}>{notify.Title}</h3>
                                            <small style={{fontSize:"10px"}}>{notify.PublishAt}</small>
                                        </UI.List.Item>
                                    )
                                })}
                            </UI.List>
                        </UI.Card>
                        <UI.Card title={"新闻动态"}  style={{marginTop:"16px"}}>
                            <UI.List footer={null} header={null}>
                                {that.state.news.map(notify=>{
                                    return (

                                        <UI.List.Item key={notify.Id} onClick={()=>{
                                            that.setState({
                                                newsCurr: notify,
                                                newsShow: true
                                            })
                                        }}>
                                            <h3 style={{fontSize:"12px"}}>{notify.Title}</h3>
                                            <small style={{fontSize:"10px"}}>{notify.PublishAt}</small>
                                        </UI.List.Item>
                                    )
                                })}
                            </UI.List>
                        </UI.Card>
                    </UI.Col>
                </UI.Row>
                <UI.Modal width={700} footer={null} visible={that.state.newsShow} onCancel={()=>{
                    that.setState({
                        newsShow: false
                    })
                }} destoryOnClode>
                    <h2>{that.state.newsCurr.Title}</h2>
                    <UI.Divider/>
                    <div dangerouslySetInnerHTML={{__html: that.state.newsCurr.Content}}></div>
                </UI.Modal>
            </div>
        )
    }
}
