import HttpPost,{GWAPI} from "./request"



var apis = {
     /**
      * 工作流管理
      * @param {*} workflow 
      */
     WorkflowManage: function (workflow) {
        return HttpPost("flow/workflowadmin", workflow)
    },
    /**
     * 重置用户密码
     * @param {*} userId 
     */
    ResetUserPassword: function (userId) {
        return HttpPost("iam/resetuserpassword", {id: userId})
    },
    /**
     * 工作流节点管理
     * @param {*} node 
     */
    WorkflowNodeAdmin: function (node) {
        return HttpPost("flow/nodeadmin", node)
    },
    /**
     * 工作流表单管理
     * @param {*} form 
     */
    WorkflowFormAdmin: function (form) {
        return HttpPost("flow/formadmin", form)
    },
    /**
     * 工作流表单字段管理
     * @param {*} field 
     */
    WorkflowFromFieldAdmin: function (field) {
        return HttpPost("flow/formfieldadmin", field)
    },
    /**
     * 工作流表单字段列表
     * @param {*} formId 
     */
    WorlflowFormFieldList: function (formId) {
        return HttpPost("flow/formfieldlist", {form_id: formId})
    },
    /**
     * 获得工作流表单详情
     * @param {*} formId 
     */
    getWorkflowDetailInfo: function (formId) {
        return HttpPost("flow/formdetail", {id: formId})
    },
    /**
     * 工作流流程设计提交
     * @param {*} line 
     */
    workflowTransitionLineHandle: function (line) {
        return HttpPost("flow/transitionlinehandle", line)
    },
    /**
     * 发送手机短信
     * @param {*} args 
     * @returns 
     */
    sendMmsMessage: function (args) {
        return HttpPost("merchant/smssend", args)
    },
    /**
     * oauth2重新授权
     * @param grantId
     */
    makeOauth: function (grantId) {
        return HttpPost("grant/oauth", {id: grantId})
    },
    /**
     * 执行器注册
     * @param registry
     * @returns {Promise<axios.AxiosResponse<any>> | * | Promise<AxiosResponse<any>>}
     */
    schedulerExecuteRegistry: function (registry) {
        return HttpPost("scheduler/executeregistry", registry)
    },
    /**
     * 已注册执行器列表
     * @param filter
     * @returns {Promise<AxiosResponse<any>> | Promise<axios.AxiosResponse<any>> | *}
     */
    schedulerRegistryList: function (filter) {
        return HttpPost("scheduler/registries", filter)
    },
    /**
     * 系统配置
     * @param filter
     * @returns {Promise<axios.AxiosResponse<any>> | * | Promise<AxiosResponse<any>>}
     */
    systemSettings: function (filter) {
        return HttpPost("system/settings", filter)
    },
    /**
     * 计划任选运行组管理
     * @param group
     * @returns {Promise<AxiosResponse<any>> | Promise<axios.AxiosResponse<any>> | *}
     */
    schedulerExecuteGroupAdmin: function (group) {
        return HttpPost("scheduler/schedulergroup", group)
    },
    /**
     * 计划任务分组列表
     * @param filter
     * @returns {Promise<axios.AxiosResponse<any>> | * | Promise<AxiosResponse<any>>}
     */
    schedulerGroups: function (filter) {
        return HttpPost("scheduler/group", filter)
    },
    /**
     * 计划任务管理
     * @param job
     * @returns {Promise<AxiosResponse<any>> | Promise<axios.AxiosResponse<any>> | *}
     */
    schedulerJobAdmin: function (job) {
        return HttpPost("scheduler/jobadmin", job)
    },
    /**
     * 计划任务列表
     * @param filter
     * @returns {Promise<AxiosResponse<any>> | Promise<axios.AxiosResponse<any>> | *}
     */
    schedulerJobList: function (filter) {
        return HttpPost("scheduler/joblist", filter)
    },
    /**
     * 内鸩穿透客户端管理
     * @param client
     * @returns {Promise<axios.AxiosResponse<any>> | * | Promise<AxiosResponse<any>>}
     */
    inpClientAdmin: function (client) {
        return HttpPost("inp/clientadmin", client)
    },
    /**
     * 内网穿透客户端列表
     * @param filter
     * @returns {Promise<axios.AxiosResponse<any>> | * | Promise<AxiosResponse<any>>}
     */
    inpClientList: function (filter) {
        return HttpPost("inp/clientlist", filter)
    },
    /**
     * 内网穿透域名解析管理
     * @param domain
     * @returns {Promise<axios.AxiosResponse<any>> | * | Promise<AxiosResponse<any>>}
     */
    inpDomainAdmin: function (domain) {
        return HttpPost("inp/domainadmin", domain)
    },
    /**
     * 内网穿透域名解析列表
     * @param filter
     * @returns {Promise<axios.AxiosResponse<any>> | * | Promise<AxiosResponse<any>>}
     */
    inpDomainList: function (filter) {
        return HttpPost("inp/domainlist", filter)
    },
    /**
     * 内网穿透隧道管理
     * @param tunnel
     * @returns {Promise<AxiosResponse<any>> | Promise<axios.AxiosResponse<any>> | *}
     */
    inpTunnelAdmin: function (tunnel) {
        return HttpPost("inp/tunneladmin", tunnel)
    },
    /**
     * 内网穿透隧道列表
     * @param filter
     * @returns {Promise<AxiosResponse<any>> | Promise<axios.AxiosResponse<any>> | *}
     */
    inpTunnelList: function (filter) {
        return HttpPost("inp/tunnellist", filter)
    },
    /**
     * 手动运行任务
     * @param jobId
     * @returns {*}
     */
    manuelTrigger: function (jobId) {
        return HttpPost("scheduler/manualexec", {id: jobId})
    },
    /**
     * 执行参数
     * @param jobId
     * @returns {*}
     */
    schedulerExecutorJobLogs: function (jobId) {
        return HttpPost("scheduler/logs", {id: jobId})
    },
    /**
     * 执行日志详情
     * @param executeId
     * @param logId
     * @returns {Promise<AxiosResponse<any>> | Promise<axios.AxiosResponse<any>> | *}
     */
    schedulerExecutorJobLogDetails: function (executeId, logId) {
        return HttpPost("scheduler/logdetails", {id: executeId, logId: logId})
    },
    /**
     * 短信发送记录
     * @param filter
     * @returns {Promise<AxiosResponse<any>> | Promise<axios.AxiosResponse<any>> | *}
     */
    getSmsRecord: function (filter) {
        return HttpPost("message/smsrecord", filter)
    },
    /**
     * 查询短信发送状态
     * @param id
     * @returns {*}
     */
    getSmsRecordStatus: function (id) {
        return HttpPost("message/getsmssendstatus", {id: id})
    },
    /**
     * 授权产品管理
     * @param product
     * @returns {Promise<AxiosResponse<any>> | Promise<axios.AxiosResponse<any>> | *}
     */
    eiamProductModify: function (product) {
        return HttpPost("eiam/productmodify", product)
    },
    /**
     * 授权产品列表
     * @param filter
     * @returns {*}
     */
    eiamProducts: function (filter) {
        return HttpPost("eiam/productlist", filter)
    },
    /**
     * 授权分组列表
     * @param filter
     * @returns {Promise<AxiosResponse<any>> | Promise<axios.AxiosResponse<any>> | *}
     */
    eiamGroups: function (filter) {
        return HttpPost("eiam/grouplist", filter)
    },
    /**
     * 授权分组管理
     * @param group
     * @returns {Promise<axios.AxiosResponse<any>> | * | Promise<AxiosResponse<any>>}
     */
    eiamGroupAdmin: function (group) {
        return HttpPost("eiam/groupmodify", group)
    },
    /**
     * 授权节点
     * @param filter
     * @returns {Promise<axios.AxiosResponse<any>> | * | Promise<AxiosResponse<any>>}
     */
    eiamEndpoints: function (filter) {
        return HttpPost("eiam/endpointlist", filter)
    },
    /**
     * 节点管理
     * @param endpoint
     * @returns {Promise<axios.AxiosResponse<any>> | * | Promise<AxiosResponse<any>>}
     */
    eiamEndpointAdmin: function (endpoint) {
        return HttpPost("eiam/endpointadmin", endpoint)
    },
    /**
     * 搜索列表
     * @param filter
     * @returns {Promise<AxiosResponse<any>> | Promise<axios.AxiosResponse<any>> | *}
     */
    getAuths: function (filter) {
        return HttpPost("grant/getauths", filter)
    },
    /**
     * 邮件发送记录
     * @param filter
     * @returns {*}
     */
    sesRecord: function (filter) {
        return HttpPost("message/sesrecord", filter)
    },
    /**
     * 系统配置授权列表
     * @param filter
     * @returns {Promise<AxiosResponse<any>> | Promise<axios.AxiosResponse<any>> | *}
     */
    systemConfigureAuthorizes: function (filter) {
        return HttpPost("merchant/specuse/auths", filter)
    },

    /**
     * 消息推送渠道管理
     * @param channel
     * @returns {Promise<AxiosResponse<any>> | Promise<axios.AxiosResponse<any>> | *}
     */
    messagePushChannelModify: function (channel) {
        return HttpPost("message/push/chanenl/modify", channel)
    },

    /**
     * 消息推送渠道列表
     * @param filter
     * @returns {Promise<AxiosResponse<any>> | Promise<axios.AxiosResponse<any>> | *}
     */
    messagePushChannelList: function (filter) {
        return HttpPost("message/push/chanenl/list", filter)
    },
    /**
     * 消息推送渠道管理
     * @param channel
     * @returns {Promise<AxiosResponse<any>> | Promise<axios.AxiosResponse<any>> | *}
     */
    messagePushAudienceModify: function (channel) {
        return HttpPost("message/push/audience/modify", channel)
    },

    /**
     * 消息推送渠道列表
     * @param filter
     * @returns {Promise<AxiosResponse<any>> | Promise<axios.AxiosResponse<any>> | *}
     */
    messagePushAudienceList: function (filter) {
        return HttpPost("message/push/audience/list", filter)
    },

    /**
     * 消息发送
     * @param argument
     * @returns {Promise<AxiosResponse<any>> | Promise<axios.AxiosResponse<any>> | *}
     */
    messagePushSend: function (argument) {
        return HttpPost("message/push/message/send", argument)
    },

    /**
     * 消息推送记录
     * @param filter
     * @returns {Promise<AxiosResponse<any>> | Promise<axios.AxiosResponse<any>> | *}
     */
    messagePushHistory: function (filter) {
        return HttpPost("message/push/message/record", filter)
    }
}

export default apis