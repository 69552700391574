import React,{Component} from "react";
import HttpPost, {UploadProps} from "../../../../../utils/request";
import * as UI from 'antd'
import { PageHeader } from '@ant-design/pro-layout'

import * as load from "../../../../../utils/loading";

const {Option} = UI.Select

export default class SesDomains extends Component{
    constructor(){
        super()
        this.state = {
            tableData: [],
            tablePage: {total: 0,currPage:1},
            tableLoad: false,
            searchKey: {},
            editShow: false,
            editCurr: {},
            graphs: [],
            imgkey: '',
            upLoading: false,
            choosedChannel: null,
            attributes: [],
            attrShow: false
        }
    }
    componentDidMount() {
        this.getGraphs()
        this.getTablePage(1)
    }

    /**
     * 发起搜索
     * @param params
     * @constructor
     */
    MakeSearch=(params)=>{
        console.log(params)
        this.setState({
            keywords: params
        })
        var that = this
        setTimeout(function () {
            that.getTablePage(1)
        },200)
    }
    /**
     * 获得短信渠道字段列表
     */
    getFields(){
        var that = this
        var para = {}
        para.name = 'SmsChannel'
        HttpPost("system/formfields", para).then(resp => {
            that.setState({
                fields: resp.data.data
            })
        })
    }
    /**
     * 获得可用渠道
     */
    getGraphs = () =>{
        var that = this
        var para = {}
        para.code = 'DevConfig'
        para.service = "SESChannels"
        para.pageNumber = 1
        para.pageSize = 10000
        HttpPost("merchant/specuseauths",para).then(resp=>{
            var map = {}
            resp.data.data.data.forEach(ele=>{
                map[ele.id] = ele.grant
            })
            that.setState({
                graphs: resp.data.data.data,
                grantMappping: map
            })
        })
    }

    /**
     * 提交域名
     * @param values
     * @constructor
     */
    Handle=(values)=>{
        var that = this
        var para = {}
        var property = {}
        Object.keys(values).forEach((key , index)=>{
            if (key.indexOf("property__") === -1) {
                para[key] = values[key]
            } else  {
                property[key.split("__")[1]] = values[key]
            }
        })
        para.property = JSON.stringify(property)
        if (Object.keys(that.state.editCurr).length > 0) {
            para.id = that.state.editCurr.id;
        }
        load.Loading();
        HttpPost("merchant/sesdomainadmin",para).then(resp=>{
            load.HasLoaded();
            if (resp.data.code===200){
                if(resp.data.data.code===200){
                    UI.message.success("邮件域名信息提交成功")
                    this.setState({
                        editShow: false,
                        editCurr: {}
                    })
                    this.getTablePage(1)
                } else {
                    UI.message.warning(resp.data.data.msg)
                }
            }else {
                UI.message.error(resp.data.msg)
            }
        })
    }

    /**
     * 获得列表
     * @param page
     * @param pageSize
     */
    getTablePage=(page,pageSize=20)=>{
        let that = this;
        let para = {};
        para.pageNumber = page;
        para.pageSize = pageSize;
        Object.assign(para, that.state.keywords)
        that.setState({
            tableLoad: true
        })
        HttpPost('merchant/sesdomainlist',para).then(function (resp) {
            that.setState({
                tableLoad: false
            })
            var curr = {
                total: resp.data.data.total,
                currPage: page
            }
            that.setState({
                tableData: resp.data.data.data,
                tablePage: curr
            })
        })
    }
    render() {
        var that = this
        var columns = [
            {
                title: "ID",
                dataIndex: "id"
            },
            {
                title: "备注名称",
                dataIndex: "name"
            },
            {
                title: "域名",
                dataIndex: "domain"
            },
            {
                title: "授权通道",
                key: "id",
                render: function (value, row, index) {
                    return (
                        <UI.Button shape={"round"} size={'small'} type={"primary"}>
                            {row.graph.name}
                        </UI.Button>
                    )
                }
            },
            {
                title: "授权平台",
                key: "id",
                render: function (value, row, index) {
                    return (
                        <UI.Button shape={"round"} size={'small'} type={"primary"}>
                            {row.graph.grant.name}
                        </UI.Button>
                    )
                }
            },
            {
                title: "审核通过",
                key: 'id',
                render: function (value, row, index) {
                    return (
                        <UI.Button type={"primary"} shape={"circle"}>
                            {row.state ? '是' : '否'}
                        </UI.Button>
                    )
                }
            },
            {
                title: "应用管理",
                key: "id",
                align: "right",
                fixed: 'right',
                render: function (value, row, index) {
                    return (
                       <span>
                           <UI.Button type={"link"} onClick={()=>{
                               load.Loading()
                               that.setState({
                                   attributes: []
                               })
                               HttpPost("merchant/sesdomainattributes", {id: row.id}).then(resp=>{
                                   load.HasLoaded()
                                   if (resp.data.code === 200 ) {
                                       if (resp.data.data.code === 200) {
                                           that.setState({
                                               attributes: resp.data.data.data,
                                               attrShow: true
                                           })
                                       } else {
                                           UI.message.error(resp.data.data.msg)
                                       }
                                   }else {
                                       UI.message.error(resp.data.msg)
                                   }
                               })
                           }}>配置参数</UI.Button>
                           <UI.Button type={"link"} onClick={()=>{
                               load.Loading()
                               HttpPost("merchant/sesdomainstate", {id: row.id}).then(resp=>{
                                   load.HasLoaded()
                                   if (resp.data.code === 200 ) {
                                       if (resp.data.data.ok) {
                                           UI.message.success("域名审核通过，现在可增加发件帐户")
                                           that.getTablePage(that.state.tablePage.currPage)
                                       } else {
                                           UI.message.error('域名审核中，请耐心等待')
                                       }
                                   }else {
                                       UI.message.error(resp.data.msg)
                                   }
                               })
                           }}>状态检测</UI.Button>
                       </span>

                    )
                }
            }
        ]
        var attrtable=[
            {
                title: "解析类型",
                dataIndex: "Type"
            },
            {
                title: "所属域名",
                dataIndex: "SendDomain"
            },
            {
                title: "解析值",
                key: "ExpectedValue",
                render: function (value, row,index) {
                    return (
                        <span style={{wordBreak:"break-all",whiteSpace:"pre-wrap",wordWrap:"break-word"}}>
                            {row.ExpectedValue}
                        </span>
                    )
                }
            },
            {
                title: "生效",
                key: "ExpectedValue",
                render: function (value, row, index) {
                    return (
                        <UI.Button type={row.Status ? "paimary" : null} danger={!row.Status} shape={"circle"}>
                            {row.Status ? '是' : '否'}
                        </UI.Button>
                    )
                }
            }
        ]
        return (
            <div>
                <PageHeader title="邮件域名管理" subTitle="可对邮件域名进行新增，编辑，认证等操作" extra={[
                    <UI.Button  key="1" type="primary" shape="round" onClick={()=>{
                        that.setState({
                            editCurr: {},
                            editShow: true,
                            choosedChannel: null
                        })
                    }}>新增域名</UI.Button>
                ]}/>
                <div style={{marginTop:'22px'}} id="hot-top-toolbar">
                    <UI.Form onFinish={that.MakeSearch} layout={"inline"}>
                        <UI.Form.Item label={"备注名称"} name={"name__contains"}>
                            <UI.Input placeholder={"请输入备注名称"}/>
                        </UI.Form.Item>
                        <UI.Form.Item label={"应用通道"} name={"Graph__Id"}>
                            <UI.Select placeholder={"请选择合理的应用通道"}>
                                {that.state.graphs.map(gp=>{
                                    return (
                                        <Option key={gp.id} value={gp.id}>{gp.name}</Option>
                                    )
                                })}
                            </UI.Select>
                        </UI.Form.Item>
                        <UI.Form.Item label={"邮件域名"} name={"domain__contains"}>
                            <UI.Input placeholder={"邮件域名"}/>
                        </UI.Form.Item>
                        <UI.Form.Item>
                            <UI.Button type={"primary"} htmlType={"submit"}>域名搜索</UI.Button>
                        </UI.Form.Item>
                    </UI.Form>
                </div>
                <div style={{marginTop:'22px'}}>
                    <UI.Table
                        rowKey="id"
                        dataSource={this.state.tableData}
                        columns={columns}
                        loading={this.state.tableLoad}
                        pagination={{total:this.state.tablePage.total,defaultPageSize:20,defaultCurrent:this.state.tablePage.currPage,onChange:this.getTablePage}}/>
                </div>
                <UI.Modal footer={null} visible={that.state.editShow} title={'短信应用管理'} onCancel={()=>{
                    that.setState({
                        editCurr: {},
                        editShow: false,
                        choosedChannel: null
                    })
                }} destroyOnClose>
                    <UI.Form onFinish={that.Handle} labelAlign={"left"} labelCol={{span:24}}>
                        <UI.Form.Item label={"显示名称"} name={"name"} rules={[{required:true,message:'请输入显示名称'}]} initialValue={this.state.editCurr.name}>
                            <UI.Input placeholder={"请输入显示名称"}/>
                        </UI.Form.Item>
                        <UI.Form.Item label={"邮件平台"} name={"graph"} rules={[{required:true,message:'请选择合理的邮件平台'}]} initialValue={this.state.editCurr.graph}>
                            <UI.Select placeholder={"请选择合理的邮件平台"} defaultValue={that.state.editCurr.graph}>
                                {that.state.graphs.map(gp=>{
                                    return (
                                        <Option key={gp.id} value={gp.id}>{gp.name}</Option>
                                    )
                                })}
                            </UI.Select>
                        </UI.Form.Item>
                        <UI.Form.Item label={"域名"} name={"domain"} rules={[{required:true,message:'请输入你的邮件域名'}]} initialValue={this.state.editCurr.domain}>
                            <UI.Input placeholder={"请输入你的邮件域名"}/>
                        </UI.Form.Item>
                        {that.state.choosedChannel &&
                        <fieldset>
                            {that.state.fields.map(field => {
                                if (parseInt(field.figure) === that.state.choosedChannel) {
                                    return (
                                        <UI.Form.Item help={field.help ? field.help : ''} key={field.id} label={field.label} name={`property__${field.prop}`}
                                                      rules={[{required:field.required,message:`请输入${field.label}`}]}
                                                      initialValue={this.state.editCurr.property ? this.state.editCurr.property[field.prop] : undefined}>
                                            <UI.Input placeholder={`请输入${field.label}`}/>
                                        </UI.Form.Item>
                                    )
                                }
                            })}
                        </fieldset>}
                        <UI.Form.Item>
                            <UI.Button htmlType={"submit"} type={"primary"} block>提交邮件域名信息</UI.Button>
                        </UI.Form.Item>
                    </UI.Form>
                </UI.Modal>
                <UI.Modal visible={that.state.attrShow} width={'600px'} footer={null} onCancel={()=>{
                    that.setState({
                        attributes: [],
                        attrShow: false
                    })
                }} destroyOnClose>
                    <UI.Table
                        rowKey="ExpectedValue"
                        dataSource={this.state.attributes}
                        pagination={null}
                        columns={attrtable}/>
                </UI.Modal>
            </div>
        );
    }
}