import React,{Component,Fragment} from "react";
import * as UI from 'antd'
import { PageHeader } from '@ant-design/pro-layout'
import  {Switch,Route,Link} from "react-router-dom"
import * as load from "../../../../utils/loading";
import HttpPost from "../../../../utils/request";
import moment from "moment";

const {Option} = UI.Select

export default class SaasServices extends Component{
    constructor() {
        super();
        this.state = {
            tableData: [],
            tablePage: {total: 0,currPage:1},
            tableLoad: false,
            searchKey: {},
            editShow: false,
            editCurr: {},
            clients: [],
            imgkey: '',
            upLoading: false
        }
    }
    componentDidMount() {
        this.GetTable(1)
        this.getClients()
    }
    /**
     * 可用运用列表
     */
    getClients(){
        let that = this;
        let para = {};
        para.pageNumber = 1;
        para.pageSize = 99999;
        HttpPost('grant/clientlist',para).then(function (resp) {
            that.setState({
                clients: resp.data.data.data
            })
        })
    }
    /**
     * 数据提交
     * @param values
     */
    handle=(values)=>{
        let that = this;
        if (Object.keys(that.state.editCurr).length > 0) {
            values.id = that.state.editCurr.id;
        }
        var meta = {}
        var para = {}
        Object.keys(values).forEach(key=>{
            console.log(key)
            if (key.indexOf("property__") ===-1){
                console.log("非属性")
                para[key] = values[key]
            } else {
                console.log("属性")
                var  nk = key.replace("property__","")
                console.log(nk)
                meta[nk] = values[key]
            }
        })
        var property = that.state.editCurr.deploy_property !== "" ? that.state.editCurr.deploy_property : {}
        Object.assign(property,meta)
        para.deploy_property = JSON.stringify(property)
        load.Loading();
        HttpPost("service/saasadmin",para).then(resp=>{
            load.HasLoaded();
            if (resp.data.code===200){
                if (resp.data.data.code===200) {
                    UI.message.success("提交软件应用成功")
                    this.setState({
                        editShow: false,
                        editCurr: {}
                    })
                    this.GetTable(1)
                } else {
                    UI.message.warning(resp.data.data.msg)
                }
            }else {
                UI.message.error(resp.data.msg)
            }
        })
    }
    /**
     * 发起搜索
     * @param params
     * @constructor
     */
    MakeSearch=(params)=>{
        console.log(params)
        this.setState({
            searchKey: params
        })
        var that = this
        setTimeout(function () {
            that.GetTable()
        },200)
    }
    /**
     * 签名列表
     * @param pageNumber
     Object.assign(para, that.state.keywords)
     * @param pageSize
     * @constructor
     */
    GetTable=(pageNumber=1,pageSize=20)=>{
        let that = this;
        let para = {};
        para.pageNumber = pageNumber;
        para.pageSize = pageSize;
        that.setState({
            tableLoad: true
        })
        Object.assign(para,that.state.searchKey)
        HttpPost('service/saaslist',para).then(function (resp) {
            that.setState({
                tableLoad: false
            })
            var curr = {
                total: resp.data.data.total,
                currPage: pageNumber
            }
            that.setState({
                tableData: resp.data.data.data,
                tablePage: curr
            })
        })
    }
    render() {
        var that = this
        var columns = [
            {
                title: "ID",
                dataIndex:"id"
            },
            {
                title: "应用名称",
                dataIndex: "name"
            },
            {
                title:"产品",
                key:"id",
                render: function (value, row, index) {
                    var standard = Object.values(JSON.parse(row.item.standard)).join(" / ")
                    return (
                        <span>
                            <h3>{row.item.item.title}</h3>
                            <small>{standard}</small>
                        </span>
                    )
                }
            },
            {
                title:"创建时间",
                key:"id",
                render: function (value, row, index) {
                    return (
                        <UI.Button size={"small"} danger shape={"round"} type={"dashed"}>{moment(row.create_at).format("YYYY-MM-DD hh:mm:ss")}</UI.Button>
                    )
                }
            },
            {
                title:"到期时间",
                key:"id",
                render: function (value, row, index) {
                    return (
                        <UI.Button size={"small"} danger shape={"round"} type={"dashed"}>{moment(row.expired).format("YYYY-MM-DD hh:mm:ss")}</UI.Button>
                    )
                }
            },
            {

                title:"终端",
                key:"id",
                render: function (value, row, index) {
                    return row.client ? row.client.name : '待配置'
                }
            },
            {
                title:"已部署",
                key:"id",
                render: function (value, row, index) {
                    return (
                        <UI.Button danger shape={"circle"}>{row.deploied ? '是' : '否'}</UI.Button>
                    )
                }
            },
            {
                title:"应用管理",
                key:"id",
                align:"center",
                fixed:"right",
                render: function (value, row, index) {
                    return (
                        <UI.Button  type={"link"} onClick={()=>{
                            var data = JSON.parse(JSON.stringify(row))
                            data.deploy_property = data.deploy_property !=="" ? JSON.parse(data.deploy_property) : {}
                            that.setState({
                                editCurr: data,
                                editShow: true
                            })
                        }}>应用编辑</UI.Button>
                    )
                }
            }
        ]
        return (
            <div>
                <PageHeader title="SAAS服务列表" subTitle="对所有采购的SAAS进行续费与更新部署管理"/>
                <div style={{marginTop:'22px',marginButtom:"32px"}}>
                    <UI.Form onFinish={that.MakeSearch} layout={"inline"}>
                        <UI.Form.Item name="Product__Title__icontains" label="产品名称">
                            <UI.Input placeholder="请输入软件名称..."/>
                        </UI.Form.Item>
                        <UI.Form.Item name="Client__Name__icontains" label="应用名称">
                            <UI.Input placeholder="请输入应用名称..."/>
                        </UI.Form.Item>
                        <UI.Form.Item>
                            <UI.Button type={"primary"} htmlType={"submit"}>应用搜索</UI.Button>
                        </UI.Form.Item>
                    </UI.Form>
                </div>
                <div style={{marginTop:'22px'}}>
                    <UI.Table
                        rowKey="id"
                        dataSource={this.state.tableData}
                        columns={columns}
                        loading={this.state.tableLoad}
                        pagination={{total:this.state.tablePage.total,defaultPageSize:20,defaultCurrent:this.state.tablePage.currPage,onChange:this.getTablePage}}/>
                </div>
                <UI.Modal visible={that.state.editShow} title={"应用部署管理"} footer={null} onCancel={()=>{
                    that.setState({
                        editCurr: {},
                        editShow: false
                    })
                }} destroyOnClose>
                    <UI.Form onFinish={that.handle} labelCol={{span:24}} labelAlign={"left"}>
                        <UI.Form.Item label={"商户端系统名称"}
                                      rules={[{required:true,message:'请输入商户端系统名称'}]} initialValue={this.state.editCurr.deploy_property ? this.state.editCurr.deploy_property.appname : null}
                                      name={"property__appname"}>
                            <UI.Input placeholder={'请输入商户端系统名称'}/>
                        </UI.Form.Item>
                        <UI.Form.Item label={"应用客户端"}
                                      rules={[{required:true,message:'请选择应用客户端'}]} initialValue={this.state.editCurr.client ? this.state.editCurr.client.id : null}
                                      name={"client"}>
                            <UI.Select placeholder={"请选择应用客户端"}>
                                {that.state.clients.map(option=>{
                                    return (
                                        <Option value={option.id} key={option.id}>{option.name}</Option>
                                    )
                                })}
                            </UI.Select>
                        </UI.Form.Item>
                        <UI.Form.Item>
                            <UI.Button type="primary" shape="round" block htmlType="submit">提交第四方物流系统基础配置</UI.Button>
                        </UI.Form.Item>
                    </UI.Form>
                </UI.Modal>
            </div>
        )
    }
}