import React,{Component,Fragment} from "react";
import HttpPost, {UploadProps} from "../../../../../utils/request";
import * as UI from 'antd'
import { PageHeader } from '@ant-design/pro-layout'

import * as load from "../../../../../utils/loading";

const {Option} = UI.Select
const {TextArea} =UI.Input

export default class Endpoints extends Component{
    constructor() {
        super();
        this.state={
            tableData: [],
            tablePage: {total: 0,currPage:1},
            tableLoad: false,
            searchKey: {},
            editShow: false,
            editCurr: {},
            graphs: [],
            imgkey: '',
            upLoading: false,
            sendShow: false,
            sendCurr: {},
            grantMappping: {},
            endpoints: [],
            currEndpointKey: null
        }
    }
    componentDidMount() {
        this.getGraphs()
        this.GetTable(1)
        this.getSystemEndpoints()
    }

    /**
     * 获得存放节点
     */
    getSystemEndpoints(){
        var that = this
        HttpPost("system/storageendpoints").then(resp => {
            that.setState({
                endpoints: resp.data.data
            })
        })
    }
    /**
     * 获得可用渠道
     */
    getGraphs = () =>{
        var that = this
        var para = {}
        para.code = 'DevConfig'
        para.service = "StorageChannels"
        para.pageNumber = 1
        para.pageSize = 10000
        HttpPost("merchant/specuseauths",para).then(resp=>{
            var map = {}
            resp.data.data.data.forEach(ele=>{
                map[ele.id] = ele.grant
            })
            that.setState({
                graphs: resp.data.data.data,
                grantMappping: map
            })
        })
    }

    /**
     * 数据提交
     * @param values
     */
    handle=(values)=>{
        let that = this;
        if (Object.keys(that.state.editCurr).length > 0) {
            values.id = that.state.editCurr.id;
        }
        load.Loading();
        HttpPost("/service/storageendpointadmin",values).then(resp=>{
            load.HasLoaded();
            if (resp.data.code===200){
                if(resp.data.data.ok){
                    UI.message.success("存储节点信息提交成功")
                    this.setState({
                        editShow: false,
                        editCurr: {}
                    })
                    this.GetTable(1)
                } else {
                    UI.message.warning(resp.data.data.msg)
                }
            }else {
                UI.message.error(resp.data.msg)
            }
        })
    }
    /**
     * 发起搜索
     * @param params
     * @constructor
     */
    MakeSearch=(params)=>{
        console.log(params)
        this.setState({
            searchKey: params
        })
        var that = this
        setTimeout(function () {
            that.GetTable()
        },200)
    }
    /**
     * 签名列表
     * @param pageNumber
     Object.assign(para, that.state.keywords)
     * @param pageSize
     * @constructor
     */
    GetTable=(pageNumber=1,pageSize=20)=>{
        let that = this;
        let para = {};
        para.pageNumber = pageNumber;
        para.pageSize = pageSize;
        that.setState({
            tableLoad: true
        })
        Object.assign(para,that.state.searchKey)
        HttpPost('/service/storageendpointpage',para).then(function (resp) {
            that.setState({
                tableLoad: false
            })
            var curr = {
                total: resp.data.data.total,
                currPage: pageNumber
            }
            that.setState({
                tableData: resp.data.data.data,
                tablePage: curr
            })
        })
    }
    render() {
        var that = this
        var columns = [
            {
                title: "ID",
                dataIndex: "id"
            },
            {
                title: "备注名称",
                dataIndex: "name"
            },
            {
                title: "平台",
                key: "id",
                render: function (value, row, index) {
                    return row.credential_provider.grant.name
                }
            },
            {
                title: "授权帐户",
                key: "id",
                render: function (value, row, index) {
                    return row.credential_provider.name
                }
            },
            {
                title: "节点",
                key: "id",
                render: function (value, row, index) {
                    return `${row.end_point.name} - ${row.end_point.code}`
                }
            },
            {
                title: "状态",
                key: 'id',
                render: function (value, row, index) {
                    return (
                        <UI.Button type={"primary"} shape={"circle"}>
                            {row.state ? '是' : '否'}
                        </UI.Button>
                    )
                }
            },
            {
                title: "节点管理",
                key: "id",
                align: "right",
                fixed: 'right',
                render: function (value, row, index) {
                    var dd = JSON.parse(JSON.stringify(row))
                    return (
                        <span>
                           <UI.Button onClick={()=>{
                               that.setState({
                                   editCurr: dd,
                                   editShow: true,
                                   currEndpointKey: dd.credential_provider.grant.code

                               })
                           }} type={"link"}>编辑</UI.Button>
                       </span>

                    )
                }
            }
        ]
        return (
            <div>
                <PageHeader title="存储节点管理" subTitle="对存储节点进行集中管理" extra={[
                    <UI.Button  key="1" type="primary" shape="round" onClick={()=>{
                        that.setState({
                            editCurr: {},
                            editShow: true,
                            currEndpointKey: null
                        })
                    }}>新增存储节点</UI.Button>
                ]}/>
                <div style={{marginTop:'22px'}} id="hot-top-toolbar">
                    <UI.Form onFinish={that.MakeSearch} layout={"inline"}>
                        <UI.Form.Item label={"帐户名称"} name={"name__contains"}>
                            <UI.Input placeholder={"请输入帐户名称"}/>
                        </UI.Form.Item>
                        <UI.Form.Item label={"域名"} name={"domain"}>
                            <UI.Select placeholder={"请选择帐户域名"}>
                                {that.state.graphs.map(gp=>{
                                    return (
                                        <Option key={gp.id} value={gp.id}>{gp.domain}</Option>
                                    )
                                })}
                            </UI.Select>
                        </UI.Form.Item>
                        <UI.Form.Item>
                            <UI.Button type={"primary"} htmlType={"submit"}>节点搜索</UI.Button>
                        </UI.Form.Item>
                    </UI.Form>
                </div>
                <div style={{marginTop:'22px'}}>
                    <UI.Table
                        rowKey="id"
                        dataSource={this.state.tableData}
                        columns={columns}
                        loading={this.state.tableLoad}
                        pagination={{total:this.state.tablePage.total,defaultPageSize:20,defaultCurrent:this.state.tablePage.currPage,onChange:this.getTablePage}}/>
                </div>
                <UI.Modal visible={that.state.editShow} title={'存储节点管理'} onCancel={()=>{
                    that.setState({
                        editCurr: {},
                        editShow: false,
                        currEndpointKey: null
                    })
                }} footer={null} destroyOnClose>
                    <UI.Form onFinish={that.handle} labelCol={{span:24}} labelAlign={"left"}>
                        <UI.Form.Item label={"节点名称"} name={"name"} rules={[{required:true,message:'请输入节点名称'}]} initialValue={this.state.editCurr.name}>
                            <UI.Input placeholder={"请输入节点名称"}/>
                        </UI.Form.Item>

                        <UI.Form.Item label={"平台通道"} name={"credential_provider"} rules={[{required:true,message:'请选择平台通道'}]} initialValue={that.state.editCurr.credential_provider ? that.state.editCurr.credential_provider.id : null}>
                            <UI.Select placeholder={"请选择平台通道"} disabled={!that.state.editCurr.id ? false: true} onChange={(e) => {
                                var auth = that.state.grantMappping[e]
                                console.log(auth)
                                that.setState({
                                    currEndpointKey: auth.code
                                })
                            }}>
                                {that.state.graphs.map(gp=>{
                                    return (
                                        <Option key={gp.id} value={gp.id}>{gp.name}</Option>
                                    )
                                })}
                            </UI.Select>
                        </UI.Form.Item>
                        {that.state.currEndpointKey &&
                        <UI.Form.Item label={"存储节点"} name={"end_point"} rules={[{required:true,message:'请选择存储节点'}]} initialValue={that.state.editCurr.end_point ? that.state.editCurr.end_point.id : null}>
                            <UI.Select disabled={!that.state.editCurr.id ? false: true}  placeholder={"请选择合适的节点"}>
                                {that.state.endpoints.map(end => {
                                    if (end.privider_code === that.state.currEndpointKey) {
                                        return (
                                            <Option value={end.id} key={end.id}>{end.name} - {end.code}</Option>
                                        )
                                    }
                                })}
                            </UI.Select>
                        </UI.Form.Item>
                        }

                        <UI.Form.Item>
                            <UI.Button htmlType={"submit"} type={"primary"} block>提交存储节点信息</UI.Button>
                        </UI.Form.Item>
                    </UI.Form>
                </UI.Modal>
            </div>
        );
    }
}