/**
 * author: Alex Lai
 * Support Website: https://laialex.com
 * Email: alex@laialex.com
 * Create Time: 2024/9/13
 */
import React, { Component } from 'react'
import * as ui from 'antd'
import apis from '../../../../utils/apis'
import { BellOutlined } from '@ant-design/icons'
import { PageHeader } from '@ant-design/pro-layout';


const { TextArea } = ui.Input
const { Option } = ui.Select

export default class Record extends Component {
  constructor() {
    super()
    this.state = {
      tableData: [],
      paging: { total: 0, pageNumber: 1, pageSize: 20 },
      tableLoad: false,
      searchKey: {},
      editShow: false,
      editCurr: {},
      submitting: false,
    }
  }

  componentDidMount() {
    this.tableDataSync(1)
  }


  /**
   * 发起搜索
   * @param params
   * @constructor
   */
  makeSearch = (params) => {
    this.setState({
      searchKey: params
    })
    var that = this
    setTimeout(function() {
      that.tableDataSync()
    }, 200)
  }
  /**
   * 签名列表
   * @param pageNumber
   Object.assign(para, that.state.keywords)
   * @param pageSize
   * @constructor
   */
  tableDataSync = (pageNumber = 1) => {
    let that = this
    let para = {}
    para.pageNumber = pageNumber
    para.pageSize = 20
    that.setState({
      tableLoad: true
    })
    Object.assign(para, that.state.searchKey)
    apis.messagePushHistory(para).then(function(resp) {
      that.setState({
        tableLoad: false
      })
      var curr = {
        total: resp.data.data.total,
        pageNumber: pageNumber
      }
      that.setState({
        tableData: resp.data.data.data,
        paging: curr
      })
    })
  }


  render() {
    var that = this

    const columns = [
      {
        title: 'ID',
        dataIndex: 'id'
      },
      {
        title: '群发消息',
        key: 'id',
        render: function(value, row, index) {
          return <ui.Button shape={'circle'} type={'primary'} danger={row.account.is_group}>{row.account.is_group ? '是' : '否'}</ui.Button>
        }
      },
      {
        title: '通道',
        key: 'id',
        render: function(value, row, index) {
          return row.account.push_channel.id
        }
      },
      {
        title: '受众',
        key: 'id',
        render: function(value, row, index) {
          return row.account.show_name
        }
      },
      {
        title: '绑定帐户',
        key: 'id',
        render: function(value, row, index) {
          return row.account.user_name
        }
      },
      {
        title: '内容',
        key: 'id',
        render: function(value, row, index) {
          return row.arguments
        }
      },
      {
        title: '已发送',
        key: 'id',
        render: function(value, row, index) {
          return (
            <ui.Button shape={'round'} type={'circle'}>{row.effected ? '是' : '否'}</ui.Button>
          )
        }
      },
      {
        title: '报错信息',
        key: 'id',
        fixed: 'right',
        render: function(value, row, index) {
          return row.ret_msg
        }
      }
    ]

    return (
      <div>
        <PageHeader title="消息推送历史记录" subTitle="详细记录消息推送的信息以及失败原因，推送状态等" />
        <ui.Card>
          <div style={{ marginTop: '22px' }}>
            <ui.Form onFinish={that.makeSearch} layout={'inline'}>
              <ui.Form.Item label={'收件人'} name={'account__show_name__contains'}>
                <ui.Input placeholder={'收件人'} />
              </ui.Form.Item>
              <ui.Form.Item>
                <ui.Button type={'primary'} htmlType={'submit'}>快速搜索</ui.Button>
              </ui.Form.Item>
            </ui.Form>
          </div>
        </ui.Card>

        <div style={{ marginTop: '22px' }}>
          <ui.Table
            rowKey="id"
            dataSource={this.state.tableData}
            columns={columns}
            loading={this.state.tableLoad}
            pagination={{
              total: this.state.paging.total,
              defaultPageSize: 20,
              defaultCurrent: this.state.paging.pageNumber,
              onChange: this.tableDataSync
            }} />
        </div>
      </div>
    )
  }
}