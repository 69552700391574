import React from "react";
import * as UI from "antd";
import { PageHeader } from '@ant-design/pro-layout';
import HttpPost from "../../../utils/request";
import * as load from "../../../utils/loading"
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import * as fa from '@fortawesome/fontawesome-free-solid'

const {Search,TextArea}=UI.Input;
const { Meta } = UI.Card;
const { Option } = UI.Select;

export default class Corpus extends React.Component{
    constructor() {
        super();
        this.state = {
            tableData: [],
            tablePage: {total: 0,currPage:1},
            tableLoad: false,
            searchKey: {},
            editShow: false,
            editCurr: {},
            lans: [],
            groups: []
        }
    }
    componentDidMount() {
        this.GetTable()
        this.GetLans()
        this.GetGroups()
    }
    /**
     * 场景列表
     * @constructor
     */
    GetGroups(){
        var that = this
        var para = {}
        para.pageNumber = 1;
        para.pageSize = 999;
        HttpPost('translate/corpusgroups',para).then(function (resp) {
            that.setState({
                groups: resp.data.data.data,
            })
        })
    }
    /**
     * 获得语言列表
     * @constructor
     */
    GetLans () {
        var that = this;
        var api = 'https://api.hottol.com/standard/open/language/list';
        HttpPost(api).then(function (res) {
            that.setState({
                lans: res.data.data,
            })
        })
    }
    /**
     * 分页查询
     * @param pageNumber
     * @param pageSize
     * @constructor
     */
    GetTable=(pageNumber=1,pageSize=20)=>{
        let that = this;
        let para = {};
        para.pageNumber = pageNumber;
        para.pageSize = pageSize;
        Object.assign(para, that.state.searchKey)
        that.setState({
            tableLoad: true
        })
        HttpPost('translate/corpus',para).then(function (resp) {
            that.setState({
                tableLoad: false
            })
            let curr = {
                total: resp.data.data.total,
                currPage: pageNumber
            }
            that.setState({
                tableData: resp.data.data.data,
                tablePage: curr
            })
        })
    }
    MakeSearch=(keywords)=>{
        var that = this;
        this.setState({
            searchKey: Object.assign(that.state.searchKey,{keywords: keywords})
        })
        that.GetTable(1)
    }
    handle=(values)=>{
        let that = this;
        if (Object.keys(that.state.editCurr).length > 0) {
            values.id = that.state.editCurr.id;
        }
        load.Loading();
        HttpPost("translate/corpusadmin",values).then(resp=>{
            load.HasLoaded();
            if (resp.data.code===200){
                if(resp.data.data.code===200){
                    UI.message.success("授权分组信息维护完成")
                    this.setState({
                        editShow: false,
                        editCurr: {}
                    })
                    this.GetTable(1)
                } else {
                    UI.message.warning(resp.data.data.msg)
                }
            }else {
                UI.message.error(resp.data.msg)
            }
        })
    }
    render() {
        var that = this
        const columns = [
            {
                title:'ID',
                dataIndex: 'id',
                key: 'id'
            },
            {
                title:'场景',
                dataIndex: 'group',
                key: 'group',
                render: function (v, row, index) {
                    return row.group ? row.group.name : "通用翻译"
                }
            },
            {
                title:'原始语言',
                dataIndex: 'from',
                key: 'from'
            },
            {
                title:'目标语言',
                dataIndex: 'to',
                key: 'to'
            },
            {
                title:'语料',
                dataIndex: 'corpus',
                key: 'corpus'
            },
            {
                title:'翻译',
                dataIndex: 'text',
                key: 'text'
            },
            {
                title: '语料管理',
                key:'operation',
                align:'right',
                width: 220,
                render: (col,row,index)=>{
                    var data = JSON.parse(JSON.stringify(row))
                    if(row.group){
                        data.group = row.group.id
                    }
                    return (
                        <div className="opt">
                            <UI.Button type="link" onClick={()=>{
                                that.setState({
                                    editCurr:data,
                                    editShow: true
                                })
                            }}>编辑</UI.Button>
                        </div>
                    )
                }
            }
        ]
        return (
            <div id="corpus">
                <PageHeader title="语料管理" subTitle="配置翻译个性化语料，使翻译系统能够更专业的进行个性化，专业化翻译" extra={[
                    <UI.Button  key="1" type="primary" shape="round" onClick={()=>{
                        that.setState({
                            editCurr: {},
                            editShow: true
                        })
                    }}>新增语料</UI.Button>
                ]}/>
                <div style={{marginTop:'22px'}} id="hot-top-toolbar">
                    <UI.Form onFinish={that.MakeSearch} layout={"inline"}>
                        <UI.Form.Item name="from" label="原始语言">
                            <UI.Select placeholder="请选择原始语言" showSearch>
                                {that.state.lans.map(lan=>{
                                    return (
                                        <Option key={lan.id} value={lan.Code}>{lan.Name}</Option>
                                    )
                                })}
                            </UI.Select>
                        </UI.Form.Item>
                        <UI.Form.Item name="to">
                            <UI.Select placeholder="请选择目标语言" showSearch>
                                {that.state.lans.map(lan=>{
                                    return (
                                        <Option key={lan.id} value={lan.Code}>{lan.Name}</Option>
                                    )
                                })}
                            </UI.Select>
                        </UI.Form.Item>
                        <UI.Form.Item name="group" label="所属场景">
                            <UI.Select placeholder="请选择所属场景" showSearch>
                                {that.state.groups.map(gp=>{
                                    return (
                                        <Option key={gp.id} value={gp.id}>{gp.name}</Option>
                                    )
                                })}
                            </UI.Select>
                        </UI.Form.Item>
                        <UI.Form.Item name="corpus__icontains" label="原始文本">
                            <UI.Input placeholder="请输入原始文本..."/>
                        </UI.Form.Item>
                        <UI.Form.Item>
                            <UI.Button type={"primary"} htmlType={"submit"}>语料搜索</UI.Button>
                        </UI.Form.Item>
                    </UI.Form>
                </div>
                <div style={{marginTop:'22px'}}>
                    <UI.Table
                        rowKey="id"
                        dataSource={this.state.tableData}
                        columns={columns}
                        loading={this.state.tableLoad}
                        pagination={{total:this.state.tablePage.total,defaultPageSize:20,defaultCurrent:this.state.tablePage.currPage,onChange:this.GetTable}}/>
                </div>
                <UI.Modal title="新增授权分组" visible={this.state.editShow} footer={null} onCancel={()=>{
                    this.setState({
                        editShow: false
                    })
                }} destroyOnClose>
                    <UI.Form labelCol={{span:24}} labelAlign="left" onFinish={this.handle}>
                        <UI.Form.Item name="from" label="原始语言" rules={[{required:true,message:'请选择原始语言'}]} initialValue={this.state.editCurr.from}>
                            <UI.Select placeholder="请选择原始语言"
                                       filterOption={(input, option) =>
                                           option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                       }
                                       showSearch>
                                {that.state.lans.map(lan=>{
                                    return (
                                        <Option key={lan.id} value={lan.Code}>{lan.Name}</Option>
                                    )
                                })}
                            </UI.Select>
                        </UI.Form.Item>
                        <UI.Form.Item name="to" label="目标语言" rules={[{required:true,message:'请选择目标语言'}]} initialValue={this.state.editCurr.to}>
                            <UI.Select placeholder="请选择目标语言"
                                       filterOption={(input, option) =>
                                           option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                       }
                                       showSearch>
                                {that.state.lans.map(lan=>{
                                    return (
                                        <Option key={lan.id} value={lan.Code}>{lan.Name}</Option>
                                    )
                                })}
                            </UI.Select>
                        </UI.Form.Item>
                        <UI.Form.Item name="group" label="所属场景" rules={[{required:false,message:'请选择所属场景'}]} initialValue={this.state.editCurr.group}>
                            <UI.Select placeholder="请选择所属场景" showSearch>
                                {that.state.groups.map(gp=>{
                                    return (
                                        <Option key={gp.id} value={gp.id}>{gp.name}</Option>
                                    )
                                })}
                            </UI.Select>
                        </UI.Form.Item>
                        <UI.Form.Item name="corpus" label="原始文本" rules={[{required:true,message:'请输原始文本'}]} initialValue={this.state.editCurr.corpus}>
                            <TextArea rows={6} placeholder="请输入原始文本..."/>
                        </UI.Form.Item>
                        <UI.Form.Item name="text" label="翻译文本" rules={[{required:true,message:'请输翻译文本'}]} initialValue={this.state.editCurr.text}>
                            <TextArea rows={6} placeholder="请输入翻译文本..."/>
                        </UI.Form.Item>
                        <UI.Form.Item style={{textAlign:'right'}}>
                            <UI.Button type="primary" shape="round" htmlType="submit">提交新的翻译规则</UI.Button>
                        </UI.Form.Item>
                    </UI.Form>
                </UI.Modal>
            </div>
        )
    }
}