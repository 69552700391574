import React from "react";
import * as UI from "antd";
import { PageHeader } from '@ant-design/pro-layout';
import HttpPost,{UploadProps} from "../../../utils/request";
import * as load from "../../../utils/loading"

const {Search, TextArea}=UI.Input;

export default class Invoice extends React.Component{
    constructor(props) {
        super(props);
        this.state={
            orders:[],
            tablePage: {total: 0,currPage:1,pageSize:15},
            tableLoad: false,
            searchKey: {}
        }
    }
    componentDidMount() {
        this.GetTable(1)
    }

    MakeSearch=(keywords)=>{
        var that = this;
        this.setState({
            searchKey: Object.assign(that.state.searchKey,{id: keywords})
        })
        that.GetTable(1)
    }
    GetTable=(page,pageSize=20)=>{
        let that = this;
        let para = {};
        para.pageNumber = page;
        para.pageSize = pageSize;
        Object.assign(para, that.state.searchKey)
        that.setState({
            tableLoad: true
        })
        HttpPost('system/systeminvoice',para).then(function (resp) {
            that.setState({
                tableLoad: false
            })
            var curr = {
                total: resp.data.data.total,
                currPage: page
            }
            that.setState({
                tableData: resp.data.data.data,
                tablePage: curr
            })
        })
    }
    render() {
        var tableConfig = [
            {
                title:'流水号',
                dataIndex: 'id',
                key: 'id',
                width: "120",
                ellipsis: true,
            },
            {
              title: "订单号",
              dataIndex: "order_id",
              key: "order_id"
            },
            {
                title:'已结算',
                dataIndex: 'effected',
                key: 'effected',
                render: function (v,row,index) {
                    return (
                        <UI.Button type={row.effected ? "primary" : "danger"} size={"small"} shape={"round"}>
                            {row.effected ? "已结算" : "待支付"}
                        </UI.Button>
                    )
                }
            },
            {
                title: "结算金额",
                dataIndex: "amount",
                key: "amount",
                render: function (v,row,index) {
                    return Math.abs(row.amount)
                }
            },
            {
                title: "结算货币",
                dataIndex: "currency",
                key: "currency"
            },
            {
                title: "科目",
                dataIndex: "amount",
                key: "amount",
                render: function (v,row,index) {
                    return row.amount > 0 ? '收入' : '支出'
                }
            },
            {
                title: "支付方式",
                dataIndex: "payment.name",
                key: "payment.name",
                render: function (v,row,index) {
                    return row.payment.name
                }
            },
            {
                title: "支付渠道",
                dataIndex: "payment.grant.name",
                key: "payment.grant.name",
                render: function (v,row,index) {
                    return row.payment.grant.name
                }
            },
            {
                title:'结算时间',
                dataIndex: 'create_at',
                key: 'create_at',
                render: function (v,row,index) {
                    var dd = new Date(row.create_at)
                    return dd.toLocaleDateString()+' '+dd.toLocaleTimeString()
                }
            },
            {
                title:'最后更新',
                dataIndex: 'update_at',
                key: 'update_at',
                render: function (v,row,index) {
                    var dd = new Date(row.update_at)
                    return dd.toLocaleDateString()+' '+dd.toLocaleTimeString()
                }
            }
        ]
        return (
            <div>
                <PageHeader
                    title="用户消费流水"
                    subTitle="用户可对历史消费进入查看与管理"/>
                <div id="tablecontains">
                    <div id="toolbar">
                        <Search placeholder="请输入订单号,流水号,结算单号..." enterButton="订单搜索" size="large" onSearch={this.MakeSearch}/>
                    </div>
                </div>
                <div id="table">
                    <UI.Table
                        rowKey="orderId"
                        dataSource={this.state.tableData}
                        columns={tableConfig}
                        loading={this.state.tableLoad}
                        pagination={{onChange:this.GetTable,total:this.state.tablePage.total,pageSize:10,current:this.state.tablePage.currPage}}/>
                </div>
            </div>
        )
    }
}
