import React from "react";
import * as UI from "antd";
import { PageHeader } from '@ant-design/pro-layout';
import HttpPost,{UploadProps} from "../../../../utils/request";
import * as load from "../../../../utils/loading"
import moment from "moment";

const {Search, TextArea}=UI.Input;
const { Option } = UI.Select;

export default class Authorizes extends React.Component {
    constructor () {
        super();
        this.state = {
            editCurr: {},
            editShow: false,
            imageUrl: null,
            loading: false,
            tableData: [],
            tablePage: {total: 0,currPage:1},
            tableLoad: false,
            searchKey: {}
        }
    }
    componentDidMount () {
        this.getTablePage()
    }
    /**
     * 发起搜索
     * @param params
     * @constructor
     */
     MakeSearch=(params)=>{
        console.log(params)
        this.setState({
            searchKey: params
        })
        var that = this
        setTimeout(function () {
            that.getTablePage(1)
        },200)
    }
    /**
     * 获得应用分页
     * @param page
     */
     getTablePage=(page = 1)=>{
        let that = this;
        let para = {};
        para.pageNumber = page;
        para.pageSize = 20;
        Object.assign(para, that.state.searchKey)
        that.setState({
            tableLoad: true
        })
        HttpPost('grant/developers',para).then(function (resp) {
            that.setState({
                tableLoad: false
            })
            var curr = {
                total: resp.data.data.total,
                currPage: page
            }
            that.setState({
                tableData: resp.data.data.data,
                tablePage: curr
            })
        })
    }
    /**
     * 提交应用信息
     * @param values
     */
    handle = (values) => {
        let that = this;
        load.Loading();
        HttpPost("grant/developergenerator",values).then(resp=>{
            load.HasLoaded();
            if (resp.data.code===200){
                if(resp.data.data.code===200){
                    UI.message.success("新增应用帐户成功")
                    this.setState({
                        editShow: false,
                        editCurr: {}
                    })
                    this.getTablePage(1)
                } else {
                    UI.message.warning(resp.data.data.msg)
                }
            }else {
                UI.message.error(resp.data.msg)
            }
        })
    }
    render () {
        let that = this
        let columns = [
            {
                title:'key',
                dataIndex: 'appId',
                key: 'appId'
            },
            {
                title:'secret',
                dataIndex: 'secret',
                key: 'appId'
            },
            {
                title:'终端名称',
                key: 'appId',
                render: function (value,row,inde) {
                    return (
                        <UI.Button shape="round" type="dashed ">{row.client.name}</UI.Button>
                    )
                }

            },
            {
                title:'应用名称',
                key: 'appId',
                render: function (value,row,inde) {
                    return (
                        <UI.Button shape="round" type="dashed">{row.client.app.name}</UI.Button>
                    )
                }

            },
            {
                title: '状态',
                key: 'state',
                render: function (value,row,index) {
                    return (
                        <UI.Button type={row.state ? 'primary' : 'danger'} size="small" shape={"round"}>
                            {row.state ? '可用' : '禁用'}
                        </UI.Button>
                    )
                }
            },
            {
                title: '创建时间',
                key: 'state',
                render: function (value,row,index) {
                    return (
                        <UI.Button type={'dashed'}  size="small" shape={"round"}>
                            {moment(row.create_at).format("YYYY/MM/DD HH:mm:ss")}
                        </UI.Button>
                    )
                }
                 
            },
            {
                title: '管理@',
                key: 'state',
                render: function (value,row,index) {
                    return (
                        <div>
                            {row.state && 
                            <UI.Button shape="round" type="link">禁用</UI.Button>
                            }
                        </div>
                    )
                }
            }
            
        ]
        return (
            <div>
                <PageHeader title="开发者集中管理" subTitle="终端开发者帐户集中生成与状态管理" extra={[
                    <UI.Button type={"primary"} shape={"round"} onClick={()=>{
                        that.setState({
                            editCurr: {},
                            editShow: true
                        })
                    }}>生成新的开发者帐户</UI.Button>
                ]}/>
                <div id="tablecontains">
                    <div id="toolbar">
                        <UI.Form onFinish={that.MakeSearch} layout={"inline"}>
                            <UI.Form.Item label={"key"} name={"AppId"}>
                                <UI.Input placeholder={"key"}/>
                            </UI.Form.Item>
                            <UI.Form.Item label={"终端名称"} name={"Client__Name__icontains"}>
                                <UI.Input placeholder={"请输入终端名称"}/>
                            </UI.Form.Item>
                            <UI.Form.Item label={"应用名称"} name={"Client__APP__Name__icontains"}>
                                <UI.Input placeholder={"请输入应用名称"}/>
                            </UI.Form.Item>
                            <UI.Form.Item>
                                <UI.Button type={"primary"} htmlType={"submit"}>开发者搜索</UI.Button>
                            </UI.Form.Item>
                        </UI.Form>
                    </div>
                </div>
                <div style={{marginTop:'22px'}}>
                    <UI.Table
                        rowKey="appId"
                        dataSource={this.state.tableData}
                        columns={columns}
                        loading={this.state.tableLoad}
                        pagination={<UI.Pagination total={this.state.tablePage.total} defaultCurrent={this.state.tablePage.currPage}/>}/>
                </div>
                <UI.Modal title="授权管理" visible={this.state.editShow} footer={null} onCancel={()=>{
                    this.setState({
                        editShow: false,
                        editCurr: {}
                    })
                }} destroyOnClose>
                    <UI.Form labelCol={{span:24}} labelAlign="left" onFinish={this.handle}>
                        <UI.Form.Item name="clientId" label="终端ID" rules={[{required:true,message:'终端ID'}]} initialValue={this.state.editCurr.name}>
                            <UI.Input placeholder="终端ID..."/>
                        </UI.Form.Item>
                        <UI.Form.Item style={{textAlign:'right'}}>
                            <UI.Button type="primary" shape="round" htmlType="submit">生成新的授权</UI.Button>
                        </UI.Form.Item></UI.Form>
                </UI.Modal>
            </div>
        )
    }
}