import React from "react";
import * as UI from "antd";
import { PageHeader } from '@ant-design/pro-layout';
import HttpPost,{UploadProps} from "../../../../../utils/request";
import * as load from "../../../../../utils/loading"

const {Search, TextArea}=UI.Input;
const { Option } = UI.Select;

export default class LogisticsSystemAccount extends React.Component{
    constructor() {
        super();
        this.state={
            editCurr: {},
            editShow: false,
            group: [],
            imageUrl: null,
            loading: false,
            tableData: [],
            tablePage: {total: 0,currPage:1},
            tableLoad: false,
            apps: [],
            clients: [],
            CreateId: 1
        }
    }
    componentDidMount() {
        this.GetTable(1)
        this.GetGrantGroups()
        this.GetApps()
    }

    /**
     * 获得商户应用
     * @constructor
     */
    GetApps = () => {
        let that = this;
        let para = {};
        para.pageNumber = 1;
        para.pageSize = 99999;
        HttpPost('grant/applist',para).then(function (resp) {
            that.setState({
                apps: resp.data.data.data
            })
        })
    }
    /**
     * 权限分组
     * @constructor
     */
    GetGrantGroups=()=>{
        let that = this;
        let para = {};
        para.pageNumber = 1;
        para.pageSize = 20000;
        HttpPost('grant/grantgrouplist',para).then(function (resp) {
            that.setState({
                group: resp.data.data.data,
            })
        })
    }
    /**
     * 在线搜索
     * @param keywords
     * @constructor
     */
    MakeSearch=(keywords)=>{
        var that = this;
        this.setState({
            searchKey: Object.assign(that.state.searchKey,{keywords: keywords})
        })
        that.getTablePage(1)
    }
    GetTable=(page,pageSize=20)=>{
        let that = this;
        let para = {};
        para.pageNumber = page;
        para.pageSize = pageSize;
        Object.assign(para, that.state.searchKey)
        that.setState({
            tableLoad: true
        })
        HttpPost('system/getlslist',para).then(function (resp) {
            that.setState({
                tableLoad: false
            })
            var curr = {
                total: resp.data.data.total,
                currPage: page
            }
            that.setState({
                tableData: resp.data.data.data,
                tablePage: curr
            })
        })
    }
    /**
     * 提交应用信息
     * @param values
     * @constructor
     */
    Handle=(values)=>{
        var that = this
        values.imgUrl = that.state.imageUrl
        values.id = that.state.editCurr.id
        load.Loading()
        HttpPost("system/logisticsinit",values).then(function (res) {
            load.HasLoaded()
            if (res.data.code === 200) {
                UI.message.success("客户端信息修改成功")
                that.GetTable(that.state.tablePage.currPage)
                that.setState({
                    currShow: false
                })
            } else {
                UI.message.error("客户端修改失改")
            }
        })
    }
    /**
     * 图标上传状态变化
     * @param info
     * @constructor
     */
    ImgUpload = (info) => {
        var that = this;
        load.HasLoaded();
        if (info.file.status === 'done') {
            console.log(info.file)
            that.setState({
                imageUrl: info.file.response.data.data.url
            })
        }
    }
    /**
     * 图片上传前开启加载
     * @param file
     */
    beforeUpload = (file) => {
        load.Loading();
    }
    AppBlock =()=>{
        var that = this
        if (!that.state.editCurr.client) {
            return (
                <span>
                    <UI.Form.Item label="所属应用" name="app" rules={[{required:true,message:"请设置所属应用"}]} help="请慎重选择，选择后即不可修改">
                        <UI.Select placeholder="请设置所属应用..." showSearch={true}>
                            {this.state.apps.map((item,index)=>{
                                return (
                                    <Option key={index} value={item.id}>{item.name}</Option>
                                )
                            })}
                        </UI.Select>
                    </UI.Form.Item>
                    <UI.Form.Item label="终端LOGO" required>
                            <UI.Upload
                                {...UploadProps}
                                beforeUpload={that.beforeUpload}
                                listType="picture-card"
                                className="avatar-uploader"
                                showUploadList={false}
                                onChange={that.ImgUpload} name="file">
                                {that.state.imageUrl ? <img src={that.state.imageUrl} alt="avatar" style={{ width: '100%' }} /> : "UPLOAD"}
                            </UI.Upload>
                        </UI.Form.Item>
                    <UI.Form.Item label="客户端介绍"  rules={[{required:true,message:"请输入终端介绍"}]} name="intro">
                        <TextArea rows={6}></TextArea>
                    </UI.Form.Item>
                </span>
            )
        }else {
            return null
        }
    }
    render() {
        let that = this;
        let tableConfig = [
            {
                title:'ID',
                dataIndex: 'id',
                key: 'id',
                width: "120",
                ellipsis: true,
            },
            {
                title:'服务名称',
                dataIndex: 'name',
                key: 'name',
            },
            {
                title:'版本',
                dataIndex: 'version',
                key: 'version',
                render: function (v,row,index) {
                    return JSON.parse(row.services.standard).version
                }
            },
            {
                title: '配送授权组',
                dataIndex: "logistics",
                key: 'logistics',
                render: function (v,row,index) {
                    return  row.logistics ? row.logistics.name : '等待初始化'
                }
            },
            {
                title: '支付授权组',
                dataIndex: "pay",
                key: 'pay',
                render: function (v,row,index) {
                    return  row.pay ? row.pay.name : '等待初始化'
                }
            },
            {

                title:'创建时间',
                dataIndex: 'creat_at',
                key: 'creat_at',
                render:function (v,row,index) {
                    var dd = new Date(row.creat_at)
                    return `${dd.toLocaleDateString()} ${dd.toLocaleTimeString()}`
                }
            },
            {

                title:'到期时间',
                dataIndex: 'expire_at',
                key: 'expire_at',
                render:function (v,row,index) {
                    var dd = new Date(row.expire_at)
                    return `${dd.toLocaleDateString()} ${dd.toLocaleTimeString()}`
                }
            },
            {
                title:'是否到期',
                dataIndex: 'expire_at',
                key: 'expire_at',
                render: function (v,row,index) {
                    var now = new Date()
                    var expire= new Date(row.expire_at)
                    return (
                        <UI.Button type={now >= expire ? "danger" : "primary"} shape={"circle"}>
                            {now >= expire ? "是" : "否"}
                        </UI.Button>
                    )
                }
            },
            {
                title: "系统管理",
                render: function (v,row,index) {
                    var now = new Date()
                    var expire= Date(row.expire_at)
                    if(now>=expire){
                        return (
                            <UI.Button type={"link"}>立即续费</UI.Button>
                        )
                    } else {
                        return (
                            <UI.Button.Group>
                                <UI.Button type={"link"} onClick={(()=>{
                                    var config = {}
                                    config.name = row.name
                                    if (row.logistics) {
                                        config.logistics = row.logistics.id
                                    }
                                    if (row.client){
                                        config.client = row.client.id
                                    }
                                    config.id = row.id
                                    if (row.pay) {
                                        config.pay = row.pay.id
                                    }
                                    that.setState({
                                        editCurr:config,
                                        editShow: true
                                    })
                                })}>配置</UI.Button>
                                {/*<UI.Button type={"link"}>域名</UI.Button>*/}
                                {/*<UI.Button type={"link"}>应用</UI.Button>*/}
                            </UI.Button.Group>
                        )
                    }
                }
            }
        ]

        return (
            <div>
                <PageHeader title="第四方物流自动部署系统" subTitle="用户可根据需要部署以及配置无限套第四方物流系统，且不同系统间可以选择是否使用相同的会员系统" extra={[
                    <a href="/purchase/item/4pl" key="2"><UI.Button type="primary" shape="round">系统采购</UI.Button></a>
                ]}/>
                <div id="tablecontains">
                    <div id="toolbar">
                        <Search placeholder="请输入服务名称..." enterButton="订单搜索" size="large" onSearch={this.MakeSearch}/>
                    </div>
                </div>
                <div id="table">
                    <UI.Table
                        rowKey="id"
                        dataSource={this.state.tableData}
                        columns={tableConfig}
                        loading={this.state.tableLoad}
                        pagination={{onChange:this.GetTable,total:this.state.tablePage.total,pageSize:10,current:this.state.tablePage.currPage}}/>
                </div>
                <UI.Modal title="第四方物流系统在线配置管理" footer={null} visible={that.state.editShow} onCancel={()=>{
                    that.setState({
                        editShow:false
                    })
                }} destroyOnClose>
                    <UI.Form onFinish={this.Handle} labelCol={{span:24}} labelAlign={"left"}>
                        <UI.Form.Item
                            label="系统名称"
                            rules={[{required:true,message:"请输入系统名称"}]}
                            initialValue={that.state.editCurr.name}
                            name="name">
                            <UI.Input placeholder="请输入系统名称..."/>
                        </UI.Form.Item>
                        <that.AppBlock/>
                        <UI.Form.Item label="配送授权分组" name="logistics" rules={[{required:true,message:"请选择快递授权分组"}]}  initialValue={that.state.editCurr.logistics}>
                            <UI.Select placeholder="请选择快递授权分组..." showSearch={true}>
                                {this.state.group.map((item,index)=>{
                                    return (
                                        <Option key={index} value={item.id}>{item.name}</Option>
                                    )
                                })}
                            </UI.Select>
                        </UI.Form.Item>
                        <UI.Form.Item label="支付授权分组" name="pay" rules={[{required:true,message:"请支付授权分组"}]}  initialValue={that.state.editCurr.pay}>
                            <UI.Select placeholder="请选择支付授权分组..." showSearch={true}>
                                {this.state.group.map((item,index)=>{
                                    return (
                                        <Option key={index} value={item.id}>{item.name}</Option>
                                    )
                                })}
                            </UI.Select>
                        </UI.Form.Item>
                        <UI.Form.Item style={{textAlign:'right'}}>
                            <UI.Button type="primary" shape="round" htmlType="submit">提交第四方物流系统基础配置</UI.Button>
                        </UI.Form.Item>
                    </UI.Form>
                </UI.Modal>
            </div>
        )
    }
}
