import React from 'react'
import ReactDOM from 'react-dom';
import * as UI from "antd";
import { PageHeader } from '@ant-design/pro-layout';

var Loading=()=>{
    let doc=document.createElement("div");
    doc.setAttribute("id","loading");
    doc.style.width='100vw';
    doc.style.height='100vh';
    doc.style.zIndex=9999999999;
    doc.style.position='fixed';
    doc.style.top=0;
    doc.style.left=0;
    doc.style.textAlign='center';
    doc.style.paddingTop='35vh';
    // doc.style.backgroundColor='rgba(255,255,255,.8)'
    document.body.appendChild(doc);
    ReactDOM.render(
        <UI.Spin tip="请求中..." size="large"/>,
        doc
    );
}

var HasLoaded=()=>{
    try {
        document.body.removeChild(document.getElementById("loading"));
    } catch (e) {
        console.log(e);
    }

}

var LoadingStart = Loading

var LoadingEnded = HasLoaded

export {
    HasLoaded,
    Loading,
    LoadingStart,
    LoadingEnded
}
