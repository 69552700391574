import React from "react";
import * as UI from "antd";
import { PageHeader } from '@ant-design/pro-layout';
import HttpPost,{UploadProps} from "../../../utils/request";
import * as load from "../../../utils/loading"

const {Search, TextArea}=UI.Input;
export default class Orders extends React.Component{
    constructor(props) {
        super(props);
        this.state={
            orders:[],
            tablePage: {total: 0,currPage:1,pageSize:15},
            tableLoad: false,
            searchKey: {}
        }
    }
    componentDidMount() {
        this.GetOrder(1)
    }

    MakeSearch=(keywords)=>{
        var that = this;
        this.setState({
            searchKey: Object.assign(that.state.searchKey,{id: keywords})
        })
        that.GetOrder(1)
    }
    GetOrder=(page,pageSize=10)=>{
        let that = this;
        let para = {};
        para.pageNumber = page;
        para.pageSize = pageSize;
        Object.assign(para, that.state.searchKey)
        that.setState({
            tableLoad: true
        })
        HttpPost('system/orderlist',para).then(function (resp) {
            that.setState({
                tableLoad: false
            })
            var curr = {
                total: resp.data.data.total,
                currPage: page
            }
            that.setState({
                tableData: resp.data.data.data,
                tablePage: curr
            })
        })
    }
    render() {
        let tableConfig = [
            {
                title:'订单号',
                dataIndex: 'orderId',
                key: 'orderId',
                width: "120",
                ellipsis: true,
            },
            {
                title: "交易流水号",
                dataIndex: 'transaction',
                key: 'transaction',
                width: "220",
                ellipsis: true,
                render: function(v,row,index){
                    if (row.transaction) {
                        return (
                            <span>{row.transaction.id}</span>
                        )
                    }else {
                        return "尚未结算"
                    }
                }
            },
            {
                title: '订单交付',
                dataIndex: 'transaction',
                key: 'transaction',
                render: function (v,row,index) {
                    if (row.transaction) {
                        return (
                            <UI.Button type={row.fulfill ? "primary" : "danger"} size={"small"} shape={"round"}>
                                {row.fulfill ? "已交付" : "未交付"}
                            </UI.Button>
                        )
                    }else {
                        return "待结算"
                    }
                }
            },
            {
                title: '是否支付',
                dataIndex: 'fulfill',
                key: 'fulfill',
                render: function (v,row,index) {
                    if (row.transaction) {
                        return (
                            <UI.Button type={row.transaction.effected ? "primary" : "danger"} size={"small"} shape={"round"}>
                                {row.transaction.effected ? "已经支付" : "待支付"}
                            </UI.Button>
                        )
                    }else {
                        return "待结算"
                    }
                }
            },
            {
                title: "下单时间",
                dataIndex: 'create_at',
                key: "create_at",
                render: function (value,row,index) {
                    var dd = new Date(value)
                    return `${dd.toLocaleDateString()} ${dd.toLocaleTimeString()}`
                }
            },
            {
                title: "订单管理",
                dataIndex: "opt",
                key: "opt",
                align:"right",
                fixed: "right",
                render: function (value,row,index) {
                    var opts = []
                    if (!row.fulfill) {
                        opts.push(<UI.Button type={"link"} onClick={()=>{
                            var pata= {}
                            pata.orderId = row.orderId
                            load.Loading()
                            HttpPost("system/manualdeploy",pata).then(function (resp) {
                                load.HasLoaded()
                                if (resp.data.code===200){
                                    if (resp.data.data.code===200){
                                        UI.message.success("订单交付完成")
                                    } else {
                                        UI.message.warning(resp.data.data.error)
                                    }
                                } else {
                                    UI.message.warning(resp.data.msg)
                                }
                            })
                        }
                        }>手动部署</UI.Button>)
                    }
                    if (!row.transaction) {
                        opts.push(<a href={'/purchase/recharge/' + row.orderId} target="_blank"><UI.Button type={"link"}>立即结算</UI.Button></a>)
                    }
                    return (
                        opts.map(curr=>{
                            return curr
                        })
                    )
                }
            }
        ]
        return (
            <div>
                <PageHeader
                    title="产品订单管理"
                    subTitle="查看历史订单以及对订单进行售后处理以及结算"/>
                <div id="tablecontains">
                    <div id="toolbar">
                        <Search placeholder="请输入订单号..." enterButton="订单搜索" size="large" onSearch={this.MakeSearch}/>
                    </div>
                </div>
                <div id="table">
                    <UI.Table
                        rowKey="orderId"
                        dataSource={this.state.tableData}
                        columns={tableConfig}
                        loading={this.state.tableLoad}
                        pagination={{onChange:this.GetOrder,total:this.state.tablePage.total,pageSize:10,current:this.state.tablePage.currPage}}/>
                </div>
            </div>
        )
    }

}
