import React from "react";
import * as UI from "antd";
import { PageHeader } from '@ant-design/pro-layout';
import HttpPost,{UploadProps} from "../../../utils/request";
import * as load from "../../../utils/loading"

const {Search, TextArea}=UI.Input;
const { Option } = UI.Select;

export default class Merchant extends React.Component{
    constructor(props){
        super(props)
        this.state={
            merchant: {}
        }
    }
    componentDidMount(){
        this.getMerchant()
    }
    /**
     * 获得商户信息
     */
    getMerchant=()=>{
        var that = this
        load.Loading()
        HttpPost("merchant/merchantinformation").then(resp=>{
            load.HasLoaded()
            that.setState({
                merchant:resp.data.data
            })
        })
    }
    /**
     * 提交商户信息修改
     */
    Handle=(para)=>{
        var that = this
        load.Loading()
        HttpPost("merchant/update",para).then(resp=>{
            load.HasLoaded()
            if(resp.data.code===200){
                UI.message.success("商户信息更新成功")
                that.getMerchant()
            }else{
                UI.message.error("商户信息更新失败，请稍后重试!")
            }
        })
    }
    render(){
        var that = this
        if (Object.keys(that.state.merchant).length){
            return (
                <div style={{width:"520px",margin:"28px auto",backgroundColor:"#ffffff",padding:"16px"}}>
                    <PageHeader
                        className="site-page-header"
                        onBack={() => null}
                        title={that.state.merchant.name}
                        subTitle={this.state.merchant.userType === "bussniess" ? "企业帐户" : "个人帐户"}
                    />
                    <UI.Form onFinish={this.Handle} labelCol={{span:24}} labelAlign={"left"}>
                        <UI.Form.Item 
                        label="公司名称" 
                        rules={[{required:true,message:"请填写公司名称"}]}
                        initialValue={this.state.merchant.name}
                        name="name">
                            <UI.Input placeholder="请输入公司名..."/>
                        </UI.Form.Item>
                        <UI.Form.Item 
                        label="联系人" 
                        rules={[{required:true,message:"请填写联系人姓名"}]}
                        initialValue={this.state.merchant.contract}
                        name="contract">
                            <UI.Input placeholder="请输入公司名..."/>
                        </UI.Form.Item>
                        <UI.Form.Item style={{textAlign:'right'}}>
                            <UI.Button type="primary" shape="round" htmlType="submit">提交商户信息</UI.Button>
                        </UI.Form.Item>
                    </UI.Form>
                </div>
            )
        } else{
            return null
        }
        
    }
}