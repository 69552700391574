/**
 * author: Alex Lai
 * Support Website: https://laialex.com
 * Email: alex@laialex.com
 * Create Time: 2023/11/13
 */
import React,{Component} from "react";
import * as UI from "antd";
import { PageHeader } from '@ant-design/pro-layout';
import apis from "../../../../utils/apis";
import {BellOutlined} from "@ant-design/icons";
import {LoadingEnded, LoadingStart} from "../../../../utils/loading";



const {TextArea} = UI.Input
const {Option} = UI.Select

export default class Job extends Component{
    constructor() {
        super();
        this.state = {
            tableData: [],
            paging: {total: 0,pageNumber: 1, pageSize: 20},
            tableLoad: false,
            searchKey: {},
            editShow: false,
            editCurr: {},
            submitting: false,
            executeGroup: [],
            glueCurr: {},
            glueShow: false,
            glueHandling: false,
            jobLogs: [],
            jobLogLoading: false,
            jobLogShow: false,
            jobLobViewCurr: {},
            jobLogDetailShow: false,
            jobLogDetailData: {}
        }
    }
    componentDidMount() {
        this.executeGroupSync()
        this.tableDataSync(1)
    }

    /**
     * 加载执行分组
     */
    executeGroupSync () {
        var param = {}
        param.pageNumber = 1
        param.pageSize = 2000
        var that = this
        apis.schedulerGroups(param).then(res => {
            that.setState({
                executeGroup: res.data.data.data
            })
        })
    }

    /**
     * 发起搜索
     * @param params
     * @constructor
     */
    makeSearch=(params)=>{
        this.setState({
            searchKey: params
        })
        var that = this
        setTimeout(function () {
            that.tableDataSync()
        },200)
    }
    /**
     * 签名列表
     * @param pageNumber
     Object.assign(para, that.state.keywords)
     * @param pageSize
     * @constructor
     */
    tableDataSync=(pageNumber=1)=>{
        let that = this;
        let para = {};
        para.pageNumber = pageNumber;
        para.pageSize = 20;
        that.setState({
            tableLoad: true
        })
        Object.assign(para,that.state.searchKey)
        apis.schedulerJobList(para).then(function (resp) {
            that.setState({
                tableLoad: false
            })
            var curr = {
                total: resp.data.data.total,
                pageNumber: pageNumber
            }
            that.setState({
                tableData: resp.data.data.data,
                paging: curr
            })
        })
    }



    render() {
        var that = this

        const  columns = [
            {
                title: "ID",
                dataIndex: "id"
            },
            {
                title: "执行组名称",
                key: "id",
                render: function (value, row, index) {
                    return (
                        <UI.Button type={"dashed"} shape={"round"} size={"small"} danger>{row.group.app_name}</UI.Button>
                    )
                }
            },
            {
                title: "任务名称",
                key: "id",
                render: function (value, row, index) {
                    return (
                        <div>{row.name}</div>
                    )
                }
            },
            {
                title: "通知邮箱",
                key: "id",
                render: function (value, row, index) {
                    return (
                        <UI.Button shape={"round"} type={"dashed"}>{row.alarm_email}</UI.Button>
                    )
                }
            },
            {
                title: "负责人",
                key: "id",
                render: function (value, row, index) {
                    return (
                        <UI.Button shape={"round"} type={"dashed"}>{row.author}</UI.Button>
                    )
                }
            },
            {
                title: "创建时间",
                key: "id",
                render: function (value, row, index) {
                    var dd = new Date(row.created_at)
                    return (
                        <UI.Button shape={"round"} type={"dashed"}>{dd.toLocaleDateString()} {dd.toLocaleTimeString()}</UI.Button>
                    )
                }
            },
            {
                title: "可用",
                key: "id",
                render: function (value, row, index) {
                    return (
                        <UI.Button shape={"round"} type={"circle"}>{row.trigger_status ?　'是' : '否'}</UI.Button>
                    )
                }
            },
            {
                title: "管理",
                key: "id",
                fixed: "right",
                width: "120",
                render: function (value, row, index) {
                    return (
                      <span>
                             <UI.Button onClick={() => {
                                 var data = JSON.parse(JSON.stringify(row))
                                 data.group = data.group.id
                                 that.setState({
                                     editCurr: data,
                                     editShow: true
                                 })
                             }} type={'link'}>编辑</UI.Button>
                            <UI.Button onClick={() => {
                                var data = JSON.parse(JSON.stringify(row))
                                data.group = data.group.id
                                that.setState({
                                    glueCurr: data,
                                    glueShow: true
                                })
                            }} type={'link'}>GLUE编辑</UI.Button>
                            <UI.Button onClick={() => {
                                var data = JSON.parse(JSON.stringify(row))
                                LoadingStart()
                                apis.manuelTrigger(row.id).then(res => {
                                    LoadingEnded()
                                    if (res.data.code === 200 && res.data.data.code === 200) {
                                        UI.message.success(res.data.data.data)
                                    } else {
                                        UI.message.error(res.data.data.data ? res.data.data.data : res.data.msg)
                                    }
                                })
                            }} type={'link'}>手动运行</UI.Button>
                            <UI.Button onClick={() => {
                                var data = JSON.parse(JSON.stringify(row))
                                // LoadingStart()
                                that.setState({
                                    jobLogLoading: true,
                                    jobLogShow: false,
                                    jobLobViewCurr: data
                                })
                                apis.schedulerExecutorJobLogs(row.id).then(res => {
                                    // LoadingEnded()
                                    that.setState({
                                        jobLogs: res.data.data.data.data,
                                        jobLogLoading: false,
                                        jobLogShow: true
                                    })
                                })
                            }} type={'link'}>日志查看</UI.Button>
                        </span>
                    )
                }
            }
        ]

        return (
          <div>
              <PageHeader title="计划任务管理" subTitle="计划任务的配置，管理，手动触发" extra={[
                  <UI.Button key="1" type="primary" shape="round" onClick={() => {
                      that.setState({
                          editCurr: {},
                          editShow: true,
                          submitting: false
                      })
                  }}>新增计划任务</UI.Button>
              ]} />
              <div style={{ color: 'darkcyan', fontSize: '10px', marginBottom: '16px' }}>
                  <BellOutlined /> 免费用户可注册两个执行终端，每天总运行CU为500
              </div>
              <UI.Card>
                  <div style={{ marginTop: '22px' }} id="hot-top-toolbar">
                      <UI.Form onFinish={that.MakeSearch} layout={"inline"}>
                          <UI.Form.Item label={"任务名称"} name={"name__contains"}>
                              <UI.Input placeholder={"任务名称"} />
                          </UI.Form.Item>
                          <UI.Form.Item>
                              <UI.Button type={"primary"} htmlType={"submit"}>快速搜索</UI.Button>
                          </UI.Form.Item>
                      </UI.Form>
                  </div>
              </UI.Card>

              <div style={{ marginTop: '22px' }}>
                  <UI.Table
                    rowKey="id"
                    dataSource={this.state.tableData}
                    columns={columns}
                    loading={this.state.tableLoad}
                    pagination={{
                        total: this.state.paging.total,
                        defaultPageSize: 20,
                        defaultCurrent: this.state.paging.pageNumber,
                        onChange: this.tableDataSync
                    }} />
              </div>

              <UI.Modal visible={that.state.editShow} title={'定时任务执行客户端名称'} onCancel={() => {
                  that.setState({
                      editCurr: {},
                      editShow: false,
                      submitting: false
                  })
              }} footer={null} destroyOnClose>
                  <UI.Form onFinish={(params => {
                      that.setState({
                          submitting: true
                      })
                      if (that.state.editCurr.id) {
                          params.id = that.state.editCurr.id
                      }
                      params.glue_source = that.state.editCurr.glue_source
                      params.glue_remark = this.state.editCurr.glue_remark
                      apis.schedulerJobAdmin(params).then(resp => {
                          that.setState({
                              submitting: false
                          })
                          if (resp.data.code === 200 && resp.data.data.code === 200) {
                              UI.message.success("提交信息成功")
                              that.tableDataSync(that.state.editCurr.id ? that.state.paging.pageNumber : 1)
                              that.setState({
                                  submitting: false,
                                  editShow: false,
                                  editCurr: {}
                              })
                          } else {
                              UI.message.error(resp.data.data.msg ? resp.data.data.msg : resp.data.msg)
                          }
                      })
                  })} labelCol={{ span: 24 }} labelAlign={"left"} initialValues={that.state.editCurr}>
                      <UI.Form.Item label={"执行任务名称"} name={"name"}
                                    rules={[{ required: true, message: '执行任务名称' }]}>
                          <UI.Input placeholder={"执行任务名称"} />
                      </UI.Form.Item>
                      <UI.Form.Item label={"执行组"} name={"group"} rules={[{ required: true, message: '执行组' }]}>
                          <UI.Select placeholder={"先选择你要配置的执行组"} style={{ width: "100%" }}>
                              {that.state.executeGroup.map(item => {
                                  return (
                                    <Option value={item.id} key={item.id}>{item.app_name}</Option>
                                  )
                              })}
                          </UI.Select>
                      </UI.Form.Item>
                      <UI.Form.Item label={"执行任务状态"} name={"trigger_status"}
                                    rules={[{ required: true, message: '执行客户端名称' }]}>
                          <UI.Radio.Group>
                              <UI.Radio value={true}>可用</UI.Radio>
                              <UI.Radio value={false}>禁用</UI.Radio>
                          </UI.Radio.Group>
                      </UI.Form.Item>
                      <UI.Form.Item label={"任务备注"} name={"remark"}
                                    rules={[{ required: true, message: '任务备注' }]}>
                          <TextArea rows={6} placeholder={"执行任务名称"} />
                      </UI.Form.Item>
                      <UI.Form.Item label={"任务负责人"} name={"author"}
                                    rules={[{ required: true, message: '任务负责人' }]}>
                          <UI.Input placeholder={"任务负责人"} />
                      </UI.Form.Item>
                      <UI.Form.Item label={"通知邮箱"} name={"alarm_email"}
                                    rules={[{ required: true, message: '通知邮箱' }]}>
                          <UI.Input placeholder={"通知邮箱"} />
                      </UI.Form.Item>
                      <UI.Form.Item label={"调度类型"} name={"scheduler_type"}
                                    rules={[{ required: true, message: '调度类型' }]}>
                          <UI.Radio.Group>
                              <UI.Radio value={"CRON"}>CRON</UI.Radio>
                              <UI.Radio value={"FIX_RATE"}>固定速度</UI.Radio>
                          </UI.Radio.Group>
                      </UI.Form.Item>
                      <UI.Form.Item label={"调度标准"} name={"scheduler_standard"}
                                    rules={[{ required: true, message: '调度标准' }]}
                                    help={"若固定速度则填入周期（秒），若cron则填入cron公式"}>
                          <UI.Input placeholder={"调度标准"} />
                      </UI.Form.Item>

                      {/*任务配置*/}
                      <UI.Form.Item label={"执行器任务handler"} name={"executor_handler"}
                                    rules={[{ required: false, message: '执行器任务handler' }]}
                                    help={"在运行模式为BEAN时填写"}>
                          <UI.Input placeholder={"执行器任务handler"} />
                      </UI.Form.Item>
                      <UI.Form.Item label={"任务参数"} name={"executor_param"}
                                    rules={[{ required: false, message: '任务参数' }]}>
                          <TextArea rows={6} placeholder={"任务参数"} />
                      </UI.Form.Item>

                      <UI.Form.Item label={"运行模式"} name={"glue_type"}
                                    rules={[{ required: true, message: '过期策略' }]}>
                          <UI.Radio.Group>
                              <UI.Radio value={"BEAN"}>BEAN</UI.Radio>
                              <UI.Radio value={"GLUE_GROOVY"}>GLUE(Java)</UI.Radio>
                              <UI.Radio value={"GLUE_SHELL"}>GLUE(Shell)</UI.Radio>
                              <UI.Radio value={"GLUE_PYTHON"}>GLUE(Python)</UI.Radio>
                              <UI.Radio value={"GLUE_PHP"}>GLUE(PHP)</UI.Radio>
                              <UI.Radio value={"GLUE_NODEJS"}>GLUE(Nodejs)</UI.Radio>
                              <UI.Radio value={"GLUE_POWERSHELL"}>GLUE(PowerShell)</UI.Radio>
                          </UI.Radio.Group>
                      </UI.Form.Item>

                      <UI.Form.Item label={"过期策略"} name={"misfire_strategy"}
                                    rules={[{ required: true, message: '过期策略' }]}>
                          <UI.Radio.Group>
                              <UI.Radio value={"DO_NOTHING"}>忽略</UI.Radio>
                              <UI.Radio value={"FIRE_ONCE_NOW"}>立即执行一次</UI.Radio>
                          </UI.Radio.Group>
                      </UI.Form.Item>
                      <UI.Form.Item label={"阻塞处理策略"} name={"executor_block_strategy"}
                                    rules={[{ required: true, message: '过期策略' }]}>
                          <UI.Radio.Group>
                              <UI.Radio value={"SERIAL_EXECUTION"}>单机串行</UI.Radio>
                              <UI.Radio value={"DISCARD_LATER"}>丢弃后续调度</UI.Radio>
                              <UI.Radio value={"COVER_EARLY"}>覆盖之前调度</UI.Radio>
                          </UI.Radio.Group>
                      </UI.Form.Item>
                      <UI.Form.Item label={"调度路由策略"} name={"executor_route_strategy"}
                                    rules={[{ required: true, message: '调度路由策略' }]}>
                          <UI.Radio.Group>
                              <UI.Radio value={"FIRST"}>第一个</UI.Radio>
                              <UI.Radio value={"LAST"}>最后一个</UI.Radio>
                              <UI.Radio value={"ROUND"}>轮询</UI.Radio>
                              <UI.Radio value={"RANDOM"}>随机</UI.Radio>
                              <UI.Radio value={"CONSISTENT_HASH"}>一致性HASH</UI.Radio>
                              <UI.Radio value={"LEAST_FREQUENTLY_USED"}>最不经常使用</UI.Radio>
                              <UI.Radio value={"LEAST_RECENTLY_USED"}>最近最久未使用</UI.Radio>
                              <UI.Radio value={"FAILOVER"}>故障转移</UI.Radio>
                              <UI.Radio value={"BUSYOVER"}>忙碌转移</UI.Radio>
                              <UI.Radio value={"SHARDING_BROADCAST"}>分片广播</UI.Radio>
                          </UI.Radio.Group>
                      </UI.Form.Item>
                      <UI.Form.Item label={"任务超时时间"} name={"executor_timeout"}
                                    rules={[{ required: false, message: '任务超时时间' }]}>
                          <UI.InputNumber style={{ width: "100%" }} placeholder={"任务超时时间,单位秒，大于0生效"} />
                      </UI.Form.Item>
                      <UI.Form.Item label={"失败重试次数"} name={"executor_fail_retry_count"}
                                    rules={[{ required: false, message: '失败重试次数' }]}>
                          <UI.InputNumber style={{ width: "100%" }} placeholder={"失败重试次数,单位秒，大于0生效"} />
                      </UI.Form.Item>
                      <UI.Form.Item>
                          <UI.Button htmlType={"submit"} loading={that.state.submitting} type={"primary"}
                                     block>立即提交
                          </UI.Button>
                      </UI.Form.Item>
                  </UI.Form>
              </UI.Modal>
              <UI.Modal visible={that.state.glueShow} title={'定时任务执行客户端名称'} onCancel={() => {
                  that.setState({
                      glueCurr: {},
                      glueShow: false,
                      glueHandling: false
                  })
              }} footer={null} destroyOnClose>
                  <UI.Form onFinish={(params => {
                      that.setState({
                          glueHandling: true
                      })
                      params.id = that.state.glueCurr.id
                      apis.schedulerJobAdmin(params).then(resp => {
                          that.setState({
                              glueHandling: false
                          })
                          if (resp.data.code === 200 && resp.data.data.code === 200) {
                              UI.message.success("提交信息成功")
                              that.tableDataSync(that.state.editCurr.id ? that.state.paging.pageNumber : 1)
                              that.setState({
                                  glueHandling: false,
                                  glueShow: false,
                                  glueCurr: {}
                              })
                          } else {
                              UI.message.error(resp.data.data.msg ? resp.data.data.msg : resp.data.msg)
                          }
                      })
                  })} labelCol={{ span: 24 }} labelAlign={"left"} initialValues={that.state.glueCurr}>
                      <UI.Form.Item label={"执行脚本"} name={"glue_source"}
                                    rules={[{ required: true, message: '执行脚本' }]}>
                          <TextArea rows={10} placeholder={"执行脚本"} />
                      </UI.Form.Item>
                      <UI.Form.Item label={"修改备注"} name={"glue_remark"}
                                    rules={[{ required: true, message: '修改备注' }]}>
                          <TextArea rows={3} placeholder={"修改备注"} />
                      </UI.Form.Item>
                      <UI.Form.Item>
                          <UI.Button htmlType={"submit"} loading={that.state.glueHandling} type={"primary"}
                                     block>立即提交
                          </UI.Button>
                      </UI.Form.Item>
                  </UI.Form>
              </UI.Modal>
              <UI.Modal mask={true} width={"755px"} open={that.state.jobLogShow} title={'执行日志'} onCancel={() => {
                  that.setState({
                      jobLogs: [],
                      jobLogLoading: false,
                      jobLogShow: false
                  })
              }} footer={null} destroyOnClose>
                  <div style={{ marginTop: '22px' }}>
                      <UI.Table
                        rowKey="id"
                        dataSource={this.state.jobLogs}
                        columns={[
                            {
                                title: "ID",
                                dataIndex: "id"
                            },
                            {
                                title: "类型",
                                key: "id",
                                render: function (value, row, index) {
                                    var msgType = {
                                        "0": "请求成功",
                                        "1": '执行成功',
                                        "2": '执行失败',
                                        "3": '运行状态',
                                    }
                                    return <UI.Button type={'dashed'}>{msgType[row.alarmStatus.toString()]}</UI.Button>
                                }
                            },
                            {
                                title: "消息",
                                key: "id",
                                render: function (value, row, index) {
                                    return <div>
                                        <div>{row.handleMsg}</div>
                                        <small>{row.executorAddress}</small>
                                    </div>
                                }
                            },
                            {
                                title: "创建时间",
                                dataIndex: "handleTime"
                            },
                            {
                                title: "操作",
                                key: "id",
                                render: function (value, row, index) {
                                    return (
                                      <span>
                                          <UI.Button onClick={() => {
                                              UI.notification.open({
                                                  title: '任务执行结果',
                                                  message: <span
                                                    dangerouslySetInnerHTML={{ __html: row.triggerMsg }}></span>
                                              })
                                          }} type={'link'}>运行详情</UI.Button>
                                          <UI.Button onClick={() => {
                                              LoadingStart()
                                              apis.schedulerExecutorJobLogDetails(that.state.jobLobViewCurr.id, row.id).then(res => {
                                                  LoadingEnded()
                                                  if (res.data.code === 200 && res.data.data.code === 200 & res.data.data.data.code === 200) {
                                                      that.setState({
                                                          jobLogDetailData: res.data.data.data.content
                                                      })
                                                      UI.notification.open({
                                                          title: '详细执行日志',
                                                          style: {width: "70vw"},
                                                          message: <div
                                                            style={{height:"90vh",overflowY: "auto"}}
                                                            dangerouslySetInnerHTML={{ __html: that.state.jobLogDetailData.logContent.replace(/\n/g,'<br/>') }}></div>,
                                                          theme: {
                                                                width: "80vw"
                                                          },
                                                          duration: null
                                                      })
                                                  } else {
                                                      UI.message.error(res.data.data.msg ? res.data.data.data.msg ? res.data.data.data.msg : res.data.data.msg : res.data.msg)
                                                  }
                                              })
                                          }} type={'link'}>执行日志</UI.Button>
                                      </span>
                                    )
                                }
                            }
                        ]}
                        loading={this.state.jobLogLoading}
                        pagination={false} />
                  </div>
              </UI.Modal>
          </div>
        )
    }
}