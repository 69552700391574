import React from "react";
import * as UI from 'antd'
import { PageHeader } from '@ant-design/pro-layout'
import "./group.css"
import HttpPost from "../../../../utils/request";
import {Loading,HasLoaded} from "../../../../utils/loading";
import {DateFormat} from "../../../../utils/tools"
import apis from "../../../../utils/apis";

const {Search,TextArea} =UI.Input;
const { Option } = UI.Select;
export default class GrantList extends React.Component{
    constructor(props) {
        super(props);
        this.state={
            tableData: [],
            tablePage: {total: 0,currPage:1},
            tableLoad: false,
            group: [],
            grant: [],
            editGrantCurr: {},
            editGrantShow: false,
            grantFields: [],
            property: {}
        }
    }
    componentDidMount() {
        this.GetGrantGroups();
        this.GetGrants();
        this.getTablePage(1);
    }

    /**
     * 可用授权分组
     * @constructor
     */
    GetGrantGroups=()=>{
        let that = this;
        let para = {};
        para.pageNumber = 1;
        para.pageSize = 20000;
        HttpPost('grant/grantgrouplist',para).then(function (resp) {
            that.setState({
                group: resp.data.data.data,
            })
        })
    }
    /**
     * 可用授权通道
     * @constructor
     */
    GetGrants=()=>{
        let that = this;
        HttpPost("grant/getgrants").then(function (resp) {
            that.setState({
                grant:resp.data.data
            })
        })
    }
    /**
     * 提交授权信息
     * @param values
     * @constructor
     */
    Handle=(values)=>{
        var that = this;
        if (Object.keys(that.state.editGrantCurr).length > 0) {
            values.id = that.state.editGrantCurr.id;
        }
        var para = {property: {}};
        Object.keys(values).map(function (ks) {
            if (ks.indexOf('property')!==-1){
                para.property[ks.replace("property",'').replace('[','').replace(']','')]=values[ks]
            } else {
                para[ks]=values[ks]
            }
        })
        Loading();
        HttpPost('grant/addgrants',para).then(function (resp) {
            HasLoaded();
            if(resp.data.code===200){
                if(resp.data.data.code===200){
                    UI.message.success("授权信息维护完成")
                    that.setState({
                        editGrantShow: false,
                        editGrantCurr: {}
                    })
                    that.getTablePage(1)
                    if (resp.data.data.url) {//oauth2.0授权页面跳转
                        setTimeout(() => {
                            window.open(resp.data.data.url)
                        }, 1000)
                    }

                } else {
                    UI.message.warning(resp.data.data.msg)
                }
            }else {
                UI.message.error(resp.data.msg)
            }
        })
    }
    /**
     * 加载搜索字段
     * @param gid
     */
    GetGrantFields=(gid)=>{
        var that = this;
        var para = {};
        para.Grants = gid;
        Loading()
        HttpPost("grant/getgrantfields",para).then(function (resp) {
            HasLoaded();
            that.setState({
                grantFields: resp.data.data
            })
        })
    }

    /**
     * 搜索
     * @param keywords
     */
    MakeSearch=(keywords)=>{
        var that = this;
        this.setState({
            searchKey: Object.assign(that.state.searchKey,{name__icontains: keywords})
        })
        that.getTablePage(1)
    }

    /**
     * 加载表单数据
     * @param page
     * @param pageSize
     */
    getTablePage=(page,pageSize=20)=>{
        let that = this;
        let para = {};
        para.pageNumber = page;
        para.pageSize = pageSize;
        Object.assign(para, that.state.searchKey)
        that.setState({
            tableLoad: true
        })
        HttpPost('grant/getauths',para).then(function (resp) {
            that.setState({
                tableLoad: false
            })
            var curr = {
                total: resp.data.data.total,
                currPage: page
            }
            that.setState({
                tableData: resp.data.data.data,
                tablePage: curr
            })
        })
    }
    getStringJson = (targetString) => {
        var target = []
        try {
            var originData = JSON.parse(targetString)
            Object.entries(originData).forEach(function (key, value) {
                target.push({
                    key: key,
                    value: value
                })
            })
        } catch (e) {
            console.log(e)
        }
        return target
    }

    render() {
        let that = this;
        let tableConfig = [
            {
                title:'ID',
                dataIndex: 'id',
                key: 'id'
            },
            {
                title: '授权名称',
                dataIndex: 'name',
                key: 'name'
            },
            {
                title: "授权渠道",
                dataIndex: 'grant.name',
                key: 'grant.name',
                render: function (v, row, index) {
                    return row.grant.name
                }
            },
            {
                title: "渠道分组",
                dataIndex: 'group.name',
                key: 'group.name',
                render: function (v, row, index) {
                    return row.group.name
                }
            },
            {
              title: "系统分类",
              dataIndex: 'grant.systemGroup.name',
              key: "grant.systemGroup.name",
              render: function (v, row, index) {
                  return  row.grant.systemGroup ? row.grant.systemGroup.name : "无"
              }
            },
            {
                title: "创建时间",
                dataIndex: 'createAt',
                key: 'createAt',
                render: (v)=>{
                    return (
                        <span>{DateFormat(v)}</span>
                    )
                }
            },
            {
                title: '授权管理',
                dataIndex: 'opt',
                key: 'opt',
                align:'right',
                fixed:'right',
                with:115,
                render: (vl,row,index)=>{
                    row=JSON.parse(JSON.stringify(row));
                    return (
                        <div className="opt">
                            <UI.Button type="link" onClick={()=>{
                                if(row.property){
                                    Object.assign(row,JSON.parse(row.property))
                                    delete row.property
                                }
                                row.grant = row.grant.id
                                row.group =row.group.id
                                that.GetGrantFields(row.grant)
                                that.setState({
                                    editGrantCurr:row,
                                    editGrantShow: true
                                })
                            }}>编辑</UI.Button>
                            {row.grant.oauth &&
                                <UI.Button type="link" onClick={()=>{
                                    Loading()
                                    apis.makeOauth(row.id).then(resp => {
                                        if (resp.data.code === 200 && resp.data.data.url) {
                                            window.open(resp.data.data.url)
                                        } else {
                                            UI.message.error("发起Oauth2.0重新授权失败")
                                        }
                                    })
                                }}>重新授权</UI.Button>
                            }
                        </div>
                    )
                }
            }
        ]
        return (
            <div id="grantApp">
                <PageHeader
                    title="授权集中管理"
                    subTitle="授权集中化分组管理，可通过管理中心统一授权，也可通过API管理授权以方便多系统管理"
                    extra={[
                        <UI.Button key="1" type="primary" shape={"round"} onClick={()=>{
                            that.setState({
                                editGrantShow: true,
                                editGrantCurr: {},
                                grantFields: []
                            })
                        }}>新增授权</UI.Button>
                    ]}/>
                <div id="tablecontains">
                    <div id="toolbar">
                        <Search placeholder="请输入授权名称关键词..." enterButton="分组搜索" size="large" onSearch={this.MakeSearch}/>
                    </div>
                </div>
                <div style={{marginTop:'22px'}}>
                    <UI.Table
                        rowKey="id"
                        dataSource={this.state.tableData}
                        columns={tableConfig}
                        loading={this.state.tableLoad}
                        pagination={{total:this.state.tablePage.total,defaultPageSize:20,defaultCurrent:this.state.tablePage.currPage,onChange:this.getTablePage}}/>
                </div>
                <UI.Modal title="授权管理" footer={null} visible={that.state.editGrantShow} onCancel={()=>{
                    that.setState({
                        editGrantShow:false
                    })
                }} destroyOnClose>
                    <UI.Form onFinish={this.Handle} labelCol={{span:24}} labelAlign={"left"}>
                        <UI.Form.Item label="授权名称" name="name" rules={[{required:true,message:"请输入授权名称"}]} initialValue={that.state.editGrantCurr.name}>
                            <UI.Input placeholder="请输入授权名称..."/>
                        </UI.Form.Item>
                        <UI.Form.Item label="授权通道" name="grant" rules={[{required:true,message:"请选择授权渠道"}]}  initialValue={that.state.editGrantCurr.grant}>
                            <UI.Select placeholder="请选择授权通道..." showSearch={true} onChange={this.GetGrantFields} disabled={that.state.editGrantCurr.id}>
                                {this.state.grant.map((item,index)=>{
                                    return (
                                        <Option key={index} value={item.id}>{item.name}</Option>
                                    )
                                })}
                            </UI.Select>
                        </UI.Form.Item>
                        {that.state.grantFields.map((fields,index)=>{
                            if (fields.formType === "select") {
                                return (
                                    <UI.Form.Item key={index}
                                                  label={fields.name}
                                                  name={"property["+fields.field+"]"}
                                                  help={fields.help ? fields.help : ""}
                                                  rules={[{required:fields.required,message:fields.placeHolder}]}
                                                  initialValue={this.state.editGrantCurr[fields.field]}>
                                        <UI.Select placeholder={fields.placeHolder}>
                                            {JSON.parse(fields.fixValue).map((vls,index)=>{
                                                return (
                                                  <Option key={index} value={vls.value}>{vls.key}</Option>
                                                )
                                            })}
                                        </UI.Select>
                                    </UI.Form.Item>
                                )
                            }else if (fields.formType === "radio"){
                                return (
                                  <UI.Form.Item key={index}
                                                label={fields.name}
                                                name={"property["+fields.field+"]"}
                                                help={fields.help ? fields.help : ""}
                                                rules={[{required:fields.required,message:fields.placeHolder}]}
                                                initialValue={this.state.editGrantCurr[fields.field]}>
                                      <UI.Radio.Group>
                                          {JSON.parse(fields.fixValue).map((vls,index)=>{
                                              return (
                                                <UI.Radio key={index} value={vls.value}>{vls.key}</UI.Radio>
                                              )
                                          })}
                                      </UI.Radio.Group>
                                  </UI.Form.Item>
                                )
                            }else if (fields.formType === "boolean"){
                                return (
                                  <UI.Form.Item key={index}
                                                label={fields.name}
                                                name={"property["+fields.field+"]"}
                                                help={fields.help ? fields.help : ""}
                                                rules={[{required:fields.required,message:fields.placeHolder}]}
                                                initialValue={this.state.editGrantCurr[fields.field]}>
                                      <UI.Radio.Group>
                                          <UI.Radio value={"true"}>是</UI.Radio>
                                          <UI.Radio value={"false"}>否</UI.Radio>
                                      </UI.Radio.Group>
                                  </UI.Form.Item>
                                )
                            } else if (fields.formType === "textarea") {
                                return (
                                    <UI.Form.Item key={index}
                                                  label={fields.name}
                                                  name={"property["+fields.field+"]"}
                                                  help={fields.help ? fields.help : ""}
                                                  rules={[{required:fields.required,message:fields.placeHolder}]}
                                                  initialValue={this.state.editGrantCurr[fields.field]}>
                                        <TextArea rows={fields.rows} placeholder={fields.placeHolder}/>
                                    </UI.Form.Item>
                                )
                            }else {
                                return (
                                    <UI.Form.Item key={index}
                                                  label={fields.name}
                                                  name={"property["+fields.field+"]"}
                                                  help={fields.help ? fields.help : ""}
                                                  rules={[{required:fields.required,message:fields.placeHolder}]}
                                                  initialValue={this.state.editGrantCurr[fields.field]}>
                                        <UI.Input placeholder={fields.placeHolder}/>
                                    </UI.Form.Item>
                                )
                            }
                        })}
                        <UI.Form.Item label="授权分组" name="group" rules={[{required:true,message:"请选择授权组"}]}  initialValue={that.state.editGrantCurr.group}>
                            <UI.Select placeholder="请选择授权分组..." showSearch={true}>
                                {this.state.group.map((item,index)=>{
                                    return (
                                        <Option key={index} value={item.id}>{item.name}</Option>
                                    )
                                })}
                            </UI.Select>
                        </UI.Form.Item>
                        <UI.Form.Item style={{textAlign:'right'}}>
                            <UI.Button type="primary" shape="round" htmlType="submit">提交授权信息</UI.Button>
                        </UI.Form.Item>
                    </UI.Form>
                </UI.Modal>
            </div>
        )
    }
}
