import React from "react";
import * as UI from "antd";
import { PageHeader } from '@ant-design/pro-layout';
import HttpPost from "../../../utils/request";
import * as load from "../../../utils/loading"
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import * as fa from '@fortawesome/fontawesome-free-solid'

const {Search,TextArea}=UI.Input;
const { Meta } = UI.Card;


export default class Marketplace extends React.Component{
    constructor() {
        super();
        this.state={
            tableData: [],
            tablePage: {total: 0,currPage:1},
            tableLoad: false,
            searchKey: {},
            editShow: false,
            editCurr: {},
            taxonomy: [],
            searchCategory: ''
        }
    }
    componentDidMount() {
        this.GetTaxonomy()
        this.GetTable()
    }

    GetTaxonomy(){
        var that = this
        HttpPost("marketplace/marketplacetaxonomy").then(function (resp) {
            that.setState({
                taxonomy: resp.data.data
            })
        })
    }

    /**
     * 分页查询
     * @param pageNumber
     * @param pageSize
     * @constructor
     */
    GetTable=(pageNumber=1,pageSize=16)=>{
        let that = this;
        let para = {};
        para.pageNumber = pageNumber;
        para.pageSize = pageSize;
        Object.assign(para, that.state.searchKey)
        that.setState({
            tableLoad: true
        })
        HttpPost('marketplace/servicelist',para).then(function (resp) {
            that.setState({
                tableLoad: false
            })
            let curr = {
                total: resp.data.data.total,
                currPage: pageNumber
            }
            that.setState({
                tableData: resp.data.data.data,
                tablePage: curr
            })
        })
    }
    render() {
        let that = this
        return(
            <div>
                <PageHeader title="应用市场" subTitle="用户可根据自己实际需要激活相应的解决方案"/>
                <div style={{backgroundColor:"#ffffff",padding:"20px"}}>
                    <UI.Radio.Group onChange={(gid)=>{
                        that.setState({
                            searchCategory: gid
                        })
                    }}>
                        <UI.Radio value="">全部分类</UI.Radio>
                        {this.state.taxonomy.map(curr=>{
                            return (
                                <UI.Radio key={curr.id} value={curr.id}>{curr.name}</UI.Radio>
                            )
                        })}
                    </UI.Radio.Group>
                </div>
                <UI.Row gutter={16} style={{marginTop:"26px"}}>
                    {that.state.tableData.map(service=>{
                        return (
                            <UI.Col key={service.id} span={4}>
                                <UI.Card bordered={false} hoverable title={false}>
                                    <Meta title={service.name} description={service.explain}/>
                                    <div style={{marginTop:"2.2rem",textAlign:"right"}}>
                                        <UI.Button.Group>
                                            <a href={`/purchase/item/${service.code}`}>
                                                <UI.Button type={'danger'} size={"small"} shape={"circle"}>
                                                    采
                                                </UI.Button>
                                            </a>
                                        </UI.Button.Group>
                                    </div>
                                </UI.Card>
                            </UI.Col>
                        )
                    })}
                </UI.Row>
                <div style={{marginTop:"2.2rem"}}>
                    <UI.Pagination
                        defaultCurrent={that.state.tablePage.currPage}
                        onChange={that.GetTable}
                        total={that.state.tablePage.total} />
                </div>
            </div>
        )
    }
}
