import React,{Component} from "react";
import * as UI from 'antd'
import { PageHeader } from '@ant-design/pro-layout'
import HttpPost from "../../../../../utils/request";
import * as load from "../../../../../utils/loading";

const {TextArea} =UI.Input
const {Option} = UI.Select

export default class SendTemplate extends Component{
    constructor() {
        super();
        this.state={
            tableData: [],
            tablePage: {total: 0,currPage:1},
            tableLoad: false,
            searchKey: {},
            editShow: false,
            editCurr: {},
            graphs: [],
            imgkey: '',
            upLoading: false
        }
    }
    componentDidMount() {
        this.getGraphs()
        this.GetTable(1)
    }

    /**
     * 获得可用渠道
     */
    getGraphs = () =>{
        var that = this
        var para = {}
        para.pageNumber = 1
        para.pageSize = 10000
        HttpPost("merchant/smsapplist",para).then(resp=>{
            that.setState({
                graphs: resp.data.data.data
            })
        })
    }
    /**
     * 数据提交
     * @param values
     */
    handle=(values)=>{
        let that = this;
        if (Object.keys(that.state.editCurr).length > 0) {
            values.id = that.state.editCurr.id;
        }
        load.Loading();
        HttpPost("merchant/smstemplateadmin",values).then(resp=>{
            load.HasLoaded();
            if (resp.data.code===200){
                if(resp.data.data.code===200){
                    UI.message.success("短信模板信息提交成功")
                    this.setState({
                        editShow: false,
                        editCurr: {}
                    })
                    this.GetTable(1)
                } else {
                    UI.message.warning(resp.data.data.msg)
                }
            }else {
                UI.message.error(resp.data.msg)
            }
        })
    }
    /**
     * 发起搜索
     * @param params
     * @constructor
     */
    MakeSearch=(params)=>{
        console.log(params)
        this.setState({
            searchKey: params
        })
        var that = this
        setTimeout(function () {
            that.GetTable()
        },200)
    }
    /**
     * 签名列表
     * @param pageNumber
     Object.assign(para, that.state.keywords)
     * @param pageSize
     * @constructor
     */
    GetTable=(pageNumber=1,pageSize=20)=>{
        let that = this;
        let para = {};
        para.pageNumber = pageNumber;
        para.pageSize = pageSize;
        that.setState({
            tableLoad: true
        })
        Object.assign(para,that.state.searchKey)
        HttpPost('merchant/smstemplatelist',para).then(function (resp) {
            that.setState({
                tableLoad: false
            })
            var curr = {
                total: resp.data.data.total,
                currPage: pageNumber
            }
            that.setState({
                tableData: resp.data.data.data,
                tablePage: curr
            })
        })
    }
    render() {
        var that = this
        var columns = [
            {
                title: "id",
                dataIndex: "id"
            },
            {
                title: "模板名称",
                dataIndex: "name"
            },
            {
                title: "短信应用",
                key: 'id',
                render: function (value, row, index) {
                    return row.sms_apps.name
                }
            },
            {
                title: "短信模板",
                key: 'id',
                render: function (value, row, index) {
                    return row.template
                }
            },
            {
                title: "短信类型",
                key: 'id',
                render: function (value, row, index) {
                    return row.sms_type ? '营销短信' : '普通短信'
                }
            },
            {
                title: "平台",
                key: 'id',
                render: function (value, row, index) {
                    return row.sms_apps.graph.name
                }
            },
            {
                title: "已推送",
                key: 'id',
                render: function (value, row, index) {
                    return (
                        <UI.Button danger shape={"circle"}>
                            {row.synced ? '是' : '否'}
                        </UI.Button>
                    )
                }
            },
            {
                title: "审核通过",
                key: 'id',
                render: function (value, row, index) {
                    return (
                        <UI.Button type={"primary"} shape={"circle"}>
                            {row.state ? '是' : '否'}
                        </UI.Button>
                    )
                }
            },
            {
                title: "平台",
                key: 'id',
                align: "right",
                fixed: "right",
                render: function (value, row, index) {
                    return (
                        <UI.Button.Group>
                            <UI.Button onClick={()=>{
                                var data = JSON.parse(JSON.stringify(row))
                                delete data.metadata
                                data.sms_apps = data.sms_apps.id
                                delete data.create_at
                                delete data.update_at
                                that.setState({
                                    editCurr: data,
                                    editShow: true
                                })
                            }} type={"link"}>模板编辑</UI.Button>
                            <UI.Button type={"link"} onClick={()=>{
                                load.Loading();
                                HttpPost("merchant/smstemplatestate",{id: row.id}).then(resp=>{
                                    load.HasLoaded();
                                    if (resp.data.code===200){
                                        if(resp.data.data.code===200){
                                            UI.message.success("模板已经通过审核")
                                            that.GetTable(1)
                                        } else {
                                            UI.message.error(resp.data.data.msg)
                                        }
                                    }else {
                                        UI.message.error(resp.data.msg)
                                    }
                                })
                            }}>状态验证</UI.Button>
                        </UI.Button.Group>
                    )
                }
            }
        ]
        return (
            <div>
                <PageHeader title="短信模板" subTitle="短信模板统一管理" extra={[
                    <UI.Button  key="1" type="primary" shape="round" onClick={()=>{
                        that.setState({
                            editCurr: {},
                            editShow: true
                        })
                    }}>新增短信模板</UI.Button>
                ]}/>
                <div style={{marginTop:'22px'}} id="hot-top-toolbar">
                    <UI.Form onFinish={that.MakeSearch} layout={"inline"}>
                        <UI.Form.Item label={"模板名称"} name={"name__contains"}>
                            <UI.Input placeholder={"请输入模板名称"}/>
                        </UI.Form.Item>
                        <UI.Form.Item label={"短信应用"} name={"Sms_Apps__Id"}>
                            <UI.Select placeholder={"请选择短信应用"}>
                                {that.state.graphs.map(gp=>{
                                    return (
                                        <Option key={gp.id} value={gp.id}>{gp.name}</Option>
                                    )
                                })}
                            </UI.Select>
                        </UI.Form.Item>
                        <UI.Form.Item>
                            <UI.Button type={"primary"} htmlType={"submit"}>模板搜索</UI.Button>
                        </UI.Form.Item>
                    </UI.Form>
                </div>
                <div style={{marginTop:'22px'}}>
                    <UI.Table
                        rowKey="id"
                        dataSource={this.state.tableData}
                        columns={columns}
                        loading={this.state.tableLoad}
                        pagination={{total:this.state.tablePage.total,defaultPageSize:20,defaultCurrent:this.state.tablePage.currPage,onChange:this.getTablePage}}/>
                </div>
                <UI.Modal visible={that.state.editShow} title={'短信模板管理'} onCancel={()=>{
                    that.setState({
                        editCurr: {},
                        editShow: false
                    })
                }} footer={null} destroyOnClose>
                    <UI.Form onFinish={that.handle} labelCol={{span:24}} labelAlign={"left"}>
                        <UI.Form.Item label={"模板名称"} name={"name"} rules={[{required:true,message:'请输入签名名称'}]} initialValue={this.state.editCurr.name}>
                            <UI.Input placeholder={"请输入短信模板名称"}/>
                        </UI.Form.Item>
                        <UI.Form.Item label={"短信应用"} name={"sms_apps"} rules={[{required:true,message:'请选择短信应用'}]} initialValue={this.state.editCurr.sms_apps}>
                            <UI.Select placeholder={"请选择短信应用"} defaultValue={that.state.editCurr.sms_app}>
                                {that.state.graphs.map(gp=>{
                                    return (
                                        <Option key={gp.id} value={gp.id}>{gp.name}</Option>
                                    )
                                })}
                            </UI.Select>
                        </UI.Form.Item>
                        <UI.Form.Item label={"短信类型"} name={"sms_type"} rules={[{required:true,message:'请选择短信类型'}]} initialValue={this.state.editCurr.sms_type}>
                            <UI.Radio.Group value={this.state.editCurr.sms_typ}>
                                <UI.Radio value={0}>普通短信</UI.Radio>
                                <UI.Radio value={1}>营销短信</UI.Radio>
                                <UI.Radio value={3}>验证码（阿里专用）</UI.Radio>
                                <UI.Radio value={4}>国际短信(阿里专用)</UI.Radio>
                            </UI.Radio.Group>
                        </UI.Form.Item>
                        <UI.Form.Item label={"短信模板"} help={"示例:{1}为您的登陆验证码，请于{2}分钟内填写，如非本人操作，请忽略本短信."} name={"template"} rules={[{required:true,message:'请输入短信模板'}]} initialValue={this.state.editCurr.template}>
                            <TextArea rows={8} placeholder={"请输入短信模板"}/>
                        </UI.Form.Item>
                        <UI.Form.Item label={"模板备注"} name={"remark"} rules={[{required:true,message:'请输入模板备注'}]} initialValue={this.state.editCurr.remark}>
                            <TextArea rows={3} placeholder={"请输入模板备注"}/>
                        </UI.Form.Item>
                        <UI.Form.Item>
                            <UI.Button htmlType={"submit"} type={"primary"} block>提交短信模板信息</UI.Button>
                        </UI.Form.Item>
                    </UI.Form>
                </UI.Modal>
            </div>
        );
    }
}