import React,{Component,useState, useEffect} from "react";
import HttpPost, {UploadProps} from "../../../utils/request";
import * as UI from 'antd'
import { PageHeader } from '@ant-design/pro-layout'
import { Input } from 'antd'
import apis from "../../../utils/apis";
import * as echarts from 'echarts';



const {Option} = UI.Select
const {TextArea} = UI.Input
export default class WorkflowManage extends Component{
    constructor() {
        super();
        this.state={
            tableData: [],
            tablePage: {total: 0,currPage:1},
            tableLoad: false,
            searchKey: {},
            editShow: false,
            editCurr: {},
            hanldeLoading: false,
            designCurr: {},
            designShow: false,
            designHandleing: false,
            flowCurr: {},
            flowShow: false,
            flowHandleLoading: false,
            nodes: [],
            forms: [],
            linesPagination: {total: 0,currPage:1},
            workflowData: [],
            lineLoading: false,
            lineSearchKeys: {}
        }
    }
    componentDidMount() {
        this.GetTable(1)
        this.getWorkflowForms()
        this.getWorkflowNodes()
    }

    /**
     * 加载工作流表单
     */
    getWorkflowForms  () {
        var that = this
        let para = {};
        para.pageNumber = 1;
        para.pageSize = 20000;
        HttpPost('flow/formslist',para).then(function (resp) {
            that.setState({
                forms: resp.data.data.data ? resp.data.data.data : []
            })
        })
    }

    /**
     * 加载工作流节点
     */
    getWorkflowNodes () {
        var that = this
        let para = {};
        para.pageNumber = 1;
        para.pageSize = 20000;
        HttpPost('flow/nodeslist',para).then(function (resp) {
            that.setState({
                nodes: resp.data.data.data
            })
        })
    }

    /**
     * 工作流图表显示
     * @param {*} data 
     */
    makeWorkflowShow = (data) => {
        var that = this
        var dom = document.getElementById("workflow_echart_show")
        var chart = echarts.init(dom)
        var data = []
        var links = []
        var options = {}
        options.title = {text: that.state.designCurr.id ? `${that.state.designCurr.name}路径图` : '工作流路径图'}
        options.tooltip = {}
        options.animationDurationUpdate = 1500
        options.animationEasingUpdate = 'quinticInOut'
        options.lineStyle = {opacity: 0.9, width:2, curveness: 0}
        var series = {}
        series.type = 'graph'
        series.layout = 'none'
        series.symbolSize = 50
        series.roam = true
        series.label = {show: true}
        series.edgeSymbol = ['circle', 'arrow']
        series.edgeSymbolSize = [4, 10]
        series.edgeLabel = {fontSize: 20}

        var tempData = {}
        var tempMapping = {}
        that.state.workflowData.forEach(ele => {
            var source = ele.source
            var target = ele.target
            var tempkeys = Object.keys(tempData)
            var exDatx = tempkeys.includes(source.name) ? tempData[source.name] : []
            exDatx.push(target)

            if (!tempkeys.includes(target.name)) {
                tempData[target.name] = []
            }
            tempData[source.name] = exDatx
            if (!Object.keys(tempMapping).includes(source.name)) {
                tempMapping[source.name] = source
            }
            if (!Object.keys(tempMapping).includes(target.name)) {
                tempMapping[target.name] = target
            }
            var currLine = {}
            currLine.source = source.name
            currLine.target = target.name
            currLine.label = {show: true}
            currLine.lineStyle = {curveness: 0.2}
            links.push(currLine)
        })
        var initX = 100
        Object.keys(tempData).forEach(key => {
            var curr = {}
            curr.name = tempMapping[key].name
            curr.x = initX
            initX += 100
            curr.y = 100
            data.push(curr)
        })
        console.log(tempData)
        series.links = links
        series.data = data
        options.series = series
        chart.setOption(options)
    }

    /**
     * 发起搜索
     * @param params
     * @constructor
     */
    MakeSearch=(params)=>{
        console.log(params)
        this.setState({
            searchKey: params
        })
        var that = this
        setTimeout(function () {
            that.GetTable()
        },200)
    }
    /**
     * 签名列表
     * @param pageNumber
     * @param pageSize
     * @constructor
     */
    GetTable=(pageNumber=1,pageSize=20)=>{
        let that = this;
        let para = {};
        para.pageNumber = pageNumber;
        para.pageSize = pageSize;
        that.setState({
            tableLoad: true
        })
        Object.assign(para,that.state.lineSearchKeys)
        HttpPost('flow/workflowlist',para).then(function (resp) {
            that.setState({
                tableLoad: false
            })
            var curr = {
                total: resp.data.data.total,
                currPage: pageNumber
            }
            that.setState({
                tableData: resp.data.data.data,
                tablePage: curr
            })
        })
    }

    /**
     * 
     */
    getWorkflowTransitionLines (workflowId=null,pageNumber=1,pageSize=2000) {
        let that = this;
        let para = {};
        para.pageNumber = pageNumber;
        para.pageSize = pageSize;
        that.setState({
            lineLoading: true
        })
        para.workflow__id = workflowId ? workflowId : that.state.designCurr.id
        HttpPost('flow/transitionlines',para).then(resp => {
            that.setState({
                lineLoading: false
            })
            var curr = {
                total: resp.data.data.total,
                currPage: pageNumber
            }
            that.setState({
                workflowData: resp.data.data.data,
                linesPagination: curr
            })
            that.makeWorkflowShow()
        })
    }
    render() {
        var that = this
        var columns = [
            {
                title: "ID",
                dataIndex: "id"
            },
            {
                title: "工作流",
                dataIndex: "name"
            },
            {
                title: "组织代码",
                key: "id",
                render: function (value, row, index) {
                    return row.org_id ? row.org_id : "无"
                }
            },
            {
                title: "部门代码",
                key: "id",
                render: function (value, row, index) {
                    return row.dept_id ? row.dept_id : "无"
                }
            },
            {
                title: "用户代码",
                key: "id",
                render: function (value, row, index) {
                    return row.guest_id ? row.guest_id : "无"
                }
            },
            {
                title: "应用场景",
                key: "id",
                render: function (value, row, index) {
                    return <div>
                        {row.explain}
                    </div>
                }
            },
            {
                title: "工作流管理",
                key: "id",
                align: "right",
                fixed: 'right',
                render: function (value, row, index) {
                    return (
                        <span>
                           <UI.Button onClick={()=>{
                               that.setState({
                                   editCurr: JSON.parse(JSON.stringify(row)),
                                   editShow: true
                               })
                           }} type={"link"}>工作流编辑</UI.Button>
                           <UI.Button type={"link"} onClick={() => {
                            that.setState({
                                designCurr: JSON.parse(JSON.stringify(row)),
                                designHandleing: false,
                                designShow: true,
                                workflowData: [],
                                linesPagination: {total: 0,currPage:1}
                            })
                            that.getWorkflowTransitionLines(row.id)
                           }}>流程设计</UI.Button>
                       </span>

                    )
                }
            }
        ]
        var flowColumns = [
            {
                title: "ID",
                dataIndex: "id"
            },
            {
                title: "起始节点",
                key: "id",
                render: function (vlaue, row, inde) {
                    return row.source.name
                }
            },
            {
                title: "目标节点",
                key: "id",
                render: function (vlaue, row, inde) {
                    return row.target.name
                }
            },
            {
                title: "流转表单",
                key: "id",
                render: function (vlaue, row, inde) {
                    return row.form.name
                }
            },
            {
                title: "可用",
                key: "id",
                render: function (vlaue, row, inde) {
                    return <UI.Button shape="circle">{row.state ? "是" : "否"}</UI.Button>
                }
            },
            {
                title: "流转表单",
                key: "id",
                render: function (vlaue, row, inde) {
                    return (
                        <span>
                            <UI.Button type={"link"} onClick={() => {
                            that.setState({
                                flowCurr: JSON.parse(JSON.stringify(row)),
                                flowHandleLoading: false,
                                flowShow: true
                            })
                            console.log(row)
                           }}>编辑</UI.Button>
                        </span>
                    )
                }
            }
        ]
        return (
            <div>
                <PageHeader title="工作流管理" subTitle="工作流配置与管理" extra={[
                    <UI.Button  key="1" type="primary" shape="round" onClick={()=>{
                        that.setState({
                            editCurr: {},
                            editShow: true,
                            hanldeLoading: false
                        })
                    }}>新增工作流</UI.Button>
                ]}/>
                <div style={{marginTop:'22px'}} id="hot-top-toolbar">
                    <UI.Form onFinish={that.MakeSearch} layout={"inline"}>
                        <UI.Form.Item label={"工作流名称"} name={"name__contains"}>
                            <UI.Input placeholder={"请输入工作流名称"}/>
                        </UI.Form.Item>
                        <UI.Form.Item>
                            <UI.Button type={"primary"} htmlType={"submit"}>工作流搜索</UI.Button>
                        </UI.Form.Item>
                    </UI.Form>
                </div>
                <div style={{marginTop:'22px'}}>
                    <UI.Table
                        rowKey="id"
                        dataSource={this.state.tableData}
                        columns={columns}
                        loading={this.state.tableLoad}
                        pagination={{total:this.state.tablePage.total,defaultPageSize:20,defaultCurrent:this.state.tablePage.currPage,onChange:this.getTablePage}}/>
                </div>
                <UI.Modal visible={that.state.editShow} title={'工作流'} onCancel={()=>{
                    that.setState({
                        editCurr: {},
                        editShow: false,
                        hanldeLoading: false
                    })
                }} footer={null} destroyOnClose>
                    <UI.Form onFinish={(params => {
                        that.setState({
                            hanldeLoading: true
                        })
                        if (that.state.editCurr.id) {
                            params.id = that.state.editCurr.id
                        }
                        apis.WorkflowManage(params).then(resp => {
                            that.setState({
                                hanldeLoading: false
                            })
                            if (resp.data.code === 200 && resp.data.data.code === 200) {
                                UI.message.success("提交工作流信息成功")
                                that.GetTable(that.state.editCurr.id ? that.state.tablePage.currPage : 1)
                                that.setState({
                                    hanldeLoading: false,
                                    editShow: false,
                                    editCurr: {}
                                })
                            } else {
                                UI.message.error(resp.data.msg)
                            }
                        })
                    })} labelCol={{span:24}} labelAlign={"left"}>
                        <UI.Form.Item label={"工作流名称"} name={"name"} rules={[{required:true,message:'请输入工作流名称名称'}]} initialValue={this.state.editCurr.name}>
                            <UI.Input placeholder={"请输入工作流名称"}/>
                        </UI.Form.Item>
                        <UI.Form.Item label={"起始节点ID"} name={"start_with"} help="起始节点ID" rules={[{required:true,message:'起始节点ID'}]} initialValue={this.state.editCurr.start_with}>
                            <UI.Input placeholder={"起始节点ID"}/>
                        </UI.Form.Item>
                        <UI.Form.Item label={"组织唯一代码"} name={"org_id"} help="用于类似集团公司类型子公司间识别，如ID，可为空" rules={[{required:false,message:'请输入组织唯一代码'}]} initialValue={this.state.editCurr.org_id}>
                            <UI.Input placeholder={"请输入组织唯一代码"}/>
                        </UI.Form.Item>
                        <UI.Form.Item label={"部门唯一代码"} name={"dept_id"} help="用于公司内部门间识别，如ID，可为空" rules={[{required:false,message:'请输入部门唯一代码'}]} initialValue={this.state.editCurr.dept_id}>
                            <UI.Input placeholder={"请输入部门唯一代码"}/>
                        </UI.Form.Item>
                        <UI.Form.Item label={"员工唯一代码"} name={"guest_id"} help="用于员工唯一性识别，如ID，可为空" rules={[{required:false,message:'请输入员工唯一代码'}]} initialValue={this.state.editCurr.guest_id}>
                            <UI.Input placeholder={"请输入员工唯一代码"}/>
                        </UI.Form.Item>
                        <UI.Form.Item label={"工作流介绍"} name={"explain"} help="用途，应用场景说明" rules={[{required:true,message:'请输入工作流工作流介绍'}]} initialValue={this.state.editCurr.explain}>
                            <TextArea rows={8} placeholder={"请输入工作流工作流介绍"}/>
                        </UI.Form.Item>
                        <UI.Form.Item>
                            <UI.Button htmlType={"submit"} loading={that.state.hanldeLoading} type={"primary"} block>提交工作流信息</UI.Button>
                        </UI.Form.Item>
                    </UI.Form>
                </UI.Modal>
                <UI.Modal width={"70vw"} bodyStyle={{padding:"22px"}}  visible={that.state.designShow} onCancel={()=>{
                    that.setState({
                        designCurr: {},
                        designShow: false,
                        designHandleing: false
                    })
                }} footer={null} destroyOnClose>
                    <h1>工作流程设计器</h1>
                    <UI.Divider/>
                    <UI.Row gutter={[16, 22]}>
                        <UI.Col span={10}>
                            <div id="toolbox" style={{width:"100%",textAlign:"right"}}>
                                <UI.Button shape="round" type="primary" size="small" onClick={() => {
                                    that.setState({
                                        flowCurr: {},
                                        flowShow: true,
                                        flowHandleLoading: false
                                    })
                                }}>新增工作流程</UI.Button>
                            </div>
                            <UI.Divider/>
                            <div style={{marginTop:'22px'}}>
                                <UI.Table
                                    rowKey="id"
                                    dataSource={this.state.workflowData}
                                    columns={flowColumns}
                                    loading={this.state.lineLoading}
                                    pagination={null}/>
                            </div>
                        </UI.Col>
                        <UI.Col span={14}>
                            <div id="workflow_echart_show" style={{height:"600px"}}></div>
                        </UI.Col>
                    </UI.Row>
                </UI.Modal>
                <UI.Modal visible={that.state.flowShow} mask={true} maskStyle={{backgroundColor:"#666666"}} onCancel={()=>{
                    that.setState({
                        flowCurr: {},
                        flowShow: false,
                        flowHandleLoading: false
                    })
                }} footer={null} destroyOnClose>
                    <h1>工作流管理</h1>
                    <UI.Divider/>
                    <UI.Form labelAlign="left" labelCol={{span:24}} onFinish= {(worflow) => {
                        console.log(worflow)
                        that.setState({
                            flowHandleLoading: true
                        })
                        worflow.workflow = that.state.designCurr.id
                        apis.workflowTransitionLineHandle(worflow).then(resp => {
                            that.setState({
                                flowHandleLoading: false
                            })
                            if (resp.data.code === 200) {
                                UI.message.success("工作流线路设计信息提交成功")
                                that.setState({
                                    flowCurr: {},
                                    flowShow: false,
                                    flowHandleLoading: false
                                })
                                Object.keys(that.state.flowCurr) > 0 ? that.getWorkflowTransitionLines(that.state.linesPagination.currPage) : that.getWorkflowTransitionLines()
                            } else {
                                UI.message.error(resp.data.data.msg ? resp.data.data.msg : resp.data.msg)
                            }
                        })
                    }}>
                        <UI.Form.Item name={"state"} label="流程可用" rules={[{required:true,message:'流程是否可用'}]} initialValue={this.state.flowCurr.state ? this.state.flowCurr.state: true}>
                            <UI.Radio.Group>
                                <UI.Radio value={true}>可用</UI.Radio>
                                <UI.Radio value={false}>不可用</UI.Radio>
                            </UI.Radio.Group>  
                        </UI.Form.Item>
                        <UI.Form.Item name={"source"} label="当前节点" rules={[{required:true,message:'请选择当前编辑节点'}]} initialValue={this.state.flowCurr.source ? this.state.flowCurr.source.id : null}>
                            <UI.Select placeholder="请选择当前编辑节点">
                                {that.state.nodes.map(node => {
                                    return (
                                        <Option value={node.id} key={node.id}>
                                            {node.name}
                                        </Option>
                                    )
                                })}
                            </UI.Select>
                        </UI.Form.Item>
                        <UI.Form.Item name={"target"} label="目标节点" rules={[{required:true,message:'请选择当前目标节点'}]} initialValue={this.state.flowCurr.target ? this.state.flowCurr.target.id : null}>
                            <UI.Select placeholder="请选择目标节点">
                                {that.state.nodes.map(node => {
                                    return (
                                        <Option value={node.id} key={node.id}>
                                            {node.name}
                                        </Option>
                                    )
                                })}
                            </UI.Select>
                        </UI.Form.Item>
                        <UI.Form.Item name={"form"} label="必要表单" rules={[{required:false,message:'请选择必要表单'}]} initialValue={this.state.flowCurr.form ? this.state.flowCurr.form.id : null}>
                            <UI.Select placeholder="请选择必要表单">
                                <Option>无须必要表单</Option>
                                {that.state.forms.map(form => {
                                    return (
                                        <Option value={form.id} key={form.id}>
                                            {form.name}
                                        </Option>
                                    )
                                })}
                            </UI.Select>
                        </UI.Form.Item>  
                        <UI.Form.Item style={{textAlign:"right"}}>
                            <UI.Button shape="round" htmlType="submit" loading={that.state.flowHandleLoading} type="primary">提交工作流流程信息</UI.Button>
                        </UI.Form.Item>
                    </UI.Form>
                </UI.Modal>
            </div>
        );
    }
}