import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import 'antd/dist/reset.css'
import { DevSupport } from '@react-buddy/ide-toolbox'
import ComponentPreviews from './dev/previews'
import { useInitial } from './dev'

React.sidewidth = '46px'
const rootElement = document.getElementById('root')
const root = ReactDOM.createRoot(rootElement)
root.render(<App />)
// root.render(
//   <DevSupport ComponentPreviews={ComponentPreviews}
//               useInitialHook={useInitial}
//   >
//     <App />
//   </DevSupport>,
//
// )

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
