/**
 * author: Alex Lai
 * Support Website: https://laialex.com
 * Email: alex@laialex.com
 * Create Time: 2024/1/9
 */
import React, {Component} from "react";
import * as UI from "antd";
import { PageHeader } from '@ant-design/pro-layout';
import apis from "../../../../../utils/apis";
import dayjs from "dayjs";
import {BellOutlined} from "@ant-design/icons";
import Button from 'antd/es/button'


const {TextArea} = UI.Input
const {Option} = UI.Select

export default class SesRecords extends Component {
    constructor() {
        super();
        this.state = {
            tableData: [],
            paging: {total: 0, pageNumber: 1, pageSize: 20},
            tableLoad: false,
            searchKey: {},
            editShow: false,
            editCurr: {},
            submitting: false
        }
    }

    componentDidMount() {
        this.tableDataSync(1)
    }

    /**
     * 发起搜索
     * @param params
     * @constructor
     */
    makeSearch = (params) => {
        this.setState({
            searchKey: params
        })
        var that = this
        setTimeout(function () {
            that.tableDataSync()
        }, 200)
    }
    /**
     * 签名列表
     * @param pageNumber
     Object.assign(para, that.state.keywords)
     * @param pageSize
     * @constructor
     */
    tableDataSync = (pageNumber = 1) => {
        let that = this;
        let para = {};
        para.pageNumber = pageNumber;
        para.pageSize = 20;
        that.setState({
            tableLoad: true
        })
        Object.assign(para, that.state.searchKey)
        apis.sesRecord(para).then(function (resp) {
            that.setState({
                tableLoad: false
            })
            var curr = {
                total: resp.data.data.total,
                pageNumber: pageNumber
            }
            that.setState({
                tableData: resp.data.data.data,
                paging: curr
            })
        })
    }


    render() {
        var that = this

        const columns = [
            {
                title: "ID",
                dataIndex: "id"
            },
            {
                title: "发件箱",
                key: "id",
                render: function (value, row, index) {
                    return `${row.account.email_address}@${row.account.domain.domain}`
                }
            },
            {
                title: "收件人",
                key: "id",
                render: function (value, row, index) {
                    return row.destination
                }
            },
            {
                title: "发件主题",
                key: "id",
                render: function (value, row, index) {
                    return (
                        <div>{row.subject}</div>
                    )
                }
            },
            {
                title: "发送成功",
                key: "id",
                render: function (value, row, index) {
                    return (
                        <UI.Button shape={"round"} type={"circle"} style={{color:"#fff",backgroundColor: row.has_sended ? "deepskyblue" : "tomato"}}>{row.has_sended ? '是' : '否'}</UI.Button>
                    )
                }
            },
            {
                title: "发件时间",
                key: "id",
                render: function (value, row, index) {
                    var dd = new Date(row.create_at)
                    return `${dd.toLocaleDateString()} ${dd.toLocaleTimeString()}`
                }
            },
            {
                title: "管理",
                key: "id",
                fixed: "right",
                render: function (value, row, index) {
                    return (
                        <span>
                             <UI.Button onClick={() => {
                                 var data = JSON.parse(JSON.stringify(row))
                                 that.setState({
                                     editCurr: data,
                                     editShow: true
                                 })
                             }} type={"link"}>查看邮件</UI.Button>
                        </span>
                    )
                }
            }
        ]

        return (
          <div>
              <PageHeader title="邮件记录" subTitle="邮件推送记录" />
              <UI.Card id="hot-top-toolbar">
                  <div style={{ marginTop: '22px' }} >
                      <UI.Form onFinish={that.makeSearch} layout={'inline'}>
                          <UI.Form.Item label={'收件人'} name={'destination'}>
                              <UI.Input placeholder={'收件人'} />
                          </UI.Form.Item>
                          <UI.Form.Item label={'邮件主题'} name={'subject__icontains'}>
                              <UI.Input placeholder={'邮件主题'} />
                          </UI.Form.Item>
                          <UI.Form.Item>
                              <UI.Button type={'primary'} htmlType={'submit'}>快速搜索</UI.Button>
                          </UI.Form.Item>
                      </UI.Form>
                  </div>
              </UI.Card>

              <div style={{ marginTop: '22px' }}>
                  <UI.Table
                    rowKey="id"
                    dataSource={this.state.tableData}
                    columns={columns}
                    loading={this.state.tableLoad}
                    pagination={{
                        total: this.state.paging.total,
                        defaultPageSize: 20,
                        defaultCurrent: this.state.paging.pageNumber,
                        onChange: this.tableDataSync
                    }} />
              </div>
              <UI.Drawer open={that.state.editShow} width={'60vw'} placement={'right'} footer={null} onClose={() => {
                  that.setState({
                      editCurr: {},
                      editShow: false
                  })
              }} destroyOnClose>
                 <h2 style={{fontSize: "2.2em"}}>{that.state.editCurr.subject}</h2>
                  <small>{dayjs(new Date(that.state.editCurr.create_at)).format('YYYY-MM-DD HH:mm:ss')}</small>
                  {that.state.editCurr.attachment && that.state.editCurr.attachment!=="" &&
                  <UI.Row gutter={"8"} style={{marginTop: "14px"}}>
                      {JSON.parse(that.state.editCurr.attachment).map(((attachment ,index)=>{
                          return <UI.Col key={index} span={24}>
                              <UI.Card styles={{ body: { padding: '0' } }}>
                                  <UI.Button type={'primary'} size={'large'} icon={<i class={"fa fa-link"}/>} href={attachment.url} target={"_blank"}>
                                      {attachment.name}
                                  </UI.Button>
                              </UI.Card>
                          </UI.Col>
                      }))}
                  </UI.Row>
                  }
                  <UI.Divider/>
                  {that.state.editCurr.content && that.state.editCurr.content.toString().startsWith('<') ?
                    <div dangerouslySetInnerHTML={{ __html: this.state.editCurr.content }}></div> :
                    <div>{that.state.editCurr.content}</div>
                  }

              </UI.Drawer>
          </div>
        )
    }
}