import React,{Component} from "react";
import HttpPost, {UploadProps} from "../../../../../utils/request";
import * as UI from 'antd'
import { PageHeader } from '@ant-design/pro-layout'

import * as load from "../../../../../utils/loading";

const {Option} = UI.Select
const {TextArea} = UI.Input
export default class SesAccounts extends Component{
    constructor() {
        super();
        this.state={
            tableData: [],
            tablePage: {total: 0,currPage:1},
            tableLoad: false,
            searchKey: {},
            editShow: false,
            editCurr: {},
            graphs: [],
            imgkey: '',
            upLoading: false,
            sendShow: false,
            sendCurr: {}
        }
    }
    componentDidMount() {
        this.getGraphs()
        this.GetTable(1)
    }

    /**
     * 获得可用渠道
     */
    getGraphs = () =>{
        var that = this
        var para = {}
        para.pageNumber = 1
        para.pageSize = 10000
        HttpPost("merchant/sesdomainlist",para).then(resp=>{
            that.setState({
                graphs: resp.data.data.data
            })
        })
    }
    /**
     * 数据提交
     * @param values
     */
    handle=(values)=>{
        let that = this;
        if (Object.keys(that.state.editCurr).length > 0) {
            values.id = that.state.editCurr.id;
        }
        load.Loading();
        HttpPost("merchant/sesaccountadmin",values).then(resp=>{
            load.HasLoaded();
            if (resp.data.code===200){
                if(resp.data.data.ok){
                    UI.message.success("邮箱地址申请成功")
                    this.setState({
                        editShow: false,
                        editCurr: {}
                    })
                    this.GetTable(1)
                } else {
                    UI.message.warning(resp.data.data.msg)
                }
            }else {
                UI.message.error(resp.data.msg)
            }
        })
    }
    /**
     * 发起搜索
     * @param params
     * @constructor
     */
    MakeSearch=(params)=>{
        console.log(params)
        this.setState({
            searchKey: params
        })
        var that = this
        setTimeout(function () {
            that.GetTable()
        },200)
    }
    /**
     * 签名列表
     * @param pageNumber
     Object.assign(para, that.state.keywords)
     * @param pageSize
     * @constructor
     */
    GetTable=(pageNumber=1,pageSize=20)=>{
        let that = this;
        let para = {};
        para.pageNumber = pageNumber;
        para.pageSize = pageSize;
        that.setState({
            tableLoad: true
        })
        Object.assign(para,that.state.searchKey)
        HttpPost('merchant/sesaccountlist',para).then(function (resp) {
            that.setState({
                tableLoad: false
            })
            var curr = {
                total: resp.data.data.total,
                currPage: pageNumber
            }
            that.setState({
                tableData: resp.data.data.data,
                tablePage: curr
            })
        })
    }
    render() {
        var that = this
        var columns = [
            {
                title: "ID",
                dataIndex: "id"
            },
            {
                title: "备注名称",
                dataIndex: "name"
            },
            {
                title: "发件人",
                dataIndex: "sender"
            },
            {
                title: "域名",
                key: "id",
                render: function (value, row, index) {
                    return (
                        <UI.Button shape={"round"} size={'small'} type={"primary"}>
                            {row.domain.domain}
                        </UI.Button>
                    )
                }
            },
            {
                title: "邮订地址",
                key: "id",
                render: function (value, row, index) {
                    return (
                        <UI.Button shape={"round"} size={'small'} type={"primary"}>
                            {row.email_address}
                        </UI.Button>
                    )
                }
            },
            {
                title: "审核通过",
                key: 'id',
                render: function (value, row, index) {
                    return (
                        <UI.Button type={"primary"} shape={"circle"}>
                            {row.state ? '是' : '否'}
                        </UI.Button>
                    )
                }
            },
            {
                title: "邮箱管理",
                key: "id",
                align: "right",
                fixed: 'right',
                render: function (value, row, index) {
                    return (
                        <span>
                           <UI.Button onClick={()=>{
                               that.setState({
                                   sendCurr: row,
                                   sendShow: true
                               })
                           }} type={"link"}>发送测试</UI.Button>
                       </span>

                    )
                }
            }
        ]
        return (
            <div>
                <PageHeader title="邮件帐户" subTitle="发件邮件地址统一管理" extra={[
                    <UI.Button  key="1" type="primary" shape="round" onClick={()=>{
                        that.setState({
                            editCurr: {},
                            editShow: true
                        })
                    }}>新增邮箱帐户</UI.Button>
                ]}/>
                <div style={{marginTop:'22px'}} id="hot-top-toolbar">
                    <UI.Form onFinish={that.MakeSearch} layout={"inline"}>
                        <UI.Form.Item label={"帐户名称"} name={"name__contains"}>
                            <UI.Input placeholder={"请输入帐户名称"}/>
                        </UI.Form.Item>
                        <UI.Form.Item label={"域名"} name={"domain"}>
                            <UI.Select placeholder={"请选择帐户域名"}>
                                {that.state.graphs.map(gp=>{
                                    return (
                                        <Option key={gp.id} value={gp.id}>{gp.domain}</Option>
                                    )
                                })}
                            </UI.Select>
                        </UI.Form.Item>
                        <UI.Form.Item>
                            <UI.Button type={"primary"} htmlType={"submit"}>帐户搜索</UI.Button>
                        </UI.Form.Item>
                    </UI.Form>
                </div>
                <div style={{marginTop:'22px'}}>
                    <UI.Table
                        rowKey="id"
                        dataSource={this.state.tableData}
                        columns={columns}
                        loading={this.state.tableLoad}
                        pagination={{total:this.state.tablePage.total,defaultPageSize:20,defaultCurrent:this.state.tablePage.currPage,onChange:this.getTablePage}}/>
                </div>
                <UI.Modal visible={that.state.editShow} title={'发件人管理'} onCancel={()=>{
                    that.setState({
                        editCurr: {},
                        editShow: false
                    })
                }} footer={null} destroyOnClose>
                    <UI.Form onFinish={that.handle} labelCol={{span:24}} labelAlign={"left"}>
                        <UI.Form.Item label={"发件人名称"} name={"name"} rules={[{required:true,message:'请输入发件人名称'}]} initialValue={this.state.editCurr.name}>
                            <UI.Input placeholder={"请输入发件人名称"}/>
                        </UI.Form.Item>
                        <UI.Form.Item label={"邮件域名"} name={"domain"} rules={[{required:true,message:'请选择邮件域名'}]} initialValue={this.state.editCurr.domain}>
                            <UI.Select placeholder={"请选择邮件域名"} defaultValue={that.state.editCurr.sms_app}>
                                {that.state.graphs.map(gp=>{
                                    return (
                                        <Option key={gp.id} value={gp.id}>{gp.domain} - {gp.name}</Option>
                                    )
                                })}
                            </UI.Select>
                        </UI.Form.Item>
                        <UI.Form.Item label={"发件帐户"} help={"不包含@domain.com"} name={"email_address"} rules={[{required:true,message:'请输入发件帐户'}]} initialValue={this.state.editCurr.email_address}>
                            <UI.Input placeholder={"请输入发件帐户"}/>
                        </UI.Form.Item>
                        <UI.Form.Item label={"发件人"} help={"如：洛河集团"} name={"sender"} rules={[{required:true,message:'请输入发件人'}]} initialValue={this.state.editCurr.sender}>
                            <UI.Input placeholder={"请输入发件人"}/>
                        </UI.Form.Item>
                        <UI.Form.Item>
                            <UI.Button htmlType={"submit"} type={"primary"} block>提交发件人信息</UI.Button>
                        </UI.Form.Item>
                    </UI.Form>
                </UI.Modal>
                <UI.Modal footer={null} visible={that.state.sendShow} onCancel={()=>{
                    that.setState({
                        sendCurr: {},
                        sendShow: false
                    })
                }} destroyOnClose>
                    <UI.Form onFinish={(params)=>{
                        params.account = that.state.sendCurr.id
                        load.Loading()
                        HttpPost("merchant/sessend",params).then(resp=>{
                            load.HasLoaded()
                            if (resp.data.code === 200) {
                                if (resp.data.data.ok) {
                                    UI.message.success("邮件发送成功，请查收")
                                    that.setState({
                                        sendCurr: {},
                                        sendShow: false
                                    })
                                } else {
                                    UI.message.error(resp.data.data.msg)
                                }
                            } else {
                                UI.message.error(resp.data.msg ? resp.data.msg : "无法发起请求")
                            }
                        })
                    }} labelAlign={"left"} labelCol={{span:24}}>
                        <UI.Form.Item label={"邮件主题"} name={"subject"} rules={[{required:true,message:'请输入邮件主题'}]}>
                            <UI.Input placeholder={"请输入邮件主题"}/>
                        </UI.Form.Item>
                        <UI.Form.Item label={"邮件内容"} name={"content"} rules={[{required:true,message:'请输入邮件内容'}]}>
                            <TextArea rows={8} placeholder={"请输入邮件主题"}/>
                        </UI.Form.Item>
                        <UI.Form.Item label={"收件人"} name={"destination"} help={"多个邮箱使用英文逗号相陋"} rules={[{required:true,message:'请输入收件人'}]}>
                            <TextArea rows={3} placeholder={"请输入收件人"}/>
                        </UI.Form.Item>
                        <UI.Form.Item>
                            <UI.Button htmlType={"submit"} type={"primary"} block>发送测试邮件</UI.Button>
                        </UI.Form.Item>
                    </UI.Form>
                </UI.Modal>
            </div>
        );
    }
}