import * as UI from "antd";
import { PageHeader } from '@ant-design/pro-layout';
import React from "react";
const {Search,TextArea}=UI.Input;
const { Meta } = UI.Card;
const {Option} = UI.Select
export default class FormUtils extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        var fields = this.props.fields
        if (fields.formType==='radio'){
            return (
                <UI.Radio.Group>
                    {fields.fixValue.map(curr=>{
                        return (
                            <UI.Radio value={curr.value}>{curr.name}</UI.Radio>
                        )
                    })}
                </UI.Radio.Group>
            )
        } else if (fields.formType==='checkbox') {
            return (
                <UI.Checkbox.Group>
                    {fields.fixValue.map(curr=>{
                        return (
                            <UI.Checkbox value={curr.value}>{curr.name}</UI.Checkbox>
                        )
                    })}
                </UI.Checkbox.Group>
            )
        } else if (fields.formType==='select') {
            return (
                <UI.Select  placeholder={fields.placeholder ? fields.placeholder : `请输入${fields.name}`}>
                    {fields.fixValue.map(curr=>{
                        return (
                            <Option value={curr.value}>{curr.name}</Option>
                        )
                    })}
                </UI.Select>
            )
        } else if (fields.formType==='number') {
            return (
                <UI.InputNumber style={{width:'100%'}} placeholder={fields.placeholder ? fields.placeholder : `请输入${fields.name}`}/>
            )
        } else if (fields.formType==='textarea') {
            return <TextArea rows={6} placeholder={fields.placeholder ? fields.placeholder : `请输入${fields.name}`}/>
        } else {
            return  <UI.Input placeholder={fields.placeholder ? fields.placeholder : `请输入${fields.name}`}/>
        }
    }
}