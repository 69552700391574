import React from "react";
import * as UI from "antd";
import { PageHeader } from '@ant-design/pro-layout';
import HttpPost,{UploadProps} from "../../../../../utils/request";
import * as load from "../../../../../utils/loading"

const {Search, TextArea}=UI.Input;
const { Option } = UI.Select;

export default class Warehouse extends React.Component{
    constructor() {
        super();
        this.state={
            editCurr: {},
            editShow: false,
            group: [],
            imageUrl: null,
            loading: false,
            tableData: [],
            tablePage: {total: 0,currPage:1},
            tableLoad: false,
            apps: [],
            clients: [],
            CreateId: 1
        }
    }
    componentDidMount() {
        this.GetTable(1)
        this.GetApps()
    }
    /**
     * 获得商户应用
     * @constructor
     */
    GetApps = () => {
        let that = this;
        let para = {};
        para.pageNumber = 1;
        para.pageSize = 99999;
        HttpPost('grant/applist',para).then(function (resp) {
            that.setState({
                apps: resp.data.data.data
            })
        })
    }
    /**
     * 在线搜索
     * @param keywords
     * @constructor
     */
    MakeSearch=(keywords)=>{
        var that = this;
        this.setState({
            searchKey: {keywords: keywords}
        })
        that.getTablePage(1)
    }
    /**
     * 数据列表
     * @param page
     * @param pageSize
     * @constructor
     */
    GetTable=(page,pageSize=20)=>{
        let that = this;
        let para = {};
        para.pageNumber = page;
        para.pageSize = pageSize;
        Object.assign(para, that.state.searchKey)
        that.setState({
            tableLoad: true
        })
        HttpPost('system/wmslist',para).then(function (resp) {
            that.setState({
                tableLoad: false
            })
            var curr = {
                total: resp.data.data.total,
                currPage: page
            }
            that.setState({
                tableData: resp.data.data.data,
                tablePage: curr
            })
        })
    }
    /**
     * 提交应用信息
     * @param values
     * @constructor
     */
    Handle=(values)=>{
        var that = this
        values.imgUrl = that.state.imageUrl
        values.id = that.state.editCurr.id
        load.Loading()
        HttpPost("system/wmsadmin",values).then(function (res) {
            load.HasLoaded()
            if (res.data.code === 200) {
                UI.message.success("客户端信息修改成功")
                that.GetTable(that.state.tablePage.currPage)
                that.setState({
                    currShow: false
                })
            } else {
                UI.message.error("客户端修改失改")
            }
        })
    }
    AppComonent=()=>{
        var that = this
        if (!this.state.editCurr.client){
            return (
                <div>
                    <UI.Form.Item label={"所属应用"} name={"app"} rules={[{required:true,message:"请选择系统所属应用"}]}>
                        <UI.Select placeholder="请设置所属应用..." showSearch={true}>
                            {this.state.apps.map((item,index)=>{
                                return (
                                    <Option key={index} value={item.id}>{item.name}</Option>
                                )
                            })}
                        </UI.Select>
                    </UI.Form.Item>
                    <UI.Form.Item
                        label="系统域名"
                        rules={[{required:true,message:"系统域名"}]}
                        initialValue={that.state.editCurr.domain}
                        name="domain">
                        <UI.Input placeholder="请输入系统域名..."/>
                    </UI.Form.Item>
                </div>
            )
        }else {
            return null
        }
    }
    render() {
        const that = this;
        const tableConfig = [
            {
                title:'ID',
                dataIndex: 'id',
                key: 'id',
                width: "120",
                ellipsis: true,
            },
            {
                title:'服务名称',
                dataIndex: 'name',
                key: 'name',
            },
            {
                title:'版本',
                dataIndex: 'version',
                key: 'version',
                render: function (v,row,index) {
                    return JSON.parse(row.services.standard).version
                }
            },
            {

                title:'创建时间',
                dataIndex: 'creat_at',
                key: 'creat_at',
                render:function (v,row,index) {
                    var dd = new Date(row.creat_at)
                    return `${dd.toLocaleDateString()} ${dd.toLocaleTimeString()}`
                }
            },
            {

                title:'到期时间',
                dataIndex: 'expire_at',
                key: 'expire_at',
                render:function (v,row,index) {
                    var dd = new Date(row.expire_at)
                    return `${dd.toLocaleDateString()} ${dd.toLocaleTimeString()}`
                }
            },
            {
                title:'是否到期',
                dataIndex: 'expire_at',
                key: 'expire_at',
                render: function (v,row,index) {
                    var now = new Date()
                    var expire= new Date(row.expire_at)
                    return (
                        <UI.Button type={now >= expire ? "danger" : "primary"} shape={"circle"}>
                            {now >= expire ? "是" : "否"}
                        </UI.Button>
                    )
                }
            },
            {
                title: "系统管理",
                align:"right",
                render: function (v,row,index) {
                    var now = new Date()
                    var expire= Date(row.expire_at)
                    if(now>=expire){
                        return (
                            <UI.Button type={"link"}>立即续费</UI.Button>
                        )
                    } else {
                        return (
                            <UI.Button.Group>
                                <UI.Button type={"link"} onClick={(()=>{
                                    var config = {}
                                    config.name = row.name
                                    if (row.client){
                                        config.client = row.client.id
                                        config.domain = row.client.Host
                                    }
                                    config.id = row.id
                                    that.setState({
                                        editCurr:config,
                                        editShow: true
                                    })
                                })}>配置</UI.Button>
                                {/*<UI.Button type={"link"}>域名</UI.Button>*/}
                                {/*<UI.Button type={"link"}>应用</UI.Button>*/}
                            </UI.Button.Group>
                        )
                    }
                }
            }
        ]
        return (
            <div>
                <PageHeader title="云仓库在线自动采购部署系统" subTitle="可在线对仓库进行采购，升级，配置，初始化等操作，注，因为系统的复杂性，系统只可升级无法降级" extra={[
                    <a href="/purchase/item/wms" key="2"><UI.Button type="primary" shape="round">系统采购</UI.Button></a>
                ]}/>
                <div id="tablecontains">
                    <div id="toolbar">
                        <Search placeholder="请输入仓库名称..." enterButton="订单搜索" size="large" onSearch={this.MakeSearch}/>
                    </div>
                </div>
                <div id="table">
                    <UI.Table
                        rowKey="id"
                        dataSource={this.state.tableData}
                        columns={tableConfig}
                        loading={this.state.tableLoad}
                        pagination={{onChange:this.GetTable,total:this.state.tablePage.total,pageSize:10,current:this.state.tablePage.currPage}}/>
                </div>
                <UI.Modal title="云仓库系统配置管理" footer={null} visible={that.state.editShow} onCancel={()=>{
                    that.setState({
                        editShow:false
                    })
                }} destroyOnClose>
                    <UI.Form onFinish={this.Handle} labelCol={{span:24}} labelAlign={"left"}>
                        <UI.Form.Item
                            label="系统名称"
                            rules={[{required:true,message:"请输入系统名称"}]}
                            initialValue={that.state.editCurr.name}
                            name="name">
                            <UI.Input placeholder="请输入系统名称..."/>
                        </UI.Form.Item>
                        <this.AppComonent/>
                        <UI.Form.Item style={{textAlign:'right'}}>
                            <UI.Button type="primary" shape="round" htmlType="submit">提交或则更新仓库信息</UI.Button>
                        </UI.Form.Item>
                    </UI.Form>
                </UI.Modal>
            </div>
        )
    }
}
