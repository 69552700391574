import React,{Component} from "react";
import HttpPost, {UploadProps} from "../../../../../utils/request";
import * as UI from 'antd'
import { PageHeader } from '@ant-design/pro-layout'

import * as load from "../../../../../utils/loading";

const {Option} = UI.Select
const {TextArea} =UI.Input

export default class Buckets extends Component{
    constructor() {
        super();
        this.state = {
            tableData: [],
            tablePage: {total: 0,currPage:1},
            tableLoad: false,
            searchKey: {},
            editShow: false,
            editCurr: {},
            endpoints: []
        }
    }
    componentDidMount() {
        this.loadEndpoints()
        this.GetTable()
    }

    /**
     * 数据提交
     * @param values
     */
    handle=(values)=>{
        let that = this;
        if (Object.keys(that.state.editCurr).length > 0) {
            values.id = that.state.editCurr.id;
        }
        load.Loading();
        HttpPost("/service/storagebucketcreate",values).then(resp=>{
            load.HasLoaded();
            if (resp.data.code===200){
                if(resp.data.data.code === 200){
                    UI.message.success("存储节点信息提交成功")
                    this.setState({
                        editShow: false,
                        editCurr: {}
                    })
                    this.GetTable(1)
                } else {
                    UI.message.warning(resp.data.data.msg)
                }
            }else {
                UI.message.error(resp.data.msg)
            }
        })
    }
    /**
     * 发起搜索
     * @param params
     * @constructor
     */
    MakeSearch=(params)=>{
        console.log(params)
        this.setState({
            searchKey: params
        })
        var that = this
        setTimeout(function () {
            that.GetTable()
        },200)
    }
    /**
     * 签名列表
     * @param pageNumber
     Object.assign(para, that.state.keywords)
     * @param pageSize
     * @constructor
     */
    GetTable=(pageNumber=1,pageSize=20)=>{
        let that = this;
        let para = {};
        para.pageNumber = pageNumber;
        para.pageSize = pageSize;
        that.setState({
            tableLoad: true
        })
        Object.assign(para,that.state.searchKey)
        HttpPost('/service/storagebucketlist',para).then(function (resp) {
            that.setState({
                tableLoad: false
            })
            var curr = {
                total: resp.data.data.total,
                currPage: pageNumber
            }
            that.setState({
                tableData: resp.data.data.data,
                tablePage: curr
            })
        })
    }
    /**
     * 加载节点
     */
    loadEndpoints(){
        let that = this;
        let para = {};
        para.pageNumber = 1;
        para.pageSize = 2000;
        HttpPost('/service/storageendpointpage',para).then(function (resp) {
            that.setState({
                endpoints: resp.data.data.data,
            })
        })
    }
    render() {
        var that = this
        var columns = [
            {
                title: "ID",
                dataIndex: "id"
            },
            {
                title: "存储桶名称",
                dataIndex: "name"
            },
            {
                title: "节点",
                key: "id",
                render: function (value, row, index) {
                    return row.provider.name
                }
            },
            {
                title: "状态",
                key: 'id',
                render: function (value, row, index) {
                    return (
                        <UI.Button type={"primary"} shape={"circle"}>
                            {row.state ? '是' : '否'}
                        </UI.Button>
                    )
                }
            },
            {
                title: "节点管理",
                key: "id",
                align: "right",
                fixed: 'right',
                render: function (value, row, index) {
                    var dd = JSON.parse(JSON.stringify(row))
                    return (
                        <span>
                           <UI.Button type={"link"}>属性</UI.Button>
                       </span>

                    )
                }
            }
        ]
        return (
            <div style={{padding:"26px"}}>
                <PageHeader title="存储桶管理" subTitle="对存储桶进行配置与进行文件管理" extra={[
                    <UI.Button  key="1" type="primary" shape="round" onClick={()=>{
                        that.setState({
                            editCurr: {},
                            editShow: true,
                            currEndpointKey: null
                        })
                    }}>新增存储桶</UI.Button>
                ]}/>
                <div style={{marginTop:'22px'}} id="hot-top-toolbar">
                    <UI.Form onFinish={that.MakeSearch} layout={"inline"}>
                        <UI.Form.Item label={"存储桶名称"} name={"name__contains"}>
                            <UI.Input placeholder={"请输入存储桶户名称"}/>
                        </UI.Form.Item>
                        <UI.Form.Item>
                            <UI.Button type={"primary"} htmlType={"submit"}>存储桶搜索</UI.Button>
                        </UI.Form.Item>
                    </UI.Form>
                </div>
                <div style={{marginTop:'22px'}}>
                    <UI.Table
                        rowKey="id"
                        dataSource={this.state.tableData}
                        columns={columns}
                        loading={this.state.tableLoad}
                        pagination={{total:this.state.tablePage.total,defaultPageSize:20,defaultCurrent:this.state.tablePage.currPage,onChange:this.getTablePage}}/>
                </div>
                <UI.Modal visible={that.state.editShow} title={'存储桶管理'} onCancel={()=>{
                    that.setState({
                        editCurr: {},
                        editShow: false,
                        currEndpointKey: null
                    })
                }} footer={null} destroyOnClose>
                    <UI.Form onFinish={that.handle} labelCol={{span:24}} labelAlign={"left"}>
                        <UI.Form.Item label={"节点名称"} name={"name"}
                                      help={"必须使用英文,若亚马逊需绑定域名 ,则使用相应绑定域名"}
                                      rules={[{required:true,message:'请输入节点名称'}]}
                                      initialValue={this.state.editCurr.name}>
                            <UI.Input placeholder={"请输入节点名称..."}/>
                        </UI.Form.Item>
                        <UI.Form.Item label={"访问权限"} name={"authority"}
                                      rules={[{required:true,message:'请选择合适的权限'}]}
                                      initialValue={this.state.editCurr.authority}>
                            <UI.Radio.Group defaultValue={this.state.editCurr.authority}>
                                <UI.Radio value={"private"}>私有读写</UI.Radio>
                                <UI.Radio value={"public-read"}>公有读私有写</UI.Radio>
                                <UI.Radio value={"public-read-write"}>公有读写</UI.Radio>
                            </UI.Radio.Group>
                        </UI.Form.Item>
                        <UI.Form.Item label={"节点"} name={"provider"} rules={[{required:true,message:'请选择相应的节点'}]} initialValue={that.state.editCurr.provider ? that.state.editCurr.provider.id : null}>
                            <UI.Select placeholder={"请选择相应的节点"}>
                                {that.state.endpoints.map(gp=>{
                                    return (
                                        <Option key={gp.id} value={gp.id}>{gp.name}</Option>
                                    )
                                })}
                            </UI.Select>
                        </UI.Form.Item>
                        <UI.Form.Item>
                            <UI.Button htmlType={"submit"} type={"primary"} block>提交存储桶信息</UI.Button>
                        </UI.Form.Item>
                    </UI.Form>
                </UI.Modal>
            </div>
        );
    }
}