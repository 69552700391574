/**
 * author: Alex Lai
 * Support Website: https://laialex.com
 * Email: alex@laialex.com
 * Create Time: 2024/1/3
 */
import React, {Component} from "react";
import * as UI from "antd";
import { PageHeader } from '@ant-design/pro-layout';
import apis from "../../../../../utils/apis";
import {BellOutlined} from "@ant-design/icons";


const {TextArea} = UI.Input
const {Option} = UI.Select

export default class EIamProduct extends Component {
    constructor() {
        super();
        this.state = {
            tableData: [],
            paging: {total: 0, pageNumber: 1, pageSize: 20},
            tableLoad: false,
            searchKey: {},
            editShow: false,
            editCurr: {},
            submitting: false,
        }
    }

    componentDidMount() {
        this.tableDataSync(1)
    }

    /**
     * 发起搜索
     * @param params
     * @constructor
     */
    makeSearch = (params) => {
        this.setState({
            searchKey: params
        })
        var that = this
        setTimeout(function () {
            that.tableDataSync()
        }, 200)
    }
    /**
     * 签名列表
     * @param pageNumber
     Object.assign(para, that.state.keywords)
     * @param pageSize
     * @constructor
     */
    tableDataSync = (pageNumber = 1) => {
        let that = this;
        let para = {};
        para.pageNumber = pageNumber;
        para.pageSize = 20;
        that.setState({
            tableLoad: true
        })
        Object.assign(para, that.state.searchKey)
        apis.eiamProducts(para).then(function (resp) {
            that.setState({
                tableLoad: false
            })
            var curr = {
                total: resp.data.data.total,
                pageNumber: pageNumber
            }
            that.setState({
                tableData: resp.data.data.data,
                paging: curr
            })
        })
    }


    render() {
        var that = this

        const columns = [
            {
                title: "ID",
                dataIndex: "id"
            },
            {
                title: "产品名称",
                key: "id",
                render: function (value, row, index) {
                    return (
                        <div style={{fontSize: "14px", fontWeight: "600"}}>{row.name}</div>
                    )
                }
            },
            {
                title: "产品代码",
                key: "id",
                render: function (value, row, index) {
                    return (
                        <div>{row.code}</div>
                    )
                }
            },
            {
                title: "产品介绍 ",
                key: "id",
                render: function (value, row, index) {
                    return  row.remark
                }
            },
            {
                title: "可用",
                key: "id",
                render: function (value, row, index) {
                    return (
                        <UI.Button shape={"round"} type={"circle"}>{row.state ? '是' : '否'}</UI.Button>
                    )
                }
            },
            {
                title: "管理",
                key: "id",
                fixed: "right",
                render: function (value, row, index) {
                    return (
                        <span>
                             <UI.Button onClick={() => {
                                 var data = JSON.parse(JSON.stringify(row))
                                 that.setState({
                                     editCurr: data,
                                     editShow: true
                                 })
                             }} type={"link"}>编辑</UI.Button>
                        </span>
                    )
                }
            }
        ]

        return (
            <div>
                <PageHeader title="授权产品管理" subTitle="启用与编辑授权产品" extra={[
                    <UI.Button key="1" type="primary" shape="round" onClick={() => {
                        that.setState({
                            editCurr: {},
                            editShow: true,
                            submitting: false
                        })
                    }}>新增产品</UI.Button>
                ]}/>
                <UI.Card>
                    <div style={{marginTop: '22px'}}>
                        <UI.Form onFinish={that.makeSearch} layout={"inline"}>
                            <UI.Form.Item label={"产品名称"} name={"name__contains"}>
                                <UI.Input placeholder={"产品名称"}/>
                            </UI.Form.Item>
                            <UI.Form.Item>
                                <UI.Button type={"primary"} htmlType={"submit"}>快速搜索</UI.Button>
                            </UI.Form.Item>
                        </UI.Form>
                    </div>
                </UI.Card>

                <div style={{marginTop: '22px'}}>
                    <UI.Table
                        rowKey="id"
                        dataSource={this.state.tableData}
                        columns={columns}
                        loading={this.state.tableLoad}
                        pagination={{
                            total: this.state.paging.total,
                            defaultPageSize: 20,
                            defaultCurrent: this.state.paging.pageNumber,
                            onChange: this.tableDataSync
                        }}/>
                </div>

                <UI.Modal visible={that.state.editShow} title={'产品管理'} onCancel={() => {
                    that.setState({
                        editCurr: {},
                        editShow: false,
                        submitting: false
                    })
                }} footer={null} destroyOnClose>
                    <UI.Form onFinish={(params => {
                        that.setState({
                            submitting: true
                        })
                        if (that.state.editCurr.id) {
                            params.id = that.state.editCurr.id
                        }
                        apis.eiamProductModify(params).then(resp => {
                            that.setState({
                                submitting: false
                            })
                            if (resp.data.code === 200) {
                                UI.message.success("提交信息成功")
                                that.tableDataSync(that.state.editCurr.id ? that.state.paging.pageNumber : 1)
                                that.setState({
                                    submitting: false,
                                    editShow: false,
                                    editCurr: {}
                                })
                            } else {
                                UI.message.error(resp.data.data.msg ? resp.data.data.msg : resp.data.msg)
                            }
                        })
                    })} labelCol={{span: 24}} labelAlign={"left"} initialValues={that.state.editCurr}>
                        <UI.Form.Item label={"授权产品名称"} name={"name"}
                                      rules={[{required: true, message: '授权产品名称'}]}>
                            <UI.Input placeholder={"授权产品名称"}/>
                        </UI.Form.Item>
                        <UI.Form.Item label={"授权产品代码"} name={"code"}
                                      rules={[{required: true, message: '授权产品代码'}]}>
                            <UI.Input placeholder={"授权产品代码"} disabled={that.state.editCurr.id}/>
                        </UI.Form.Item>
                        <UI.Form.Item label={"授权产品可用"} name={"state"}
                                      rules={[{required: true, message: '授权产品可用'}]}>
                            <UI.Radio.Group>
                                <UI.Radio value={true}>可用</UI.Radio>
                                <UI.Radio value={false}>禁用</UI.Radio>
                            </UI.Radio.Group>
                        </UI.Form.Item>
                        <UI.Form.Item label={"授权产品说明"} name={"remark"}
                                      rules={[{required: true, message: '授权产品说明'}]}>
                            <TextArea rows={6} placeholder={"授权产品说明"}/>
                        </UI.Form.Item>
                        <UI.Form.Item>
                            <UI.Button htmlType={"submit"} loading={that.state.submitting} type={"primary"}
                                       block>立即提交
                            </UI.Button>
                        </UI.Form.Item>
                    </UI.Form>
                </UI.Modal>
            </div>
        )
    }
}