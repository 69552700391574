import React from "react";
import * as UI from "antd";
import { PageHeader } from '@ant-design/pro-layout';
import "./group.css"
import HttpPost from "../../../../utils/request";
import * as load from "../../../../utils/loading"

const {Search,TextArea}=UI.Input;

export default class GrantGroup extends React.Component{
    constructor(props) {
        super(props);
        this.state={
            tableData: [],
            tablePage: {total: 0,currPage:1},
            tableLoad: false,
            searchKey: {},
            editShow: false,
            editCurr: {}
        }
    }
    componentDidMount() {
        this.getTablePage(1);
    }

    MakeSearch=(keywords)=>{
        var that = this;
        this.setState({
            searchKey: Object.assign(that.state.searchKey,{name__icontains: keywords})
        })
        that.getTablePage(1)
    }
    handle=(values)=>{
        let that = this;
        if (Object.keys(that.state.editCurr).length > 0) {
            values.id = that.state.editCurr.id;
        }
        load.Loading();
        HttpPost("grant/addgroup",values).then(resp=>{
            load.HasLoaded();
            if (resp.data.code===200){
                if(resp.data.data.code===200){
                    UI.message.success("授权分组信息维护完成")
                    this.setState({
                        editShow: false,
                        editCurr: {}
                    })
                    this.getTablePage(1)
                } else {
                    UI.message.warning(resp.data.data.msg)
                }
            }else {
                UI.message.error(resp.data.msg)
            }
        })
    }
    getTablePage=(page,pageSize=20)=>{
        let that = this;
        let para = {};
        para.pageNumber = page;
        para.pageSize = pageSize;
        Object.assign(para, that.state.searchKey)
        that.setState({
            tableLoad: true
        })
        HttpPost('grant/grantgrouplist',para).then(function (resp) {
            that.setState({
                tableLoad: false
            })
            var curr = {
                total: resp.data.data.total,
                currPage: page
            }
            that.setState({
                tableData: resp.data.data.data,
                tablePage: curr
            })
        })
    }
    render() {
        let that = this;
        const columns =[
            {
                title:'ID',
                dataIndex: 'id',
                key: 'id'
            },
            {
                title: '分组名称',
                dataIndex: 'name',
                key: 'name'
            },
            {
                title: '分组说明',
                dataIndex: 'explain',
                key: 'explain'
            },
            {
                title: '分组操作',
                key:'operation',
                align:'right',
                width: 220,
                render: (col,row,index)=>{
                    return (
                        <div className="opt">
                            <UI.Button type="link" onClick={()=>{
                                that.setState({
                                    editCurr:row,
                                    editShow: true
                                })
                            }}>编辑</UI.Button>
                        </div>
                    )
                }
            }
        ]
        return (
            <div id="grant-list">
                <PageHeader title="授权分组管理" subTitle="结第三方授权进行分组，以方便不同的应用进行调用" extra={[
                        <UI.Button  key="1" type="primary" shape="round" onClick={()=>{
                            that.setState({
                                editCurr: {},
                                editShow: true
                            })
                        }}>新增分组</UI.Button>
                    ]}/>
                <div id="tablecontains">
                    <div id="toolbar">
                        <Search placeholder="请输入授权名称关键词..." enterButton="分组搜索" size="large" onSearch={this.MakeSearch}/>
                    </div>
                </div>
                <div style={{marginTop:'22px'}}>
                    <UI.Table
                        rowKey="id"
                        dataSource={this.state.tableData}
                        columns={columns}
                        loading={this.state.tableLoad}
                        pagination={{total:this.state.tablePage.total,defaultPageSize:20,defaultCurrent:this.state.tablePage.currPage,onChange:this.getTablePage}}/>
                </div>
                <UI.Modal title="新增授权分组" visible={this.state.editShow} footer={null} onCancel={()=>{
                    this.setState({
                        editShow: false
                    })
                }} destroyOnClose>
                    <UI.Form labelCol={{span:24}} labelAlign="left" onFinish={this.handle}>
                        <UI.Form.Item name="name" label="分组名称" rules={[{required:true,message:'请输入分组名称'}]} initialValue={this.state.editCurr.name}>
                            <UI.Input placeholder="请输入授权分组名称..."/>
                        </UI.Form.Item>
                        <UI.Form.Item name="explain" label="分组说明"  rules={[{required:true,message:'请输入分组说胆'}]} initialValue={this.state.editCurr.explain}>
                            <TextArea placeholder="请输入授权分组介绍..." rows={6}/>
                        </UI.Form.Item>
                        <UI.Form.Item style={{textAlign:'right'}}>
                            <UI.Button type="primary" shape="round" htmlType="submit">提交分组信息</UI.Button>
                        </UI.Form.Item>
                    </UI.Form>
                </UI.Modal>
            </div>
        )
    }
}
