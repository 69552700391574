import React from "react";
import * as UI from "antd";
import { PageHeader } from '@ant-design/pro-layout';
import HttpPost from "../../../utils/request";
import * as load from "../../../utils/loading"
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import * as fa from '@fortawesome/fontawesome-free-solid'
import {Loading} from "../../../utils/loading";
import {HasLoaded} from "../../../utils/loading";
import FormUtils from "../../../utils/FormUtils";

const {Search,TextArea}=UI.Input;
const { Meta } = UI.Card;
const {Option} = UI.Select

export default class MiniPram extends  React.Component{
    constructor() {
        super();
        this.state={
            tableData: [],
            tablePage: {total: 0,currPage:1},
            tableLoad: false,
            searchKey: {},
            editShow: false,
            editCurr: {},
            taxonomy: [],
            auths: []
        }
    }
    componentDidMount() {
        this.GetTaxonomy()
        this.GetTable()
        this.GetAuths()
    }

    /**
     * 获得所有的可用终端
     * @constructor
     */
    GetAuths = () => {
        let para = {};
        para.pageNumber = 1;
        para.pageSize = 99999;
        var that = this;
        HttpPost('grant/getauths',para).then(function (resp) {
            that.setState({
                auths: resp.data.data.data
            })
        })
    }
    GetTaxonomy(){
        var that = this
        HttpPost("marketplace/marketplacetaxonomy").then(function (resp) {
            that.setState({
                taxonomy: resp.data.data
            })
        })
    }

    /**
     * 服务列表
     * @param pageNumber
     * @param pageSize
     * @constructor
     */
    GetTable(pageNumber=1,pageSize=15){
        var  that = this;
        var para = {};
        para.pageNumber = pageNumber;
        para.pageSize = pageSize;
        Object.assign(para, that.state.searchKey)
        that.setState({
            tableLoad: true
        })
        HttpPost('system/miniprams',para).then(function (resp) {
            var curr = {
                total: resp.data.data.total,
                currPage: pageNumber
            }
            that.setState({
                tableLoad: false,
                tableData: resp.data.data.data,
                tablePage: curr
            })
        })
    }

    /**
     * 提交数据
     * @param values
     * @returns {*}
     * @constructor
     */
    Handle=(values)=>{
        var that = this
        var para = {property: {}}
        Object.keys(values).map(function (ks) {
            if (ks.indexOf('property')!==-1){
                para.property[ks.replace("property",'').replace('[','').replace(']','')]=values[ks]
            } else {
                para[ks]=values[ks]
            }
        })
        if (Object.keys(that.state.editCurr).length>0){
            para.id =that.state.editCurr.id
        }
        load.Loading()
        HttpPost('merchant/meshmodify',para).then(function (resp) {
            load.HasLoaded();
            if(resp.data.code===200){
                UI.message.success("微应用修改成功，系统将推送配置至终端系统")
                that.GetTable(that.state.tablePage.currPage)
                that.setState({
                    editShow: false
                })
            }else {
                UI.message.error(resp.data.msg)
            }
        })
    }
    /**
     * 域名检查
     * @param domain
     * @param record
     * @constructor
     */
    DomainCheck = (rule,value,callback)=>{
        var that = this
        var domain = 'apptago.com'
        if (JSON.parse(that.state.editCurr.property).domain.indexOf('appify.cn')!==-1){
            domain = 'appify.cn'
        }
        var check = JSON.parse(that.state.editCurr.property).domain.replace(`.${domain}`,'')
        if (check.length > 0) {
            value = `${value}.${check}`
        }
        var para = {}
        para.domain = domain
        para.subdomain = value
        HttpPost("system/checkdomainexites",para).then(function (resp) {
            if (resp.data.code === 200) {
                if (resp.data.data.code === 200) {
                    callback()
                } else {
                    callback(resp.data.data.error)
                }
            } else {
                callback(resp.data.msg)
            }
        })
    }
    GetTableComonent=(fields)=>{
        if (fields.formType==='radio'){
            return (
                <UI.Radio.Group>
                    {fields.fixValue.map(curr=>{
                        return (
                            <UI.Radio value={curr.value}>{curr.name}</UI.Radio>
                        )
                    })}
                </UI.Radio.Group>
            )
        } else if (fields.formType==='checkbox') {
            return (
                <UI.Checkbox.Group>
                    {fields.fixValue.map(curr=>{
                        return (
                            <UI.Checkbox value={curr.value}>{curr.name}</UI.Checkbox>
                        )
                    })}
                </UI.Checkbox.Group>
            )
        } else if (fields.formType==='select') {
            return (
                <UI.Select  placeholder={fields.placeholder ? fields.placeholder : `请输入${fields.name}`}>
                    {fields.fixValue.map(curr=>{
                        return (
                            <Option value={curr.value}>{curr.name}</Option>
                        )
                    })}
                </UI.Select>
            )
        } else if (fields.formType==='number') {
            return (
                <UI.InputNumber style={{width:'100%'}} placeholder={fields.placeholder ? fields.placeholder : `请输入${fields.name}`}/>
            )
        } else if (fields.formType==='textarea') {
            return <TextArea rows={6} placeholder={fields.placeholder ? fields.placeholder : `请输入${fields.name}`}/>
        } else {
            return  <UI.Input placeholder={fields.placeholder ? fields.placeholder : `请输入${fields.name}`}/>
        }
    }
    render() {
        var that = this

        var tableConfig = [
            {
                title:'ID',
                dataIndex: 'id',
                key: 'id'
            },
            {
                title:'应用名称',
                dataIndex: 'name',
                key: 'name'
            },
            {
                title:'所属服务',
                dataIndex: 'service',
                key: 'service',
                render: function (value, row, index) {
                    return row.service.name
                }
            },
            {
                title:'过期时间',
                dataIndex: 'expire',
                key: 'expire',
                render: function (value, row, index) {
                    var dd = new Date(row.expire)
                    return `${dd.toLocaleDateString()} ${dd.toLocaleTimeString()}`
                }
            },
            {
                title:'采购时间',
                dataIndex: 'create_at',
                key: 'create_at',
                render: function (value, row, index) {
                    var dd = new Date(row.create_at)
                    return `${dd.toLocaleDateString()} ${dd.toLocaleTimeString()}`
                }
            },
            {
                title:'产品',
                dataIndex: 'variant',
                key: 'variant',
                render: function (value,row,index) {
                    if (row.variant) {
                        return row.variant.item.title
                    } else {
                        return '未指定产品'
                    }
                }
            },
            {
                title:'产品规格',
                dataIndex: 'variant',
                key: 'variant',
                render: function (value, row, index) {
                    if (row.variant) {
                        var vl = []
                        Object.keys(JSON.parse(row.variant.standard)).map(function (ks) {
                            vl.push(`${JSON.parse(row.variant.item.standard_mapping)[ks].name}: ${JSON.parse(row.variant.standard)[ks]}`)
                        })
                        return vl.join('\r\n')
                    } else {
                        return '未指定产品'
                    }

                }
            },
            {
                title:'应用编辑',
                key: 'id',
                render: function (value, row, index) {
                    return (
                        <UI.Button.Group>
                            <UI.Button type={"link"} onClick={()=>{
                                var para = JSON.parse(JSON.stringify(row))
                                if (para.authorization) {
                                    para.authorization = para.authorization.id
                                }
                                that.setState({
                                    editCurr: para,
                                    editShow: true
                                })
                            }}>编辑</UI.Button>
                        </UI.Button.Group>
                    )
                }
            }
        ]
        const Domain = ()=>{
            if (that.state.editCurr.property) {
                if (JSON.parse(that.state.editCurr.property).domain) {
                    return (
                        <UI.Form.Item label="域名设置" name='property[subdomain]' initialValue={JSON.parse(that.state.editCurr.property).subdomain}
                                      rules={[{required:true,message:"请选择关联终端"},{validator: that.DomainCheck}]}>
                            <UI.Input addonAfter={JSON.parse(that.state.editCurr.property).domain} placeholder="请配置您所期望的域名，使用根域名请输入@" />
                        </UI.Form.Item>
                    )
                } else {
                    return null
                }

            } else {
                return null
            }
        }
        const AuthComponent = () =>{
            if (that.state.editCurr.service.need_authorization) {
                return (
                    <UI.Form.Item label="第三方登陆授权"
                                  rules={[{required:true,message:"请选择应用第三方授权"}]} initialValue={that.state.editCurr.authorization}
                                  name='authorization'>
                        <UI.Select placeholder='请选择你所需要用到的授权认证信息'>
                            {that.state.auths.map(auth=>{
                                if (auth.grant.systemGroup.id ===that.state.editCurr.service.default_system_grant_group.id){
                                    return (
                                        <Option value={auth.id} key={auth.id}>{auth.name}</Option>
                                    )
                                } else {
                                    return null
                                }
                            })}
                        </UI.Select>
                    </UI.Form.Item>
                )
            }else {
                return null
            }
        }
        const Property = () =>{
            if (that.state.editCurr.service.property){
                var property=JSON.parse(that.state.editCurr.service.property)
                return (
                    <fieldset>
                        <legend>属性字段</legend>
                        {Object.keys(property).map(pp=>{
                            var fields = property[pp]
                            var cmp = that.GetTableComonent(fields)
                            switch (fields.formType) {
                                case "radio" :
                                    return (
                                        <UI.Form.Item
                                            key={pp}
                                            initialValue={JSON.parse(that.state.editCurr.property)[pp]}
                                            name={`property[${property[pp].field}]`}
                                            rules={[{required:property[pp].required,message:`请填写${property[pp].name}的信息`}]}
                                            label={property[pp].name}>
                                            <UI.Radio.Group>
                                                {fields.fixValue.map(curr=>{
                                                    return (
                                                        <UI.Radio value={curr.value}>{curr.name}</UI.Radio>
                                                    )
                                                })}
                                            </UI.Radio.Group>
                                        </UI.Form.Item>
                                    )
                                case "checkbox":
                                    return (
                                        <UI.Form.Item
                                            key={pp}
                                            initialValue={JSON.parse(that.state.editCurr.property)[pp]}
                                            name={`property[${property[pp].field}]`}
                                            rules={[{required:property[pp].required,message:`请填写${property[pp].name}的信息`}]}
                                            label={property[pp].name}>
                                            <UI.Checkbox.Group>
                                                {fields.fixValue.map(curr=>{
                                                    return (
                                                        <UI.Checkbox value={curr.value}>{curr.name}</UI.Checkbox>
                                                    )
                                                })}
                                            </UI.Checkbox.Group>
                                        </UI.Form.Item>
                                    )
                                case 'select':
                                    return (
                                        <UI.Form.Item
                                            key={pp}
                                            initialValue={JSON.parse(that.state.editCurr.property)[pp]}
                                            name={`property[${property[pp].field}]`}
                                            rules={[{required:property[pp].required,message:`请填写${property[pp].name}的信息`}]}
                                            label={property[pp].name}>
                                            <UI.Select  placeholder={fields.placeholder ? fields.placeholder : `请输入${fields.name}`}>
                                                {fields.fixValue.map(curr=>{
                                                    return (
                                                        <Option value={curr.value}>{curr.name}</Option>
                                                    )
                                                })}
                                            </UI.Select>
                                        </UI.Form.Item>
                                    )
                                case 'number':
                                    return (
                                        <UI.Form.Item
                                            key={pp}
                                            initialValue={JSON.parse(that.state.editCurr.property)[pp]}
                                            name={`property[${property[pp].field}]`}
                                            rules={[{required:property[pp].required,message:`请填写${property[pp].name}的信息`}]}
                                            label={property[pp].name}>
                                            <UI.InputNumber style={{width:'100%'}} placeholder={fields.placeholder ? fields.placeholder : `请输入${fields.name}`}/>
                                        </UI.Form.Item>
                                    )
                                case 'textarea':
                                    return (
                                        <UI.Form.Item
                                            key={pp}
                                            initialValue={JSON.parse(that.state.editCurr.property)[pp]}
                                            name={`property[${property[pp].field}]`}
                                            rules={[{required:property[pp].required,message:`请填写${property[pp].name}的信息`}]}
                                            label={property[pp].name}>
                                            <TextArea rows={6} placeholder={fields.placeholder ? fields.placeholder : `请输入${fields.name}`}/>
                                        </UI.Form.Item>
                                    )
                                default:
                                    return (
                                        <UI.Form.Item
                                            key={pp}
                                            initialValue={JSON.parse(that.state.editCurr.property)[pp]}
                                            name={`property[${property[pp].field}]`}
                                            rules={[{required:property[pp].required,message:`请填写${property[pp].name}的信息`}]}
                                            label={property[pp].name}>
                                            <UI.Input placeholder={fields.placeholder ? fields.placeholder : `请输入${fields.name}`}/>
                                        </UI.Form.Item>
                                    )
                            }
                        })}
                    </fieldset>
                )
            } else {
                return null
            }
        }
        return (
            <div>
                <PageHeader title="微应用列表" subTitle="用户可在线对所采购微应用进行管理，续费等操作"/>
                <div style={{padding:'22px',backgroundColor:'#ffffff'}}>
                    <UI.Form layout={"inline"}>
                        <UI.Form.Item label='应用分类' name='Service__Id'>
                            <UI.Select placeholder={'请选择你要搜索的应用分类'}>
                                {this.state.taxonomy.map(curr=>{
                                    return (
                                        <Option key={curr.id} value={curr.id}>{curr.name}</Option>
                                    )
                                })}
                            </UI.Select>
                        </UI.Form.Item>
                        <UI.Form.Item label={'应用名称'} name="name__icontains">
                            <UI.Input placeholder='请输入应用名称...'/>
                        </UI.Form.Item>
                        <UI.Form.Item>
                            <UI.Button type={"primary"}>应用搜索</UI.Button>
                        </UI.Form.Item>
                    </UI.Form>
                </div>
                <div id="table">
                    <UI.Table
                        rowKey="id"
                        dataSource={this.state.tableData}
                        columns={tableConfig}
                        loading={this.state.tableLoad}
                        pagination={{onChange:this.GetTable,total:this.state.tablePage.total,pageSize:15,current:this.state.tablePage.currPage}}/>
                </div>
                <UI.Modal title="微服务配置" footer={null} visible={that.state.editShow} onCancel={()=>{
                    that.setState({
                        editShow:false
                    })
                }} destroyOnClose>
                    <UI.Form onFinish={that.Handle} labelCol={{span:24}} labelAlign={"left"}>
                        <UI.Form.Item label="应用名称" name="name"
                                      rules={[{required:true,message:"请输入应用名称"}]} initialValue={that.state.editCurr.name}>
                            <UI.Input/>
                        </UI.Form.Item>
                        <Domain/>
                        <AuthComponent/>
                        <Property/>
                        <UI.Form.Item style={{textAlign:'right'}}>
                            <UI.Button type="primary" shape="round" htmlType="submit">提交微应用配置信息</UI.Button>
                        </UI.Form.Item>
                    </UI.Form>
                </UI.Modal>
            </div>
        )
    }

}