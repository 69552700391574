import React from "react";
import * as UI from 'antd'
import { PageHeader } from '@ant-design/pro-layout'
import HttpPost from "../../../../utils/request";
import {Loading,HasLoaded} from "../../../../utils/loading";
import {DateFormat} from "../../../../utils/tools"
import apis from "../../../../utils/apis";


export default class Users extends React.Component {
    constructor(props) {
        super(props);
        this.state={
            tableData: [],
            tablePage: {total: 0,currPage:1},
            tableLoad: false,
            group: [],
            grant: [],
            editGrantCurr: {},
            editGrantShow: false,
            passShow: false,
            currPassed: ""
        }
    }
    componentDidMount() {
        this.getTablePage(1);
    }
    MakeSearch=(keywords)=>{
        var that = this;
        this.setState({
            searchKey: Object.assign(that.state.searchKey,{name__icontains: keywords})
        })
        that.getTablePage(1)
    }
    getTablePage=(page,pageSize=20)=>{
        let that = this;
        let para = {};
        para.pageNumber = page;
        para.pageSize = pageSize;
        Object.assign(para, that.state.searchKey)
        that.setState({
            tableLoad: true
        })
        HttpPost('iam/userlist',para).then(function (resp) {
            that.setState({
                tableLoad: false
            })
            var curr = {
                total: resp.data.data.total,
                currPage: page
            }
            that.setState({
                tableData: resp.data.data.data,
                tablePage: curr
            })
        })
    }
    render() {
        let that = this;
        let tableConfig = [
            {
                title:'ID',
                dataIndex: 'id',
                key: 'id'
            },
            {
                title:'妮称',
                dataIndex: 'nickName',
                key: 'id'
            },
            {
                title:'登陆邮箱',
                dataIndex: 'email',
                key: 'id'
            },
            {
                title:'员工分组',
                key: 'id',
                render: function (value, row, index) {
                    return row.group ? row.group.name : "无"
                }
            },
            {
                title:'手机号码',
                key: 'id',
                render: function (value, row, index) {
                    return row.mobile ? row.mobile : "无"
                }
            },
            {
                title:'员工帐户',
                key: 'id',
                render: function (value, row, index) {
                    return (
                        <UI.Button type="primary" shape="circle" danger={row.staf}>{row.staf ? "是" : "否"}</UI.Button>
                    )
                }
            },
            {
                title:'超级管理员',
                key: 'id',
                render: function (value, row, index) {
                    return (
                        <UI.Button type="primary" shape="circle" danger={row.superUser}>{row.superUser ? "是" : "否"}</UI.Button>
                    )
                }
            },
            {
                title: '管理',
                dataIndex: 'opt',
                key: 'opt',
                align:'right',
                fixed:'right',
                with:115,
                render: (vl,row,index)=>{
                    row=JSON.parse(JSON.stringify(row));
                    return (
                        <span className="opt">
                            <UI.Button type="link" onClick={()=>{
                                UI.message.error("当前不支持用户信息直接修改")
                            }}>信息编辑</UI.Button>
                            <UI.Button type="link" onClick={()=>{
                                Loading()
                                apis.ResetUserPassword(row.id).then(resp => {
                                    HasLoaded()
                                    if (resp.data.code === 200 && resp.data.data.code ===200) {
                                        that.setState({
                                            passShow: true,
                                            currPassed: resp.data.data.password

                                        })
                                    } else {
                                        UI.message.error(resp.data.data && resp.data.data.msg ? resp.data.data.msg : resp.data.msg)
                                    }
                                })
                            }}>重置密码</UI.Button>
                            <UI.Button type="link" onClick={()=>{
                                UI.message.info("请先行配置邮件发送渠道以及默认发送帐户")
                            }}>发送消息</UI.Button>
                        </span>
                    )
                }
            }
        ]
        return (
            <div id="users">
                <PageHeader
                    title="会员管理"
                    subTitle="会员管理，消息通知，会员营销"
                    extra={[]}/>
                <div id="tablecontains">
                    <div id="toolbar">
                        <UI.Form onFinish={that.MakeSearch} layout={"inline"}>
                            <UI.Form.Item label={"登陆邮箱"} name={"email"}>
                                <UI.Input placeholder={"登陆邮箱"}/>
                            </UI.Form.Item>
                            <UI.Form.Item>
                                <UI.Button type={"primary"} htmlType={"submit"}>用户搜索</UI.Button>
                            </UI.Form.Item>
                        </UI.Form>
                    </div>
                </div>
                <div style={{marginTop:'22px'}}>
                    <UI.Table
                        rowKey="id"
                        dataSource={this.state.tableData}
                        columns={tableConfig}
                        loading={this.state.tableLoad}
                        pagination={{total:this.state.tablePage.total,defaultPageSize:20,defaultCurrent:this.state.tablePage.currPage,onChange:this.getTablePage}}/>
                </div>
                <UI.Modal visible={that.state.passShow} title={'发件人管理'} onCancel={()=>{
                    that.setState({
                        currPassed: "",
                        passShow: false
                    })
                }} footer={null} destroyOnClose>
                    密码重置成功，请妥善保存以下密码，系统将不会再次显示此信息：<br/>
                    <span style={{color:"red"}}>{that.state.currPassed}</span>
                </UI.Modal>
            </div>
        )
    }
}