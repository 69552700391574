import React from "react";
import * as UI from "antd";
import { PageHeader } from '@ant-design/pro-layout';
import HttpPost from "../../../../../utils/request";
import * as load from "../../../../../utils/loading";


const {Option} = UI.Select

export  default  class SmsApp extends React.Component{
    constructor() {
        super();
        this.state={
            tableData: [],
            tablePage: {total: 0,currPage:1},
            tableLoad: false,
            keywords: {},
            editShow: false,
            editCurr: {},
            graphs: [],
            imgkey: '',
            upLoading: false,
            fields: [],
            choosedChannel: null,
            grantMappping: {}
        }
    }
    componentDidMount() {
        this.getGraphs()
        this.getFields()
        this.getTablePage(1)
    }

    /**
     * 发起搜索
     * @param params
     * @constructor
     */
    MakeSearch=(params)=>{
        console.log(params)
        this.setState({
            keywords: params
        })
        var that = this
        setTimeout(function () {
            that.getTablePage(1)
        },200)
    }
    /**
     * 获得短信渠道字段列表
     */
    getFields(){
        var that = this
        var para = {}
        para.name = 'SmsChannel'
        HttpPost("system/formfields", para).then(resp => {
            that.setState({
                fields: resp.data.data
            })
        })
    }
    /**
     * 获得可用渠道
     */
    getGraphs = () =>{
        var that = this
        var para = {}
        para.code = 'DevConfig'
        para.service = "SmsChannels"
        para.pageNumber = 1
        para.pageSize = 10000
        HttpPost("merchant/specuseauths",para).then(resp=>{
            var map = {}
            resp.data.data.data.forEach(ele=>{
                map[ele.id] = ele.grant
            })
            that.setState({
                graphs: resp.data.data.data,
                grantMappping: map
            })
        })
    }

    /**
     * 提交短信应用
     * @param values
     * @constructor
     */
    Handle=(values)=>{
        var that = this
        var para = {}
        var property = {}
        Object.keys(values).forEach((key , index)=>{
            if (key.indexOf("property__") === -1) {
                para[key] = values[key]
            } else  {
                property[key.split("__")[1]] = values[key]
            }
        })
        para.property = JSON.stringify(property)
        if (Object.keys(that.state.editCurr).length > 0) {
            para.id = that.state.editCurr.id;
        }
        load.Loading();
        HttpPost("merchant/smsappadmin",para).then(resp=>{
            load.HasLoaded();
            if (resp.data.code===200){
                if(resp.data.data.code===200){
                    UI.message.success("短信应用信息提交成功")
                    this.setState({
                        editShow: false,
                        editCurr: {}
                    })
                    this.getTablePage(1)
                } else {
                    UI.message.warning(resp.data.data.msg)
                }
            }else {
                UI.message.error(resp.data.msg)
            }
        })
    }

    /**
     * 获得列表
     * @param page
     * @param pageSize
     */
    getTablePage=(page,pageSize=20)=>{
        let that = this;
        let para = {};
        para.pageNumber = page;
        para.pageSize = pageSize;
        Object.assign(para, that.state.keywords)
        that.setState({
            tableLoad: true
        })
        HttpPost('merchant/smsapplist',para).then(function (resp) {
            that.setState({
                tableLoad: false
            })
            var curr = {
                total: resp.data.data.total,
                currPage: page
            }
            that.setState({
                tableData: resp.data.data.data,
                tablePage: curr
            })
        })
    }
    render() {
        var that = this
        var columns = [
            {
                title: "ID",
                dataIndex: "id"
            },
            {
                title: "应用名称",
                dataIndex: "name"
            },
            {
                title: "授权通道",
                key: "id",
                render: function (value, row, index) {
                    return (
                        <UI.Button shape={"round"} size={'small'} type={"primary"}>
                            {row.graph.name}
                        </UI.Button>
                    )
                }
            },
            {
                title: "授权平台",
                key: "id",
                render: function (value, row, index) {
                    return (
                        <UI.Button shape={"round"} size={'small'} type={"primary"}>
                            {row.graph.grant.name}
                        </UI.Button>
                    )
                }
            },
            {
                title: "应用管理",
                key: "id",
                align: "right",
                fixed: 'right',
                render: function (value, row, index) {
                    return (
                        <UI.Button onClick={()=>{
                            that.setState({
                                choosedChannel: null
                            })
                            var data = JSON.parse(JSON.stringify(row))
                            var choosedChannel =  data.graph.grant.id
                            data.graph = data.graph.id
                            if (data.property) {
                                var tp = JSON.parse(data.property)
                                data.property = tp
                            }
                            that.setState({
                                editCurr: data
                            })
                            that.setState({
                                choosedChannel: choosedChannel,
                                editShow: true
                            })
                        }} type={"link"}>
                            应用编辑
                        </UI.Button>
                    )
                }
            }
        ]
        return (
            <div>
                <PageHeader title="短信应用" subTitle="对平台短信应用的集中管理" extra={[
                    <UI.Button  key="1" type="primary" shape="round" onClick={()=>{
                        that.setState({
                            editCurr: {},
                            editShow: true,
                            choosedChannel: null
                        })
                    }}>新增短信应用</UI.Button>
                ]}/>
                <div style={{marginTop:'22px'}} id="hot-top-toolbar">
                    <UI.Form onFinish={that.MakeSearch} layout={"inline"}>
                        <UI.Form.Item label={"应用名称"} name={"name__contains"}>
                            <UI.Input placeholder={"请输入应用名称"}/>
                        </UI.Form.Item>
                        <UI.Form.Item label={"应用通道"} name={"Graph__Id"}>
                            <UI.Select placeholder={"请选择合理的应用通道"}>
                                {that.state.graphs.map(gp=>{
                                    return (
                                        <Option key={gp.id} value={gp.id}>{gp.name}</Option>
                                    )
                                })}
                            </UI.Select>
                        </UI.Form.Item>
                        <UI.Form.Item>
                            <UI.Button type={"primary"} htmlType={"submit"}>应用搜索</UI.Button>
                        </UI.Form.Item>
                    </UI.Form>
                </div>
                <div style={{marginTop:'22px'}}>
                    <UI.Table
                        rowKey="id"
                        dataSource={this.state.tableData}
                        columns={columns}
                        loading={this.state.tableLoad}
                        pagination={{total:this.state.tablePage.total,defaultPageSize:20,defaultCurrent:this.state.tablePage.currPage,onChange:this.getTablePage}}/>
                </div>
                <UI.Modal footer={null} visible={that.state.editShow} title={'短信应用管理'} onCancel={()=>{
                    that.setState({
                        editCurr: {},
                        editShow: false,
                        choosedChannel: null
                    })
                }} destroyOnClose>
                    <UI.Form onFinish={that.Handle} labelAlign={"left"} labelCol={{span:24}}>
                        <UI.Form.Item label={"应用名称"} name={"name"} rules={[{required:true,message:'请输入应用名称'}]} initialValue={this.state.editCurr.name}>
                            <UI.Input placeholder={"请输入应用名称"}/>
                        </UI.Form.Item>
                        <UI.Form.Item label={"应用通道"} name={"graph"} rules={[{required:true,message:'请选择合理的应用通道'}]} initialValue={this.state.editCurr.graph}>
                            <UI.Select placeholder={"请选择合理的应用通道"} onChange={(value,option)=>{
                                that.setState({
                                    choosedChannel: that.state.grantMappping[value].id
                                })
                            }} defaultValue={that.state.editCurr.graph}>
                                {that.state.graphs.map(gp=>{
                                    return (
                                        <Option key={gp.id} value={gp.id}>{gp.name}</Option>
                                    )
                                })}
                            </UI.Select>
                        </UI.Form.Item>
                        {that.state.choosedChannel &&
                        <fieldset>
                            {that.state.fields.map(field => {
                                if (parseInt(field.figure) === that.state.choosedChannel) {
                                    return (
                                        <UI.Form.Item help={field.help ? field.help : ''} key={field.id} label={field.label} name={`property__${field.prop}`}
                                                      rules={[{required:field.required,message:`请输入${field.label}`}]}
                                                      initialValue={this.state.editCurr.property ? this.state.editCurr.property[field.prop] : undefined}>
                                            <UI.Input placeholder={`请输入${field.label}`}/>
                                        </UI.Form.Item>
                                    )
                                }
                            })}
                        </fieldset>}
                        <UI.Form.Item>
                            <UI.Button htmlType={"submit"} type={"primary"} block>提应短信应用信息</UI.Button>
                        </UI.Form.Item>
                    </UI.Form>
                </UI.Modal>
            </div>
        )
    }
}