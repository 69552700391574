import React,{useState} from 'react';
import Layout from './layout/Layout'
import "@ant-design/flowchart/dist/index.css"
import './style.css';

function App() {
  const [globalData,setGlobalData] = useState({
    sideWidth: "46px"
  })
  return (
      <Layout/>
  );
}

export default App;
