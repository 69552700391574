import React,{Component} from "react";
import * as UI from 'antd'
import { PageHeader } from '@ant-design/pro-layout'
import HttpPost, { GWAPI } from "../../../../../utils/request";
import * as load from "../../../../../utils/loading";
import apis from "../../../../../utils/apis";

const {TextArea} =UI.Input
const {Option} = UI.Select

export default class SendSMSMessage extends Component{
    constructor() {
        super();
        this.state={
            countrys: [],
            handingLoading: false
        }
    }
    componentDidMount() {
        this.getCountriesAvaiable()
    }



    /**
     * 加载国家列表
     */
    getCountriesAvaiable () {
        var that = this
        GWAPI.getCountries({}).then(ret => {
            that.setState({
                countrys: ret.data.data
            })
        }) 
    }
     
    render() {
        var that = this
        return (
            <div>
                <PageHeader title="短信推送测试" subTitle="在线测试短信推送信息" extra={[]}/>
                <UI.Card style={{width:"760px",margin: "14px auto",borderRadius: "8px"}}>
                    <UI.Form onFinish={(params) => {
                        console.log(params)
                        var argsParam = params.args.split("\n")
                        if (argsParam[0].indexOf(":")!==-1){
                            var sendParam = {}
                            argsParam.forEach(elem => {
                                var currentKeyValuePair = elem.split(":")
                                sendParam[currentKeyValuePair[0]] = currentKeyValuePair[1]
                            })
                            params.args = sendParam
                        } else {
                            params.args = argsParam
                        }
                        that.setState({
                            handingLoading: true
                        })
                        apis.sendMmsMessage(params).then(ret => {
                            that.setState({
                                handingLoading: false
                            })
                            if (ret.data.code === 200 && ret.data.data.code === 200) {
                                UI.message.success("成功发送测试短信，请通过手机查询发送是否成功!")
                            } else {
                                UI.message.error(ret.data.data.msg ? ret.data.data.msg : ret.data.msg)
                            }
                            
                        })
                    }} labelAlign="left" labelCol={{span:24}}>
                        <UI.Form.Item label={"手机归属地"} name={"itac"} rules={[{required:true,message:'手机归属地'}]}>
                            <UI.Select placeholder={"手机归属地"}>
                                {that.state.countrys.map(item=>{
                                    return (
                                        <Option key={item.id} value={item.itac}>{item.ShowName} (+{item.itac})</Option>
                                    )
                                })}
                            </UI.Select>
                        </UI.Form.Item>
                        <UI.Form.Item label={"签名ID"} name={"sign_id"} rules={[{required:true,message:'请输入签名ID'}]}>
                            <UI.Input placeholder={"请输入签名ID"}/>
                        </UI.Form.Item>
                        <UI.Form.Item label={"模板ID"} name={"template_id"} rules={[{required:true,message:'请输入模板ID'}]}>
                            <UI.Input placeholder={"请输入模板ID"}/>
                        </UI.Form.Item>
                        <UI.Form.Item label={"收信手机"} name={"mobile"} rules={[{required:true,message:'收信手机'}]}>
                            <UI.Input placeholder={"收信手机"}/>
                        </UI.Form.Item>
                        <UI.Form.Item label={"参数值"} name={"args"} rules={[{required:true,message:'参数值'}]}>
                            <TextArea rows={10} placeholder={"一行一个参数值，出现顺序需与模板中相应的值对应,若为数字参数即直接填入值即可，若为字符参数则以‘key:value’为参考样式"} help="一行一个参数值，出现顺序需与模板中相应的值对应,若为数字参数即直接填入值即可，若为字符参数则以‘key:value’为参考样式"/>
                        </UI.Form.Item>
                        <UI.Form.Item>
                            <UI.Button htmlType={"submit"} type={"primary"} loading={this.state.handingLoading} block>发送测试短信</UI.Button>
                        </UI.Form.Item>
                    </UI.Form>
                </UI.Card>
            </div>
        );
    }
}