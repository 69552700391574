/**
 * author: Alex Lai
 * Support Website: https://laialex.com
 * Email: alex@laialex.com
 * Create Time: 2023/11/14
 */
import React, {Component} from "react";
import * as UI from "antd";
import { PageHeader } from '@ant-design/pro-layout';
import apis from "../../../../utils/apis";
import {BellOutlined} from "@ant-design/icons";


const {TextArea} = UI.Input
const {Option} = UI.Select

export default class Domains extends Component
{
    constructor()
    {
        super();
        this.state = {
            tableData: [],
            paging: {total: 0, pageNumber: 1, pageSize: 20},
            tableLoad: false,
            searchKey: {},
            editShow: false,
            editCurr: {},
            submitting: false,
            clients: []
        }
    }
    componentDidMount()
    {
        this.clientSync()
        this.tableDataSync(1)
    }

    /**
     * 终端加载
     */
    clientSync () {
        var that = this
        let para = {}
        para.pageNumber = 1
        para.pageSize = 2000
        apis.inpClientList(para).then(function (resp) {
            that.setState({
                clients: resp.data.data.data,
            })
        })
    }

    /**
     * 发起搜索
     * @param params
     * @constructor
     */
    makeSearch = (params) => {
        this.setState({
            searchKey: params
        })
        var that = this
        setTimeout(function () {
            that.tableDataSync()
        }, 200)
    }
    /**
     * 签名列表
     * @param pageNumber
     Object.assign(para, that.state.keywords)
     * @param pageSize
     * @constructor
     */
    tableDataSync = (pageNumber = 1) => {
        let that = this;
        let para = {};
        para.pageNumber = pageNumber;
        para.pageSize = 20;
        that.setState({
            tableLoad: true
        })
        Object.assign(para, that.state.searchKey)
        apis.inpDomainList(para).then(function (resp) {
            that.setState({
                tableLoad: false
            })
            var curr = {
                total: resp.data.data.total,
                pageNumber: pageNumber
            }
            that.setState({
                tableData: resp.data.data.data,
                paging: curr
            })
        })
    }


    render()
    {
        var that = this

        const columns = [
            {
                title: "ID",
                dataIndex: "id"
            },
            {
                title: "备注",
                key: "id",
                render: function (value, row, index) {
                    return (
                        <div style={{fontSize: "14px", color: "darkcyan"}}>{row.remark}</div>
                    )
                }
            },
            {
                title: "节点",
                key: "id",
                render: function (value, row, index) {
                    return (
                        <div>{row.client.endpoint.code}</div>
                    )
                }
            },
            {
                title: "客户端",
                key: "id",
                render: function (value, row, index) {
                    return (
                        <div>{row.client.remark}</div>
                    )
                }
            },
            {
                title: "域名",
                key: "id",
                render: function (value, row, index) {
                    return (
                        <UI.Button shape={"round"} type={"dashed"}>{row.host}</UI.Button>
                    )
                }
            },
            {
                title: "CNAME",
                key: "id",
                render: function (value, row, index) {
                    return `${row.client.endpoint.code}.inp.alai.pub`
                }
            },
            {
                title: "可用",
                key: "id",
                render: function (value, row, index) {
                    return (
                        <UI.Button shape={"round"} type={"circle"}>{row.state ? '是' : '否'}</UI.Button>
                    )
                }
            },
            {
                title: "管理",
                key: "id",
                fixed: "right",
                render: function (value, row, index) {
                    return (
                        <span>
                             <UI.Button onClick={() => {
                                 var data = JSON.parse(JSON.stringify(row))
                                 data.client = data.client.id
                                 that.setState({
                                     editCurr: data,
                                     editShow: true
                                 })
                             }} type={"link"}>编辑</UI.Button>
                        </span>
                    )
                }
            }
        ]

        return (
            <div>
                <PageHeader title="穿透域名管理" subTitle="穿透域名管理" extra={[
                    <UI.Button key="1" type="primary" shape="round" onClick={() => {
                        that.setState({
                            editCurr: {},
                            editShow: true,
                            submitting: false
                        })
                    }}>绑定域名</UI.Button>
                ]}/>
                <div style={{color: "darkcyan", fontSize: "10px", marginBottom: "16px"}}>
                    <BellOutlined/> 免费用户可绑定五个域名
                </div>
                <UI.Card>
                    <div style={{marginTop: '22px'}}>
                        <UI.Form onFinish={that.MakeSearch} layout={"inline"}>
                            <UI.Form.Item label={"备注说明"} name={"remark__contains"}>
                                <UI.Input placeholder={"备注说明"}/>
                            </UI.Form.Item>
                            <UI.Form.Item label={"域名"} name={"host"}>
                                <UI.Input placeholder={"域名"}/>
                            </UI.Form.Item>
                            <UI.Form.Item>
                                <UI.Button type={"primary"} htmlType={"submit"}>快速搜索</UI.Button>
                            </UI.Form.Item>
                        </UI.Form>
                    </div>
                </UI.Card>

                <div style={{marginTop: '22px'}}>
                    <UI.Table
                        rowKey="id"
                        dataSource={this.state.tableData}
                        columns={columns}
                        loading={this.state.tableLoad}
                        pagination={{
                            total: this.state.paging.total,
                            defaultPageSize: 20,
                            defaultCurrent: this.state.paging.pageNumber,
                            onChange: this.tableDataSync
                        }}/>
                </div>

                <UI.Modal visible={that.state.editShow} title={'定时任务执行客户端名称'} onCancel={() => {
                    that.setState({
                        editCurr: {},
                        editShow: false,
                        submitting: false
                    })
                }} footer={null} destroyOnClose>
                    <UI.Form onFinish={(params => {
                        that.setState({
                            submitting: true
                        })
                        if (that.state.editCurr.id) {
                            params.id = that.state.editCurr.id
                        }
                        apis.inpDomainAdmin(params).then(resp => {
                            that.setState({
                                submitting: false
                            })
                            if (resp.data.code === 200 && resp.data.data.code === 200) {
                                UI.message.success("提交信息成功")
                                that.tableDataSync(that.state.editCurr.id ? that.state.paging.pageNumber : 1)
                                that.setState({
                                    submitting: false,
                                    editShow: false,
                                    editCurr: {}
                                })
                            } else {
                                UI.message.error(resp.data.data.msg ? resp.data.data.msg : resp.data.msg)
                            }
                        })
                    })} labelCol={{span: 24}} labelAlign={"left"} initialValues={that.state.editCurr}>
                        <UI.Form.Item label={"域名备注"} name={"remark"}
                                      rules={[{required: true, message: '域名备注'}]}>
                            <UI.Input placeholder={"域名备注"}/>
                        </UI.Form.Item>
                        <UI.Form.Item label={"绑定客户端"} name={"client"} rules={[{required: true, message: '绑定客户端'}]}>
                            <UI.Select placeholder={"绑定客户端"} style={{width: "100%"}}>
                                {that.state.clients.map(item => {
                                    return (
                                        <Option value={item.id} key={item.id}>{item.remark}</Option>
                                    )
                                })}
                            </UI.Select>
                        </UI.Form.Item>
                        <UI.Form.Item label={"解析状态"} name={"state"}
                                      rules={[{required: true, message: '解析状态'}]}>
                            <UI.Radio.Group>
                                <UI.Radio value={true}>可用</UI.Radio>
                                <UI.Radio value={false}>禁用</UI.Radio>
                            </UI.Radio.Group>
                        </UI.Form.Item>
                        <UI.Form.Item label={"绑定域名"} name={"host"}
                                      rules={[{required: true, message: '绑定域名'}]}>
                            <UI.Input placeholder={"绑定域名"}/>
                        </UI.Form.Item>
                        <UI.Form.Item label={"可用协议"} name={"scheme"}
                                      rules={[{required: true, message: '可用协议'}]}>
                            <UI.Radio.Group>
                                <UI.Radio value={"all"}>所有http / https</UI.Radio>
                                <UI.Radio value={"http"}>http</UI.Radio>
                                <UI.Radio value={"https"}>https</UI.Radio>
                            </UI.Radio.Group>
                        </UI.Form.Item>
                        <UI.Form.Item label={"反向代理目录"} name={"location"}
                                      rules={[{required: false, message: '反向代理目录'}]} help={"url路由 空则为不限制"}>
                            <UI.Input placeholder={"反向代理目录"}/>
                        </UI.Form.Item>
                        <UI.Form.Item label={"内网访问地址"} name={"target"}
                                      rules={[{required: false, message: '内网访问地址'}]} help={"例如:192.168.1.11:8080,不包含协议"}>
                            <UI.Input placeholder={"内网访问地址"}/>
                        </UI.Form.Item>
                        <UI.Form.Item label={"请求头"} name={"header"}
                                      rules={[{required: false, message: '请求头'}]} help={"一行一个"}>
                            <TextArea rows={5} placeholder={"请求头"}/>
                        </UI.Form.Item>
                        <UI.Form.Item label={"请求主机"} name={"hostchange"}
                                      rules={[{required: false, message: '请求主机'}]} help={"用于修改真实请求主机"}>
                            <UI.Input placeholder={"请求主机"}/>
                        </UI.Form.Item>
                        <UI.Form.Item>
                            <UI.Button htmlType={"submit"} loading={that.state.submitting} type={"primary"}
                                       block>立即提交
                            </UI.Button>
                        </UI.Form.Item>
                    </UI.Form>
                </UI.Modal>
            </div>
        )
    }
}