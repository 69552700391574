import React from "react";
import * as UI from "antd";
import { PageHeader } from '@ant-design/pro-layout';
import HttpPost from "../../../utils/request";
import * as load from "../../../utils/loading"
import apis from "../../../utils/apis";

const {Search,TextArea}=UI.Input;
const { Meta } = UI.Card;
const {Option} = UI.Select

export default class CorpusGroup extends React.Component{
    constructor() {
        super();
        this.state = {
            tableData: [],
            tablePage: {total: 0,currPage:1},
            tableLoad: false,
            searchKey: {},
            editShow: false,
            editCurr: {},
            engine: []
        }
    }
    componentDidMount() {
        this.GetTable()
        this.workEngineSync()
    }

    /**
     * 加载翻译引擎
     */
    workEngineSync () {
        var filter = {}
        filter.scene = "TransChannelIds"
        filter.state = true
        filter.pageNumber = 1
        filter.pageSize = 2000
        var that = this
        apis.getAuths(filter).then(res => {
            that.setState({
                engine: res.data.data.data
            })
        })
    }


    /**
     * 分页查询
     * @param pageNumber
     * @param pageSize
     * @constructor
     */
    GetTable=(pageNumber=1,pageSize=20)=>{
        let that = this;
        let para = {};
        para.pageNumber = pageNumber;
        para.pageSize = pageSize;
        Object.assign(para, that.state.searchKey)
        that.setState({
            tableLoad: true
        })
        HttpPost('translate/corpusgroups',para).then(function (resp) {
            that.setState({
                tableLoad: false
            })
            let curr = {
                total: resp.data.data.total,
                currPage: pageNumber
            }
            that.setState({
                tableData: resp.data.data.data,
                tablePage: curr
            })
        })
    }
    MakeSearch=(keywords)=>{
        var that = this;
        this.setState({
            searchKey: Object.assign(that.state.searchKey,{keywords: keywords})
        })
        that.GetTable(1)
    }
    handle=(values)=>{
        let that = this;
        if (Object.keys(that.state.editCurr).length > 0) {
            values.id = that.state.editCurr.id;
        }
        load.Loading();
        HttpPost("translate/translatecorpusgroupmodify",values).then(resp=>{
            load.HasLoaded();
            if (resp.data.code===200){
                if(resp.data.data.code===200){
                    UI.message.success("授权分组信息维护完成")
                    this.setState({
                        editShow: false,
                        editCurr: {}
                    })
                    this.GetTable(1)
                } else {
                    UI.message.warning(resp.data.data.msg)
                }
            }else {
                UI.message.error(resp.data.msg)
            }
        })
    }
    render() {
        var that = this
        const columns = [
            {
                title:'ID',
                dataIndex: 'id',
                key: 'id'
            },
            {
                title: '领域名称',
                dataIndex: 'name',
                key: 'name'
            },
            {
                title: '翻译引擎',
                key: 'name',
                render: function (v, row, index) {
                    return row.work_engine ? row.work_engine.name : "未绑定默认翻译引擎"
                }
            },
            {
                title: '领域说明',
                dataIndex: 'explain',
                key: 'explain'
            },
            {
                title: '领域管理',
                key:'operation',
                align:'right',
                width: 220,
                render: (col,row,index)=>{
                    return (
                        <div className="opt">
                            <UI.Button type="link" onClick={()=>{
                                that.setState({
                                    editCurr:row,
                                    editShow: true
                                })
                            }}>编辑</UI.Button>
                        </div>
                    )
                }
            }
        ]
        return (
            <div id="corpusgroup">
                <PageHeader title="翻译领域管理" subTitle="通过设定专业的翻译领域，用户可针对本行业或则特定应用场景针对性的翻译，从而提高语言翻译的精准度" extra={[
                    <UI.Button  key="1" type="primary" shape="round" onClick={()=>{
                        that.setState({
                            editCurr: {},
                            editShow: true
                        })
                    }}>新增领域</UI.Button>
                ]}/>
                <div id="tablecontains">
                    <div id="toolbar">
                        <Search placeholder="请输入翻译组名称搜索..." enterButton="领域搜索" size="large" onSearch={this.MakeSearch}/>
                    </div>
                </div>
                <div style={{marginTop:'22px'}}>
                    <UI.Table
                        rowKey="id"
                        dataSource={this.state.tableData}
                        columns={columns}
                        loading={this.state.tableLoad}
                        pagination={{total:this.state.tablePage.total,defaultPageSize:20,defaultCurrent:this.state.tablePage.currPage,onChange:this.GetTable}}/>
                </div>
                <UI.Modal title="新增授权分组" visible={this.state.editShow} footer={null} onCancel={()=>{
                    this.setState({
                        editShow: false
                    })
                }} destroyOnClose>
                    <UI.Form labelCol={{span:24}} labelAlign="left" onFinish={this.handle}>
                        <UI.Form.Item name="name" label="翻译领域名称" rules={[{required:true,message:'请输入翻译领域名称'}]} initialValue={this.state.editCurr.name}>
                            <UI.Input placeholder="请输入翻译领域名称..."/>
                        </UI.Form.Item>
                        <UI.Form.Item name="model" label="大模型" rules={[{required:false,message:'请输入大模型名称'}]} initialValue={this.state.editCurr.model}>
                            <UI.Input placeholder="请输入大模型名称..."/>
                        </UI.Form.Item>
                        <UI.Form.Item name="model_version" label="大模型版本" rules={[{required:false,message:'请输入大模型版本'}]} initialValue={this.state.editCurr.model_version}>
                            <UI.Input placeholder="请输入大模型版本..."/>
                        </UI.Form.Item>
                        <UI.Form.Item name="work_engine_id" label="智能翻译引擎" rules={[{required:true,message:'智能翻译引擎'}]} initialValue={this.state.editCurr.name}>
                            <UI.Select placeholder={"请选择自动翻译引擎"}>
                                {that.state.engine.map(elem => {
                                    return <Option value={elem.id} key={elem.id}>{elem.name}</Option>
                                })}
                            </UI.Select>
                        </UI.Form.Item>
                        <UI.Form.Item name="explain" label="翻译领域说明"  rules={[{required:true,message:'请输入翻译领域说明'}]} initialValue={this.state.editCurr.explain}>
                            <TextArea placeholder="请输入翻译领域说明..." rows={6}/>
                        </UI.Form.Item>
                        <UI.Form.Item style={{textAlign:'right'}}>
                            <UI.Button type="primary" shape="round" htmlType="submit">提交翻译领域</UI.Button>
                        </UI.Form.Item>
                    </UI.Form>
                </UI.Modal>
            </div>
        )
    }

}