import React from "react";
import * as UI from "antd";
import { PageHeader } from '@ant-design/pro-layout';
import HttpPost,{UploadProps} from "../../../../utils/request";
import * as load from "../../../../utils/loading"

const {Search, TextArea}=UI.Input;
const { Option } = UI.Select;

export default class ClientList extends React.Component{
    constructor() {
        super();
        this.state={
            editCurr: {},
            editShow: false,
            imageUrl: null,
            loading: false,
            tableData: [],
            tablePage: {total: 0,currPage:1},
            tableLoad: false,
            group: [],
            apps: [],
            searchKey: {}
        }
    }
    componentDidMount() {
        this.getTablePage(1)
        this.GetGrantGroups()
        this.GetApps()
    }
    /**
     * 获得商户应用
     * @constructor
     */
    GetApps = () => {
        let that = this;
        let para = {};
        para.pageNumber = 1;
        para.pageSize = 99999;
        HttpPost('grant/applist',para).then(function (resp) {
            that.setState({
                apps: resp.data.data.data
            })
        })
    }
    /**
     * 权限分组
     * @constructor
     */
    GetGrantGroups=()=>{
        let that = this;
        let para = {};
        para.pageNumber = 1;
        para.pageSize = 20000;
        HttpPost('grant/grantgrouplist',para).then(function (resp) {
            that.setState({
                group: resp.data.data.data,
            })
        })
    }
    /**
     * 发起搜索
     * @param params
     * @constructor
     */
    MakeSearch=(params)=>{
        console.log(params)
        this.setState({
            searchKey: params
        })
        var that = this
        setTimeout(function () {
            that.getTablePage(1)
        },200)
    }
    /**
     * 图标上传状态变化
     * @param info
     * @constructor
     */
    ImgUpload = (info) => {
        var that = this;
        load.HasLoaded();
        if (info.file.status === 'done') {
            console.log(info.file)
            that.setState({
                imageUrl: info.file.response.data.data.url
            })
        }
    }
    /**
     * 图片上传前开启加载
     * @param file
     */
    beforeUpload = (file) => {
        load.Loading();
    }
    /**
     * 获得应用分页
     * @param page
     */
    getTablePage=(page)=>{
        let that = this;
        let para = {};
        para.pageNumber = page;
        para.pageSize = 20;
        Object.assign(para, that.state.searchKey)
        that.setState({
            tableLoad: true
        })
        HttpPost('grant/clientlist',para).then(function (resp) {
            that.setState({
                tableLoad: false
            })
            var curr = {
                total: resp.data.data.total,
                currPage: page
            }
            that.setState({
                tableData: resp.data.data.data,
                tablePage: curr
            })
        })
    }
    /**
     * 提交应用信息
     * @param values
     */
    handle = (values) => {
        let that = this;
        if (Object.keys(that.state.editCurr).length > 0) {
            values.id = that.state.editCurr.id;
        }
        if (that.state.imageUrl){
            values.icon = that.state.imageUrl
        }
        load.Loading();
        HttpPost("grant/clientmanage",values).then(resp=>{
            load.HasLoaded();
            if (resp.data.code===200){
                if(resp.data.data.code===200){
                    UI.message.success("应用信息处理完成")
                    this.setState({
                        editShow: false,
                        editCurr: {}
                    })
                    this.getTablePage(1)
                } else {
                    UI.message.warning(resp.data.data.msg)
                }
            }else {
                UI.message.error(resp.data.msg)
            }
        })
    }
    render() {
        let that = this
        let columns = [
            {
                title:'ID',
                dataIndex: 'id',
                key: 'id'
            },
            {
                title:'JWT KEY',
                dataIndex: 'id',
                key: 'jwt_secret'
            },
            {
                title:'终端名称',
                dataIndex: 'name',
                key: 'name'
            },
            {
                title:'应用地址',
                dataIndex: 'homeUrl',
                key: 'homeUrl'
            },
            {
                title:'登陆返回地址',
                dataIndex: 'ssoUrl',
                key: 'ssoUrl'
            },
            {
                title:'应用类型',
                key: 'open_client',
                render: function (value,row,index) {
                    return (
                        <UI.Button shape={"round"} type={"text"}>
                            {row.open_client ? '开放终端' : '系统终端'}
                        </UI.Button>
                    )
                }
            },
            {
                title: '部署状态',
                key: 'need_deploy',
                render: function (value,row,index) {
                    return (
                        <UI.Button type={row.need_deploy ? 'danger' : 'primary'} shape={"round"}>
                            {row.need_deploy ? '待部署' : '已部署'}
                        </UI.Button>
                    )
                }
            },
            {
                title: '过期时间',
                key: 'expire',
                render: function (value,row,index) {
                    var dd = new Date(row.expire)
                    return `${dd.toLocaleDateString()} ${dd.toLocaleTimeString()}`
                }
            },
            {
                title:'终端管理',
                key: 'opt',
                fixed: 'right',
                render: function (v,row,index) {
                   return (
                       <UI.Button type="link" onClick={()=>{
                           var para = JSON.parse(JSON.stringify(row))
                           if (para.open_id_group) {
                               para.open_id_group = para.open_id_group.id
                           }
                           if (para.app){
                               para.app = para.app.id
                           }
                           if (para.icon) {
                               that.setState({
                                   imageUrl: para.icon
                               })
                           }else {
                               that.setState({
                                   imageUrl: null
                               })
                           }
                           that.setState({
                               editCurr: para,
                               editShow: true
                           })
                       }}>编辑</UI.Button>
                   )
                }
            }
        ]
        return (
            <div>
                <PageHeader title="客户端集中管理" subTitle="终端集中化管理平台，可对终端信息以及终端的用户群进行集中化管理" extra={[
                    <UI.Button type={"primary"} shape={"round"} onClick={()=>{
                        that.setState({
                            editCurr: {},
                            editShow: true
                        })
                    }}>新增客户端</UI.Button>
                ]}/>
                <div id="tablecontains">
                    <div id="toolbar">
                        <UI.Form onFinish={that.MakeSearch} layout={"inline"}>
                            <UI.Form.Item label={"客户端名称"} name={"name__icontains"}>
                                <UI.Input placeholder={"请输入客户端名称"}/>
                            </UI.Form.Item>
                            <UI.Form.Item label={"应用名称"} name={"App__Name__icontains"}>
                                <UI.Input placeholder={"请输入应用名称"}/>
                            </UI.Form.Item>
                            <UI.Form.Item>
                                <UI.Button type={"primary"} htmlType={"submit"}>客户端搜索</UI.Button>
                            </UI.Form.Item>
                        </UI.Form>
                    </div>
                </div>
                <div style={{marginTop:'22px'}}>
                    <UI.Table
                        rowKey="id"
                        dataSource={this.state.tableData}
                        columns={columns}
                        loading={this.state.tableLoad}
                        pagination={<UI.Pagination total={this.state.tablePage.total} defaultCurrent={this.state.tablePage.currPage}/>}/>
                </div>
                <UI.Modal title="终端管理" visible={this.state.editShow} footer={null} onCancel={()=>{
                    this.setState({
                        editShow: false,
                        editCurr: {}
                    })
                }} destroyOnClose>
                    <UI.Form labelCol={{span:24}} labelAlign="left" onFinish={this.handle}>
                        <UI.Form.Item name="name" label="终端名称" rules={[{required:true,message:'请输入终端名称'}]} initialValue={this.state.editCurr.name}>
                            <UI.Input placeholder="请输入终端名称..."/>
                        </UI.Form.Item>
                        <UI.Form.Item label="终端LOGO" required>
                            <UI.Upload
                                {...UploadProps}
                                beforeUpload={that.beforeUpload}
                                listType="picture-card"
                                className="avatar-uploader"
                                showUploadList={false}
                                onChange={that.ImgUpload} name="file">
                                {that.state.imageUrl ? <img src={that.state.imageUrl} alt="avatar" style={{ width: '100%' }} /> : "UPLOAD"}
                            </UI.Upload>
                        </UI.Form.Item>
                        <UI.Form.Item name="open_id_group_id" label="开放登陆授权组" rules={[{required:false,message:'开放登陆授权组'}]} initialValue={this.state.editCurr.open_id_group}>
                            <UI.Select placeholder="请选择开放登陆授权组..." showSearch={true}>
                                {this.state.group.map((item,index)=>{
                                    return (
                                        <Option key={index} value={item.id}>{item.name}</Option>
                                    )
                                })}
                            </UI.Select>
                        </UI.Form.Item>
                        <UI.Form.Item name="app" label="所属应用" rules={[{required:true,message:'所属应用'}]} initialValue={this.state.editCurr.app}>
                            <UI.Select placeholder="请选择所属应用..." showSearch={true}>
                                {this.state.apps.map((item,index)=>{
                                    return (
                                        <Option key={index} value={item.id}>{item.name}</Option>
                                    )
                                })}
                            </UI.Select>
                        </UI.Form.Item>
                        <UI.Form.Item name="ssoUrl" label="登陆校检页" rules={[{required:true,message:'请输入登陆校检页'}]} initialValue={this.state.editCurr.ssoUrl}>
                            <UI.Input placeholder="请输入登陆校检页..."/>
                        </UI.Form.Item>
                        <UI.Form.Item name="homeUrl" label="应用着陆页" rules={[{required:true,message:'请输入应用着陆页'}]} initialValue={this.state.editCurr.homeUrl}>
                            <UI.Input placeholder="请输入应用着陆页..."/>
                        </UI.Form.Item>
                        <UI.Form.Item name="host" label="终端域名" rules={[{required:true,message:'终端域名'}]} initialValue={this.state.editCurr.host}>
                            <UI.Input placeholder="终端域名..."/>
                        </UI.Form.Item>
                        <UI.Form.Item name="intro" label="终端介绍" rules={[{required:true,message:'终端介绍'}]} initialValue={this.state.editCurr.intro}>
                            <TextArea rows={5} placeholder="终端介绍..."/>
                        </UI.Form.Item>
                        <UI.Form.Item style={{textAlign:'right'}}>
                            <UI.Button type="primary" shape="round" htmlType="submit">提交终端信息</UI.Button>
                        </UI.Form.Item></UI.Form>
                </UI.Modal>
            </div>
        )
    }
}
