import React,{Component} from "react";
import HttpPost, {BaseUrl} from "../utils/request";
import {message} from "antd"


export default class SSO extends Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        this.makeSign()
    }
    makeSign () {
        var params = new URLSearchParams(window.location.search)
        var code = params.get("code")
        var api = 'graph/exchangetoken';
        if (code) {
            HttpPost(api,{code:code}).then(function (res) {
                if (res.data.code === 200) {
                    message.success("登陆成功")
                    if (res.data.data.length > 0) {
                        localStorage.setItem("appToken",res.data.data)
                        setTimeout(function () {
                            window.location.href = "/";
                        },3000)
                    } else {
                        message.warning("登陆信息错误，请返回重新登陆");
                        setTimeout(function () {
                            window.location.href=BaseUrl.SSO
                        },5000)
                    }
                }else {
                    message.warning("登陆信息错误，请返回重新登陆");
                    setTimeout(function () {
                        window.location.href=BaseUrl.SSO
                    },5000)
                }
            })
        }else {
            window.location.href=BaseUrl.SSO
        }
    }
    render() {
        return (
            <div id="ssopage"></div>
        )
    }
}
